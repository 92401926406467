import React from 'react';

const jiangxi = {
  id: 'jiangxi',
  image: '/img/locations/jiangxi.jpg',
  name: 'Цзянси',
  chiName: '江西',
  ruName: '',
  say: 'Jiāngxī',
  teas: [{ name: 'Лу Шань Юнь У' }, { name: 'Юнь Лу' }, { name: 'Дун Тин Би Ло' }],
  content: (
    <React.Fragment>
      <p>
        Известно, что люди начали селиться в этих местах десятки тысяч лет назад: сквозь горный массив тут пролегал один
        из немногих удобных путей, по которому можно было пройти и на север, и на юг Китая. Исключительно благодаря
        этому обстоятельству здесь сформировалась своя оригинальная культура и развилась экономика, а сам регион
        сохранил стратегическое значение на протяжении тысяч лет.
      </p>
      <p>
        В древний период истории Китая этот район не стал одним из очагов китайской цивилизации, здесь жил народ байюэ,
        в китайских летописях изображенный варварским и примитивным, впоследствии подвергшийся насильственной
        китаизации. В VII-IV вв. до н. э. некоторые районы нынешней Цзянси входили в состав царств У, Юэ и Чу, а в конце
        III в. до н. э. были присоединены к империи Цинь, стали именоваться областью Цзюцзян и интенсивно начали
        заселяться китайцами (хань). В 733 г. при династии Тан была образована провинция Цзяннань, ее западная часть
        стала именоваться Цзянси. В XIII в., после завоевания Китая монголами, была образована провинция Цзянси в ее
        нынешних границах. В 1853-1861 гг. северные районы Цзянси контролировались повстанцами-тайпинами, а в 1861 г.
        она уже была открыта для иностранной торговли.
      </p>
      <p>
        Эпохальным событием для Китая стало восстание частей Национально-революционной армии под руководством китайских
        коммунистов в Наньчане в 1927 г. в ответ на контрреволюционный переворот Ван Цзинвэя и Чан Кайши. Это событие
        отмечается в КНР как день основания Народно-освободительной армии Китая. В 1930-1934 гг. в Цзянси находилась
        центральная опорная база советского движения в Китае. В 1937-1945 гг. провинцию оккупировали японские войска, а
        после войны здесь обосновался Гоминьдан, из-под власти которого Цзянси была освобождена в 1949 г.
      </p>
      <p>
        Китайская провинция Цзянси протянулась к югу от реки Янцзы, в ее среднем и нижнем течении, расположившись вдоль
        реки Гань. Так называют провинцию Цзянси в Китае на протяжении тысяч лет. Когда-то только рыба и рис были
        основным богатством провинции, сегодня ситуация меняется.
      </p>
      <p>
        Провинция не относится к числу самых развитых в Китае, зато ее экономика считается одной из наиболее устойчивых,
        и ее относят к категории «самодостаточных».
      </p>
      <p>
        Провинция располагает собственными запасами каменного угля, очень перспективны геотермальные источники энергии.
        В Цзянси ведется разработка руд драгоценных металлов, урана, меди, вольфрама. По добыче редких и редкоземельных
        металлов, включая ниобий и тантал, Цзянси стоит на первом месте в стране.
      </p>
      <p>
        Промышленность Цзянси стала развиваться усиленными темпами еще в 1960-1970-х гг., когда в Китае — в целях
        сохранения предприятий на случай войны — стали перемещать заводы и фабрики с побережья во внутриконтинентальные
        провинции. В Цзянси построены крупнейший в Азии рудник по добыче меди и крупнейшая в Китае база по выплавке
        трети всей меди страны. В городе Ганьчжоу находится одно из крупнейших в Китае предприятий по производству
        вольфрама.
      </p>
      <p>
        Город Цзинджэчжэнь — родина фарфора, его делают уже 1700 лет из местной уникальной глины. Здесь находится
        крупнейшая в Китае фарфоровая фабрика — один из самых известных в мире центров фарфорового производства. Лесное
        хозяйство Цзянси — одно из лучших в стране. Лесами покрыто 40% провинции, и это очень высокий для Китая
        показатель. Провинция — главный поставщик бамбука и ценной древесины (камелия масличная, тунговое дерево и
        китайское сальное дерево) на внутренний рынок. Также здесь много реликтовых растений, они находятся под охраной
        государства и считаются «живыми ископаемыми», как, например, гинкго: некоторые деревья достигают возраста 2500
        лет.
      </p>
      <p>
        Несмотря на усиленную индустриализацию, основой экономики провинции остается сельское хозяйство, главная
        культура — рис, по его урожаю провинция занимает третье место в Китае. Цзянси — бесспорный лидер по выращиванию
        кумквата, или золотого мандарина, растущего только в этом районе КНР.
      </p>
      <p>
        Главная достопримечательность провинции — гора Лушань. Культура и история поселения на горе Лушань насчитывает
        более 6 тыс. лет. На протяжении веков ее красоту воспевали знаменитые китайские поэты и писатели, на ней
        сохранилось свыше четырехсот хвалебных надписей. Гора предоставляла убежище для монахов и священников,
        проповедовавших буддизм, даосизм, католичество и православие, на горе построено около четырехсот храмов. В 1996
        г. гора Лушань была включена в список Всемирного культурного наследия ЮНЕСКО.
      </p>
      <p>
        Столичный Наньчан — большой перенаселенный город. Считается историческим центром провинции, местом, где впервые
        начали селиться люди. Был основан еще в 201 г. до н. э.
      </p>
    </React.Fragment>
  ),
};

export default jiangxi;
