import React from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import classNames from 'classnames';

import data from '../data/data';
import { /* basic,  */ listVariants, itemVariants } from '../data/framerVars';

import s from './Family.module.css';
import sh from './share.module.css';

import FamilyCard from '../components/FamilyCard';

const Family = () => {
  const params = useParams();
  const family = data.find((el) => el.id === params.familyId);
  const { teaList } = family;

  return (
    <motion.section
      className={classNames([sh.PageWrapper, s.Family])}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={listVariants}
    >
      {teaList &&
        teaList.map((tea) => {
          return (
            <FamilyCard
              key={tea.id}
              to={`/family/${params.familyId}/tea/${tea.id}`}
              elem={tea}
              variants={itemVariants}
            />
          );
        })}
    </motion.section>
  );
};

export default Family;
