import React from 'react';
import Quote from '../../components/Quote';

import s from '../../pages/StuffItem.module.css';

const gaiwan = {
  id: 'gaiwan',
  image: '/img/stuff/gaiwan.png',
  name: 'Чашка с крышкой',
  chiName: '盖碗',
  ruName: 'гайвань',
  say: 'gàiwǎn',
  content: (
    <React.Fragment>
      <p>
        В дословном переводе с китайского словосочетание «гай-вань» звучит как «крышка-чашка» и означает накрытую чашку
        или чашку с крышкой.
      </p>
      <Quote>Блюдце символизирует землю, крышка — небо, сама чашка — человека</Quote>
      <div className={s.splash}>
        <img src="/img/stuff/gaiwan_opt/gaiwan4_1280.jpg" alt="" />
      </div>
      <p>
        Гайвань имеет и другое название — Сань Цхай Вань «чаша трех основ», где блюдце символизирует землю, крышка —
        небо, а сама чашка — человека. Именно поэтому, находясь между небом и землей, человек обретает гармонию, в том
        числе и во время чаепития.
      </p>
      <picture>
        <img src="/img/stuff/gaiwan_opt/gaiwan2_1280.jpg" alt="" />
        {/* <img src="/img/stuff/gaiwan_opt/gaiwan3_1280.jpg" alt="" /> */}
      </picture>
      <p>
        Ее возникновение относится к временам династии Мин, когда в Поднебесной повсеместно стали производить рассыпной
        чай. Стало понятно, что новый вид чая гораздо удобнее заваривать в специальной отдельной посуде в отличие от
        прессованного чая, который варили в котлах. Так пять-шесть веков назад в Поднебесной появилась гайвань.
      </p>
      <p>
        Новый чайный предмет как нельзя лучше соответствовал новому способу приготовления чайного напитка и новому
        чайному ритуалу. Сначала появились индивидуальные чашки, которые стало модно накрывать крышками (способ, до сих
        пор практикуемый в Японии), а позже к ним прибавился третий предмет – блюдце с углублением для чашки. Иногда это
        блюдце в Китае называют «лодочкой». Чайные мастера придумали его из любви к троичности, а функционально оно
        призвано защитить дно чашки от соприкосновения с поверхностью стола.
      </p>
      <p>
        {' '}
        Особенно популярна гайвань в провинции Сычуань, которая считается родиной всех новаций в чайной культуре, ведь
        само чайное дерево родом из этих мест.
      </p>
    </React.Fragment>
  ),
};

export default gaiwan;
