import React from 'react';
import { /* NavLink, */ useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import classNames from 'classnames';

import stuff from '../data/stuff';
// import { basic, listVariants, itemVariants } from '../data/framerVars';

import s from './StuffItem.module.css';
import sh from './share.module.css';

// import { ReactComponent as BackIcon } from '../svg/back.svg';

const basic = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
  exit: { opacity: 0 },
};

const imgVars = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0, transition: { delay: 0.5 } },
  exit: { opacity: 0, x: 100 },
};

const infoVars = {
  initial: { opacity: 1, y: 0 },
  animate: { opacity: 1, y: 0, transition: { delay: 0.2, staggerChildren: 0.15 } },
  exit: { opacity: 0, y: 50 },
};

const contVars = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

const StuffItem = () => {
  const params = useParams();
  const stuffData = stuff.find((el) => el.id === params.stuffId);

  return (
    <motion.section
      className={classNames([sh.PageWrapper, s.Tea])}
      variants={basic}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {/* <motion.div variants={contVars} className={s.Back}>
        <NavLink to={`/stuff`}>
          <BackIcon />
        </NavLink>
      </motion.div> */}
      <div className={s.Grid}>
        <motion.div variants={infoVars} className={s.InfoCenter}>
          <motion.div variants={contVars} className={s.chiName}>
            {stuffData.chiName}
          </motion.div>
          <motion.div variants={contVars} className={s.Name}>
            {stuffData.name} ∙ {stuffData.say} ∙ {stuffData.ruName}
          </motion.div>
          <motion.div variants={contVars} className={s.FullGrid}>
            {stuffData.content}
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default StuffItem;
