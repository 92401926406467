import React from 'react';

const hainan = {
  id: 'hainan',
  image: '/img/locations/hainan.jpg',
  name: 'Хайнань',
  chiName: '海南',
  ruName: '',
  say: 'Hǎinán',
  teas: [{ name: 'Цзинь Дин Пай Хун Суй Ча' }, { name: 'Бай Ша Люй Ча' }],
  content: (
    <React.Fragment>
      <p>
        Хайнань (в переводе «Южнее моря») в древности был заселен предками народности ли. Он был завоеван и вошел в
        состав китайского государства во II в. до н. э., а в VII-XII вв. волна ханьских переселенцев оттеснила местных
        жителей с прибрежных равнин в горы.
      </p>
      <p>
        Тропический остров Хайнань не более 400 тыс. лет назад был частью материка. Исследования останков древних
        животных, датированных 600-400 тыс. лет до н. э., показали, что флора и фауна острова и близлежащего полуострова
        ничем не отличались. Остров площадью почти 34 тыс. км2 мог отколоться в результате землетрясения, связанного с
        бурной вулканической деятельностью, прекратившейся на Хайнане только около 8 тыс. лет назад. О прошлых
        извержениях свидетельствуют кратеры потухших вулканов.
      </p>
      <p>
        Коренным населением Хайнаня считается народность ли, пришедшая на остров из южных областей Китая около 7 тыс.
        лет назад. Местная легенда трактует древнюю историю так: некий юноша пересек Южное море, на острове в горах
        нашел яйцо, из которого вышла девушка, ставшая его женой, и от них произошел народ ли. В том же ключе другая
        легенда описывает этнологию народа мяо: будто бы они произошли от собаки и принцессы. Отец принцессы пообещал
        дочь тому, кто одолеет вождя врагов. Победителем оказался дворовый пес, который принес голову вражеского
        предводителя.
      </p>
      <p>
        В самом деле, люди народности мяо всегда считались храбрыми воинами. По одной из версий, они оказались на
        острове как наемники ханьских правителей для службы в военном гарнизоне и подавления восстаний коренного
        населения, ведь ли отчаянно сопротивлялись с момента завоевания и включения в состав китайского государства во
        II в. до н. э. Крупные антиправительственные восстания тут вспыхивали и в XVI-XVII вв., и в конце XIX столетия.
      </p>
      <p>
        В первое время ханьцев на удаленном по тем меркам острове, который тогда называли «воротами в ад», было совсем
        немного: военный гарнизон на севере, да еще место ссылки неугодных чиновников (на Хайнане в этом качестве
        побывали пять министров эпохи Тан и Сун). Активное проникновение ханьцев на весь остров, особенно на плодородные
        прибрежные равнины, начинается уже при династии Тан (VII-X вв.). Местных жителей вытеснили в центральные
        гористые районы.
      </p>
      <p>
        Что касается выделяемых в отдельный народ хуэйцев, компактно проживающих на острове в городе Санья, то они по
        языку и внешне не отличаются от ханьцев, вот только традиционно исповедуют ислам. Их далекими предками были
        арабские и персидские торговцы, приплывавшие на торговых судах и бравшие в жены ханьских женщин. Потомство от
        таких смешанных браков упоминается уже в период Северной Сун (960-1127 гг.) как «хуэй-хуэй». При династии Мин
        (1368-1644 гг.), терпимо относившейся ко всем религиям, хуэйцы сформировались как самостоятельная группа. Во
        время правления династии Цин (1644-1911 гг.) начались гонения, но даже при коммунистах хуэйцы смогли отстоять
        свое право на самобытность.
      </p>
      <p>
        Во второй половине XIV в. остров вошел в состав китайской провинции Гуандун. В 1876 г. цинское правительство
        открыло порт Хайкоу для иностранной торговли. В 1890-1891 гг. ли восставали против цинских властей, а в
        1927-1936 гг. Хайнань являлся одним из районов вооруженной борьбы против гоминьдановских властей. Когда в
        1939-1945 гг. остров был оккупирован, местное население вело партизанскую борьбу против японских войск, а после
        войны, в 1946-1949 гг., это был район активного противостояния уже гоминьдановской реакции. В 1952 г. на Хайнане
        был создан автономный округ народностей ли и мяо, входящий в провинцию Гуандун, а в 1988 г. Хайнань стал
        отдельной провинцией.
      </p>
      <p>
        Горы, холмы, платформы и равнины острова Хайнань образуют ярусную архитектонику. Средняя высота вершин — 500-800
        м над уровнем моря, 81 из них поднимается выше 1000 м, а самые высокие горы (Учжишань, Ингэлин, Эцзунлин,
        Хоумилин, Яцзядалин и Дяолошань) преодолели 1500 м. С горных массивов в центре острова радиально стекают
        довольно крупные реки. В горах — муссонные тропические леса, равнины возделаны.
      </p>
      <p>
        Правительство Китая и управление по туризму провинции Хайнань всерьез озаботились превращением этого
        тропического острова в место туристического паломничества — курортный центр мирового уровня.
      </p>
      <p>
        Находясь на одной широте с Гавайями, остров Хайнань имеет прекрасный климат, отличные условия для орошения и
        очень хорошую экологию: чистейшие воздух и море. Здесь растут разнообразные тропические фрукты (более 50 видов),
        кокосы и сахарный тростник; кофе, чай и какао (завезено в 1954 г.), хлопчатник, а также более 120 видов овощей.
        Урожаи риса собирают по 2-3 раза в год. В общем, идеальные условия для сельского хозяйства. Как и для въездного
        туризма, как постановило правительство, запустив в 1999 г. проект Международного туристического центра. К
        развитию индустрии международного туризма китайцы подошли очень толково, в первую очередь занявшись вопросами
        экологии, организацией десятков парков-заповедников и питомников диких зверей и благоустройством естественных
        песчаных отмелей береговой линии — превращением их в многокилометровую цепочку благоустроенных пляжей.
      </p>
      <p>
        Теплое и прозрачное Южно-Китайское море тоже «благоустроено»: теперь по закону в пределах «острова
        международного туризма» запрещен сброс вредных веществ. Дно тщательно очищено от мусора, ведь и в подводный мир
        предусмотрены экскурсии — для дайвингистов и на подводных лодках.
      </p>
    </React.Fragment>
  ),
};

export default hainan;
