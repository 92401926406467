import React from 'react';

import s from './Quote.module.css';

const Quote = (props) => {
  const { children, cite } = props;
  return <blockquote className={s.Quote}>{children}</blockquote>;
};

export default Quote;
