import React from 'react';

const guizhou = {
  id: 'guizhou',
  image: '/img/locations/guizhou.jpg',
  name: 'Гуйчжоу',
  chiName: '贵州',
  ruName: '',
  say: 'Gùizhōu',
  teas: [{ name: 'Мао Цзянь' }, { name: 'Гуй Дин Сюэ Я' }, { name: 'Инь Цю Ча' }],
  content: (
    <React.Fragment>
      <p>
        Гуйчжоу (как китайцы ее называют кратко — Гуй) — провинция, занимающая почти всю территорию Юньнань-Гуйчжоуского
        нагорья, где горы повышаются с востока на запад от 700 до почти 3000 м. Горы относительно старые, почему и
        оказались к нашему времени прорезанными глубокими речными долинами. Горы сложены в основном известняковыми
        породами, отчего здесь развитый карст, много пещер, подземных рек, воронок, впадин и водопадов.
      </p>
      <p>
        В условиях господствующего влажного субтропического климата произрастают леса с ценными породами деревьев.
        Правда, в связи с интенсивной вырубкой под строительство и просто на дрова эти прекрасные девственные леса
        сохранились лишь на верхних участках склонов гор. Все, что ниже, — полностью террасированы и освоены под
        земледелие. Рек в провинции много, Гуйчжоу служит водоразделом речных систем рек Янцзы и Чжуцзян, реки питаются
        за счет ливневых осадков, приносимых муссонами.
      </p>
      <p>
        Археологические данные подтверждают, что территория нынешнего Гуйчжоу начала заселяться китайцами в III в. до н.
        э. В те далекие времена сюда стремились те, кто хотел избежать гибели в войнах за передел плодородных земель в
        долинах Янцзы и Хуанхэ. Многие правители избирали Гуйчжоу как место ссылки своих противников, хотя для тех
        пребывание в этих горах было просто спасением.
      </p>
      <p>
        Данные о том, когда именно Гуйчжоу удостоился статуса провинции, расходятся. Согласно одним данным, это
        произошло в 1413 г., по другим сведениям — провинция Гуйчжоу образована во второй половине XVII в. на основе
        Гуйчжоуского административного управления, объединявшего в XV-XVII вв. несколько областей. Это решение также
        нелегко объяснить, потому что управлять такой громадной территорией, сплошь покрытой непроходимыми горами, было
        трудно, а еще труднее — собирать налоги.
      </p>
      <p>
        Понятно, что национальные меньшинства, дотоле терпевшие власть ганцев, сплотились, и в 1735 г. в Гуйчжоу
        вспыхнуло крупное восстание народности мео против маньчжуро-китайских властей, которое было потоплено в крови в
        1736 г. Прошло сто лет, и в 1855-1872 гг. Гуйчжоу снова была охвачена восстаниями мео и присоединившихся к ним
        других горных национальных меньшинств. Руководство восстанием было в руках тайных религиозных сект и местной
        феодальной знати, и пекинское правительство, опираясь на богатый многовековой опыт политики «разделяй и
        властвуй», вновь подавило восстание. Других попыток выступать против ханьцев уже не было.
      </p>
      <p>
        В октябре 1934 — апреле 1935 гг. территория Гуйчжоу стала ареной действий Красной армии Китая, совершавшей в то
        время свой знаменитый Северо-западный поход. Неожиданный экономический взлет провинция пережила во время
        японо-китайской войны 1937-1945 гг.: в Гуйчжоу были эвакуированы десятки промышленных предприятий из центральных
        районов страны вместе с рабочими и специалистами, что способствовало экономическому развитию провинции.
      </p>
      <p>
        Однако после окончания войны необходимость в Гуйчжоу как в тыловой базе исчезла, и эта территория вновь
        вернулась к исконно крестьянскому труду. Гуйчжоу не совсем типичен для этого района Китая, поскольку китайцев
        здесь не столь много, как в соседних провинциях.
      </p>
      <p>
        Более трети всего населения провинции составляют представители двенадцати национальных меньшинств, и все они
        живут преимущественно в горах. Китайцев (хань) — две трети, их количество не столь заметно растет, как в других
        районах страны. Это объясняется как чисто экономическими, так и политическими причинами. Гуйчжоу — район
        относительно тихий, волнения на политической почве здесь прекратились еще при императорах, в XIX в., и
        пекинскому правительству не приходится проводить политику увеличения присутствия китайцев как фактора
        сдерживания нацменьшинств. Что еще важнее, в районе отсутствует развитая крупная промышленность, нет больших
        городов, в которых главным образом оседают китайцы, и население в основном занято в сельском хозяйстве.
      </p>
      <p>
        Результатом многочисленных попыток ханьцев заселить этот район китайцами стало появление города Тьянлон-Тунбао
        (чаще его именуют просто Тунпу). Сегодня это историческое поселение и место, куда стекаются туристы со всего
        Китая и китайцы со всего мира. Здесь живут тунбао — официально непризнанная пекинским правительством этническая
        группа ханьцев. Они потомки солдат, присланных сюда в эпоху династии Мин (1368-1398 гг.) подавлять очередное
        выступление народности мео. Они говорят на смеси китайского и языка мео, но у них особая культура, вплоть до
        собственной оперы.
      </p>
      <p>
        Название провинции Гуйчжоу в переводе с китайского языка звучит несколько двусмысленно: «гуй» — «дорогой,
        ценный», а «чжоу» — «округ, область», а вместе — примерно как «дорогая земля». Однако в настоящее время
        экономика провинции остается малоразвитой, хотя недра провинции, используя язык учебника истории китайской
        школы, «буквально извергают природные богатства».
      </p>
    </React.Fragment>
  ),
};

export default guizhou;
