import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import NamesTable from './NamesTable';

import { basicStagger, basicItem } from '../data/framerVars';

import s from './Dictionary.module.css';
import sh from './share.module.css';

const Dictionary = () => {
  return (
    <motion.article
      variants={basicStagger}
      initial="initial"
      animate="animate"
      exit="exit"
      className={classNames([sh.PageWrapper, s.Dictionary])}
    >
      <motion.div variants={basicItem} className={s.Block}>
        <h2>茶的种类</h2>
        <h3>Виды чая</h3>
        <ul>
          <li>Бай Ча 白茶 Белый чай.</li>
          <li>Люй Ча 绿茶 Зеленый чай.</li>
          <li>Хуан Ча 黄茶 Желтый чай.</li>
          <li>Хун Ча 红茶 Красный чай.</li>
          <li>Улун 乌龙茶</li>
          <li>Пуэр Ча 洱茶</li>
          <li>Шэн Пуэр 生普洱 (Сырой пуэр)</li>
          <li>Шу Пуэр 熟普洱 (Готовый пуэр)</li>
          <li>Хэй Ча 黑茶 Черный чай.</li>
          <li>Дянь Хун 滇红 Юньнаньский красный чай.</li>
          <li>Дань Цун 单丛 Улун с одиноких кустов горы Феникса.</li>
          <li>Дун Дин 冻顶 Вид тайваньских улунов.</li>
        </ul>
        <p>Примеры: Гу Шу Хун Ча — Красный чай со старых деревьев, Моли Люй Ча — Жасминовый Зеленый Чай.</p>
      </motion.div>

      <motion.div variants={basicItem} className={s.Block}>
        <h2>原料</h2>
        <h3>Сырье</h3>
        <p>С каких кустов или деревьев были собраны листья чая</p>
        <ul>
          <li>
            Гу Шу 古树 старые деревья. Чаще встречается шу и шэн пуэры со старых деревьев, но могут быть и другие виды,
            как в примере выше.
          </li>
          <li>Цяо Му 乔木 прямоствольное дерево. До статуса старого оно еще не доросло. Тоже относится к пуэрам.</li>
          <li>Сяо Шу 小树 ­ маленькое дерево.</li>
          <li>Сяо Чжун 小锺 маленький куст.</li>
          <li>Тай Цзи — плантационные кусты.</li>
          <li>Е — лист.</li>
          <li>Сяо Е ­— маленькие листья.</li>
          <li>Е Шен 野生 дикорастущий чай.</li>
          <li>
            Мао Ча 毛茶 чай-сырец. Несортированный улун, часто с веточками, и не прессованный чай в случае с шэн
            пуэрами. (Габа Мао Ча, Гу Шу Мао Ча)
          </li>
          <li>
            Да 大 большой. Да Хун Пао — большой красный халат. Да Бай — большой белый сорт. Да Шу — большое дерево.
          </li>
        </ul>
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        <h2>等级</h2>
        <h3>Сорт и вид чая</h3>
        <ul>
          <li>Бин 饼 блин. То есть то, что спрессовано в форму блина. Не обязательно пуэр.</li>
          <li>То Ча 沱茶 Чаша. Маленький выгнутый блинчик с выемкой посередине. Я 芽 почки.</li>
          <li>Мэй 眉 брови. Чай в форме бровей.</li>
          <li>Гун Тин 宫廷 Дворцовый. Мелколистовое сырье пуэра.</li>
          <li>Лао 老 Старый, то есть чай вылеживался несколько лет.</li>
          <li>Цзинь 金 золотой. Например, золотые почки.</li>
          <li>Хао 毫 ворс. Например, ворсистые почки.</li>
          <li>Гао Шань 高山 Высокогорный.</li>
          <li>Мао Цзянь 毛尖 ворсистые лезвия (Е Шен Мао Цзянь)</li>
          <li>Мао Фэн 毛峰 ворсистые пики (Дянь Хун Мао Фэн)</li>
        </ul>
        <p>Примеры: Гун Тин Сяо Я, Е Шен Мао Цзянь, Да Сюэ Шань Мао Ча, Лао Я Бао Бин, Цзинь Цзюнь Мэй.</p>
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        <h2>香气</h2>
        <h3>Аромат</h3>
        <ul>
          <li>Сян 香 аромат, ароматный.</li>
          <li>Хуа Сян 花香 цветочный аромат.</li>
          <li>Гуо Сян ­果香 фруктовый аромат.</li>
          <li>Цин Сян 清香 свежий, прозрачный, чистый аромат.</li>
          <li>Нун Сян 浓香 плотный, густой аромат.</li>
          <li>Най Сян 奶香 молочный аромат.</li>
          <li>Чень Сян 陈香 выдержанный аромат, аромат старости.</li>
          <li>Ми Сян — медовый аромат.</li>
          <li>Пу Тяо Сян — виноградный аромат.</li>
          <li>Цзю Сян — аромат хризантемы.</li>
          <li>Е Лай Сян — аромат туберозы.</li>
        </ul>
        <p>
          Примеры: Най Сян Цзинь Сюань, Ми Сян Хун Шуэй Улун, ГАБА Пу Тяо, Цзю Сян Гао Шань Дань Цун, Те Гуань Инь Цин
          Сян, Е Лай Сян Дань Цун.
        </p>
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        <h2>热身</h2>
        <h3>Прогрев</h3>
        <ul>
          <li>Цин Хо 轻火 лёгкий прогрев.</li>
          <li>Чжун Хо 中火 средний прогрев.</li>
          <li>Цзу Хо 足火 достаточный прогрев.</li>
          <li>Гао Хо 高火 сильный прогрев.</li>
        </ul>
        <p>Примеры: ГАБА Чжун Хо, Жень Шень Улун Гао Хо.</p>
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        <h2>诗意的名字</h2>
        <h3>Поэтические названия</h3>
        <ul>
          <li>Да Хун Пао 大红袍 Большой Красный Халат</li>
          <li>Жоу Гуй 肉桂 Корица</li>
          <li>Шуй Сянь 水仙 Водяной Бессмертный</li>
          <li>Ци Лань 奇兰 Чудесная Орхидея</li>
          <li>Фо Шоу 佛手 Ладонь Будды</li>
          <li>Шуй Цзинь Гуй 水金龟 Водяная Золотая Черепаха</li>
          <li>Бай Цзи Гуань 白鸡冠 Белый Петушиный Гребень</li>
        </ul>
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        <h2>常用词</h2>
        <h3>Словарик часто встречающихся слов</h3>
        <ul>
          <li>Лун 龙 Дракон. Например, Лун Цзин.</li>
          <li>Ба 八 Восемь.</li>
          <li>Сянь 仙 Бессмертный. Например, Ба Сянь.</li>
          <li>Лань 兰 Орхидея. Например, Моли Лань Сюэ.</li>
          <li>Гун 宫 Дворцовый. Например, Гун Тин, Гун Мэй.</li>
          <li>Инь 银 Серебрянный. Например, Инь Ло, Бай Хао Инь Чжэнь.</li>
          <li>Ван 王 Владыка. Например, Лао Ча Ван, Инь Чжэнь Ван.</li>
          <li>
            Цзи Пин 极品 Высшее качество. Например, Цзюнь Шань Инь Чжэнь Цзи Пин. Вэй Шань 味山 Внешние горы, соседние
            горы. Например, Вэй Шань Сяо Чжун. Чжэнь
          </li>
          <li>
            Шань 正山 Настоящие, правильные горы. Например, Чжэнь Шань Сяо Чжун. Дин 顶 Пик. Например, Лун Дин, Мэн Дин,
            Дун Дин.
          </li>
          <li>Лянь 莲 Лотос. Например, Люй Лянь.</li>
          <li>Му Дань 牡丹 Пион. Например, Бай Му Дань.</li>
          <li>Юй 玉 Нефрит. Например, Люй Юй Лу.</li>
          <li>Юнь 云 Облако. Например, Моли Юнь Фэн.</li>
          <li>Гань Лу 甘露 Сладкая роса. Например, Мэн Дин Гань Лу.</li>
          <li>У 雾 Туман. Например, Мэн Дин Юнь У.</li>
          <li>Сун 松 Сосна. Например, Лао Сун Сяо Чжун.</li>
          <li>Гуй 桂 Корица. Хуан Цзинь Гуй 黄金桂 Золотая Корица.</li>
          <li>Се 蟹 Краб. Например, Мао Се.</li>
          <li>Те 铁 Железо. Например, Те Гуань Инь, Те Ло Хань.</li>
          <li>Цюй 翠 Драгоценный. Например, Цюй Юй.</li>
          <li>Шань Линь Си 杉林溪 Ручей горного леса.</li>
          <li>Хун Шуэй 红水 Красная вода.</li>
          <li>Мэй Жень 美人 Красавица. Например, Дун Фан Мэй Жень, Мэй Жень Бай Ча.</li>
        </ul>
        <p>Примеры:</p>
        <ul>
          <li>
            Цзинь Я Дянь Хун Ча. Цзинь — золото. Я — почки. Дянь Хун — юньнаньский красный. Ча — чай. Итого получаем —
            Юньнаньский красный чай с золотыми почками.
          </li>
          <li>
            Мэн Сун Цяо Му Бай Ча: Мэн Сун — название гор, Цяо Му — Прямоствольное дерево, Бай Ча — Белый чай. Собираем
            паззл и получается: Белый чай с гор Мэн Сун, собранный с прямоствольных деревьев.
          </li>
          <li>
            Масу Гао Шань Хун Ча: Масу — деревня в Уишань, Гао Шань — высокая гора, Хун Ча — красный чай. Высокогорный
            красный чай из деревни Масу.
          </li>
        </ul>
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        <h2>生产量</h2>
        <h3>Производство</h3>
        <ul>
          <li>Ша Цин 杀青 – «убийство зелени».</li>
          <li>Жоу Нянь 揉捻 – сминание-скручивание.</li>
          <li>Гань Цзао 干燥 – сушка</li>
          <li>Вэй Дяо 萎凋 – подвяливание</li>
          <li>Жоу Це 揉切 – резка</li>
          <li>Фа Цзяо 发酵 – ферментация</li>
          <li>Го Хун Го 过红锅 — «пройти красный котёл»</li>
          <li>Сюнь Бэй 薰焙 – копчение</li>
        </ul>
      </motion.div>
      <motion.div variants={basicItem} className={s.TableBlock}>
        <h2>名字</h2>
        <h3>Названия</h3>
        <NamesTable />
      </motion.div>
    </motion.article>
  );
};

export default Dictionary;
