import React from 'react';
import { motion } from 'framer-motion';

import TeaTypeBlock from '../components/TeaTypeBlock';

import s from './Main.module.css';

import data from '../data/data';
import { basic, listVariants } from '../data/framerVars';

const Main = () => {
  return (
    <motion.section variants={basic} initial="initial" animate="animate" exit="exit" className={s.Main}>
      <motion.ul initial="initial" animate="enter" exit="exit" variants={listVariants} className={s.TeaTypeList}>
        {data.map((tType, i) => {
          return <TeaTypeBlock key={tType.name} data={tType} />;
        })}
      </motion.ul>
    </motion.section>
  );
};

export default Main;
