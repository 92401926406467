const BiLuoChun = {
  id: 'BiLuoChun',
  name: 'Билочунь',
  altName: 'Дунтин Билочунь',
  image: '/img/tea/BiLuoChun.png',
  chiName: '碧螺春',
  altChiName: '洞庭碧螺春',
  enName: 'Bi Luo Chun',
  altEnName: 'Dong Ting Bi Luo Chun, Green Snail Spring',
  ruName: 'Изумрудные спирали весны',
  altRuName: 'Дунтин Билочунь, Би Ло Чунь',
  location: 'только провинция Цзянсу',
  say: 'Bìluóchūn',
  legend:
    'Легенда гласит, что изначально Дунтинь Билочунь назывался Ся Шань Жень Сян (невероятный аромат). А все потому, что сборщики чая, желая унести с плантации как можно больше для поставки к императорскому двору, заполнили даже карманы и полы халатов. От нагрева они начали благоухать, поэтому чаю и дали такое название. Лишь потом, в конце 17-го века император Кан Си попробовал этот чай во время своего визита на озеро Тай Ху и был действительно изумлен его ароматом, но название показалось ему вульгарным и недостойным такого чая. Выпив чай, император посмотрел на листья, напоминавшие ракушки улиток, и назвал чай Би Ло Чунь (зеленые спирали весны).',
  howto: {
    text:
      'Заваривать кипятком, остывшим до 70-75°С в фарфоровой гайвани, чайнике из пористой глины или стеклянной посуде. Пропорция заварки к воде: 3-4 гр на 100 мл. Пить проливом с постепенным увеличением экспозиции. Выдерживает 7-8 завариваний.',
    temp: '70-75',
    ratio: '3-4',
    time: '3',
  },
  taste: {
    grass: 4,
    flower: 2,
    nuts: 3,
  },
  headerMargin: 12,
};

export default BiLuoChun;
