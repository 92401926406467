import React from 'react';

import s from '../../pages/StuffItem.module.css';

const chahe = {
  id: 'chahe',
  image: '/img/stuff/chahe.png',
  name: 'Чайная коробочка',
  chiName: '茶荷',
  ruName: 'чахэ',
  say: 'cháhé',
  content: (
    <React.Fragment>
      <p>
        Чайный лепесток, или чайная коробочка, чахэ – это специальный предмет, предназначенный для «знакомства с чаем».
        Чайный лист из чайницы пересыпается в чахэ.{' '}
      </p>
      <div className={s.splash}>
        <img src="/img/stuff/chahe_opt/chahe5_1280.jpg" alt="" />
      </div>
      {/* <p>
        Ведущий чаепития первым согревает чайный лист дыханием, плотно обхватив чахэ обеими ладонями и поднеся его
        близко к лицу. Согревание чая означает медленный плавный выдох через нос и такой же медленный спокойный вдох.
      </p> */}
      <p>
        По одной из версий чахэ появилось еще в древнем Китае. Когда Шэнь-Нун открыл людям чай, люди стали использовать
        его в качестве подношения божествам. А когда чай стал национальным напитком, его стали подносить императорам.
        Для этого использовалась небольшая коробочка из глины, в которую насыпали чай. Эту коробочку ставили на алтарь
        или давали в руки императору, чтобы тот мог увидеть качество подносимого ему чая. Изобретение было очень удобным
        и стало применяться в чайных церемониях, а в последующем почти при каждой дегустации.
      </p>
      <p>
        Церемония начаналась с того, что ведущий засыпал в чахе чай и представлял его гостям, затем его передавали по
        часовой стрелке. В процессе каждый мог ознакомиться с тем, что он будет пить, потрогать руками и вкусить аромат.
        Для вкушения аромата гость делал три выдоха на чай и три вдоха. Это помогало разбудить дух чая и познакомиться с
        его истинным ароматом.
      </p>
      <picture>
        <img src="/img/stuff/chahe_opt/chahe2_1200.jpg" alt="" />
      </picture>
      <p>
        В дегустациях Пин Ча, чахэ использовалось как инструмент, позволяющий увидеть целостность листа, наличие пыли
        или крошки. С помощью чайной коробочки можно было легко определить правильно ли чай хранился, а так же его
        свежесть и качество.
      </p>
    </React.Fragment>
  ),
};

export default chahe;
