import React from 'react';

import s from '../../pages/StuffItem.module.css';

const chaban = {
  id: 'chaban',
  image: '/img/stuff/chaban.png',
  name: 'Чайная доска',
  chiName: '茶板',
  ruName: 'чабань',
  say: 'chábǎn',
  content: (
    <React.Fragment>
      <div className={s.splash}>
        <img src="/img/stuff/chaban_opt/chaban1_1280.jpg" alt="" />
      </div>
      <p>
        Чайная доска формирует пространство для чаепития – это незаменимый элемент классической чайной церемонии «Пин
        Ча», или вдумчивой дегустации чая. На чабани располагается вся необходимая посуда и чайные инструменты. Более
        того, ча бань упрощает чаепитие – на нее свободно можно лить воду, благодаря поддону или специальной трубке для
        отвода воды, пролитая жидкость не будет мешать чаепитию.
      </p>
      <p>
        Снаружи это изящный столик, декорированный резьбой или инкрустацией, а внутри него – поддон, в который сливается
        вода с поверхности.{' '}
      </p>
      <p>Чаще всего чайные доски делают из дерева, бамбука и прессованной каменной крошки, реже – из металла.</p>
      <picture>
        <img src="/img/stuff/chaban_opt/chaban4_1280.jpg" alt="" />
      </picture>
      <p>
        Первые упоминания чайной доски, как элемента некоего ритуального действа, относятся к даосским и буддийским
        монахам. В горах Тай Шань (провинция Шаньдун), около 1500 лет назад, даос Шуанг Ван впервые начал использовать
        плоский камень, в качестве стола для чаепитий. После, ритуальные чаепития стали проводиться в буддийских
        монастырях. Чабани начинают изготавливать из различных пород древесины, в основном – из бамбука. Со временем,
        чайные доски и столы стали появляться и в императорских домах. По большей части, население Китая не использовало
        чайные доски аж до 1978 года. В те годы на материк с Тайвани начинает поступать новая посуда и разнообразная
        утварь, среди которой были и чабани.
      </p>
    </React.Fragment>
  ),
};

export default chaban;
