import React from 'react';

import fujian from './locations/fujian';
import guangdong from './locations/guangdong';
import hunan from './locations/hunan';
import hubei from './locations/hubei';
import jiangxi from './locations/jiangxi';
import yunnan from './locations/yunnan';
import zhejiang from './locations/zhejiang';
import guizhou from './locations/guizhou';
import guanxi from './locations/guanxi';
import taiwan from './locations/taiwan';
import hainan from './locations/hainan';
import jiangsu from './locations/jiangsu';
import anhui from './locations/anhui';
import henan from './locations/henan';

const locations = [
  fujian,
  guangdong,
  hunan,
  hubei,
  jiangxi,
  yunnan,
  zhejiang,
  guizhou,
  guanxi,
  taiwan,
  hainan,
  jiangsu,
  anhui,
  henan,
  // {
  //   id: 'anxi',
  //   image: '/img/locations/anxi.jpg',
  //   name: 'Аньси',
  //   chiName: '安溪县',
  //   ruName: '',
  //   say: 'Ānxī xiàn',
  //   content: <React.Fragment></React.Fragment>,
  // },
  // {
  //   id: 'wuyishan',
  //   image: '/img/locations/wuyishan.jpg',
  //   name: 'Уишань',
  //   chiName: '武夷山',
  //   ruName: '',
  //   say: 'Wǔyí Shān',
  //   content: <React.Fragment></React.Fragment>,
  // },
];

// , Юньнань //Юндэ

export default locations;
