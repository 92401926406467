import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter /* , useLocation */ } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './index.css';

// kick off the polyfill!
smoothscroll.polyfill();

// const ScrollToTop = () => {
//   const { pathname } = useLocation();

//   React.useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// };

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <ScrollToTop /> */}
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
