import React from 'react';

import s from './TasteChart.module.css';

import { ReactComponent as Dash } from '../svg/dash.svg';

import tastes from '../data/tastes';

const getLabel = (slug) => {
  const taste = tastes.find((el) => el.slug === slug);
  return taste ? taste.label : '';
};

const getDots = (num) => {
  let dots = [];
  for (let i = 0; i < num; i += 1) dots.push(<b key={`b${i}`}>•</b>);
  for (let i = 0; i < 5 - num; i += 1) dots.push(<em key={`em${i}`}>•</em>);
  return dots;
};

const TasteChart = (props) => {
  const { taste } = props;
  if (!taste) return null;
  return (
    <div className={s.TasteChart}>
      <div className={s.chiName}>品味</div>
      <div className={s.Grid}>
        {Object.keys(taste).map((key, i) => {
          return (
            <React.Fragment key={i}>
              <div>{getLabel(key)}</div>
              <div className={s.Center}>
                <Dash />
              </div>
              <div className={s.Dots}>{getDots(taste[key])}</div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default TasteChart;
