import React from 'react';
import Quote from '../../components/Quote';

import s from '../../pages/StuffItem.module.css';

const chabei = {
  id: 'chabei',
  image: '/img/stuff/chabei.png',
  name: 'Чашка',
  chiName: '茶杯',
  ruName: 'чабэй',
  say: 'chábēi',
  content: (
    <React.Fragment>
      <p>
        Чашка чавань была изначально предназначена именно для питья чая. Китайцы говорят, что чашку делает чашкой
        заключенная внутри нее пустота, а если бы этой пустоты не было, то и пить чай было бы невозможно.
      </p>
      <Quote>Чашку делает чашкой заключенная внутри нее пустота</Quote>
      <div className={s.splash}>
        <img src="/img/stuff/chabei_opt/chabei2_1280.jpg" alt="" />
      </div>
      <p>
        Наиболее распространенные в Китае материалы для изготовления чашек – фарфор, керамика, стекло, реже камень.
        Другие материалы – металл или дерево – применяются в странах, где чайная культура была воспринята от китайцев и
        породила местные особенности, например в Японии или Таиланде.
      </p>
      <picture>
        <img src="/img/stuff/chabei_opt/chabei1_1280.jpg" alt="" />
      </picture>
      <p>
        По китайским источникам, знаменательное открытие фарфора случилось в эпоху Тан. Прекрасный с виду, напоминающий
        «императорский камень» цветом и блеском, фарфор был значительно удобнее в обработке, чем твердый нефрит. Новый
        материал назвали таоцы, где тао обозначает керамику, а цы – ее подвид фарфор. Сегодня словосочетанием цыци
        китайцы называют любые изделия из фарфора.
      </p>
      <p>
        В начале Х века для развития фарфорового производства в Поднебесной были организованы мастерские в Цзиндэчжэне,
        провинция Цзянсу. Они очень быстро развились в мировой центр фарфорового производства, репутация которого
        неоспорима и поныне. Особое место в продукции Цзиндэчжэня во времена династии Сун занимала керамика, близкая
        селадонам, производство которой было налажено приезжими из Лунцюаня мастерами. Основное место в палитре глазурей
        заняли пастельные тона – оттенки бирюзового, голубого и зеленого цветов.
      </p>
      <div className={s.splash}>
        <img src="/img/stuff/chabei_opt/chabei3_1280.jpg" alt="" />
      </div>
      <p>
        В качестве термоустойчивых красок китайцы долгое время использовали кобальт и гематит, а отделка эмалями стала
        применяться только в XVII веке. Именно подглазурная роспись кобальтом дала миру знаменитый белый фарфор с синим
        рисунком.
      </p>
    </React.Fragment>
  ),
};

export default chabei;
