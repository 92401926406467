import React from 'react';

const anhui = {
  id: 'anhui',
  image: '/img/locations/anhui.jpg',
  name: 'Аньхой',
  chiName: '安徽',
  ruName: '',
  say: 'Ānhuī',
  teas: [
    { name: 'Хуан Шань Мао Фэн' },
    { name: 'Аньхой Мао Цзянь' },
    { name: 'Ци Мень Мао Фэн' },
    { name: 'Лу Ань Гуа Пянь' },
    { name: 'Тай Пин Хоу Куй' },
    { name: 'Дин Гу Да Фан' },
    { name: 'Фу Си Гун Пинь' },
  ],
  content: (
    <React.Fragment>
      <p>
        Китайская провинция Аньхой, или, как ее еще кратко называют, Вань, занимает большую территорию в нижнем течении
        реки Янцзы.
      </p>
      <p>
        Северная часть провинции — в пределах Великой Китайской равнины, полностью распаханной еще тысячу лет назад. На
        западе и юге — средневысотные горы, самые известные из которых — хребет Хуаншань, получивший известность как
        «прекраснейшие горы Китая», чьи сосновые леса воспеты во многих литературных и музыкальных произведениях.
        Современное название (Желтые горы) им дал поэт Ли Бо еще в VIII в. за главную особенность этой горной гряды —
        золотистого цвета горные пики. С 1990 г. район гор Хуаншань входит в список Всемирного наследия ЮНЕСКО и
        является национальным парком КНР.
      </p>
      <p>
        Вторая после гор Хуаншань природная достопримечательность провинции — самое большое в Аньхое озеро Чаоху
        площадью около 800 км2. Оно же — самая большая экологическая проблема провинции: миллионы человек используют
        озеро для орошения, рыболовства и транспорта, что привело к сильному заилению вод Чаоху, ставшего одним из самых
        загрязненных озер Китая.
      </p>
      <p>Через провинцию протекают крупнейшие реки Китая — Янцзы и Хуайхэ.</p>
      <p>До второй половины XVII в. названия Аньхой просто не было.</p>
      <p>
        Территория сегодняшней провинции Аньхой была аграрным районом древнего царства Чу, существовавшего в VIII-III
        вв. до н. э. На плодородных землях долины Янцзы выращивалось много риса, и за эти угодья царство Чу вело
        непрестанные войны с соседями.
      </p>
      <p>
        Долгое время Чу оставалось непобедимым, пока ханьская династия Лю не заключила союз с другими соседями. Династия
        Чу была побеждена, земли прежнего царства, включая и территорию нынешней провинции Аньхой, стали частью Ханьской
        империи на последующие четыре столетия.
      </p>
      <p>
        Долгое время большая история творилась в стороне от рисовых полей Аньхой. Когда Ханьской империей стали
        управлять евнухи от имени малолетних императоров, начались восстания недовольных. И самое известное — Восстание
        Желтых повязок (184-204 гг.): народный бунт начался именно в районе нынешней Аньхой. Оно и стало одной из причин
        падения Ханьской империи: правящая династия ослабла, и в 220 г. последний император отрекся от престола.
      </p>
      <p>
        В дальнейшем этот район оставался безымянной житницей империй, сменявших друг друга, не обладая никаким
        административным статусом. Пока не образовалась империя Цинь, которой управляла последняя династия
        монархического Китая.
      </p>
      <p>
        Период правления императора Канси (1654-1722 гг.) считается в Китае символом благополучия и «золотым веком»
        империи. Чтобы править страной, император Канси сосредоточил в своих руках всю власть. Чтобы добиться
        максимального подчинения и контроля, Канси провел реформу административно-территориального деления страны, в
        частности сократил армию чиновников. Для этого он просто уменьшил число округов. Тогда-то на карте страны и
        появилась провинция Аньхой, чье название образовано из первых слогов названий областей Аньцин и Хойчжоу, что
        ранее были на месте Аньхой.
      </p>
      <p>До наших дней провинция сохранилась примерно в границах эпохи императора Канси.</p>
      <p>
        Китайская провинция Аньхой находится на Великой Китайской равнине, в нижнем течении реки Янцзы, пересекающей
        провинцию от края до края.
      </p>
      <p>
        Большая крестьянская провинция — именно так называют Аньхой сами китайцы, что в их понимании значит то же самое,
        что «очень бедный край».
      </p>
      <p>
        Население провинции — в основном приверженцы традиционной китайской религии, даосизма и буддизма. Провинция
        входит в круг буддийского паломничества: здесь находится Цзюхуашань, или Гора девяти прелестей, — одна из
        четырех священных гор китайского буддизма. Правда, добраться до нее совсем непросто, и посещают гору лишь самые
        верные адепты буддизма. В то же время среди народа шэ все еще сильны обычаи анимизма и поклонения духам предков.
      </p>
      <p>
        За многие века занятий сельским хозяйством леса на равнинах повырубили, и в наше время почти сплошь возделанная
        низина подвержена наводнениям реки Хуайхэ. Здесь живут десятки миллионов человек, и, чтобы справиться с
        катастрофой, на притоках Хуайхэ построены крупные водохранилища: Фоцзылинское на реке Пихэ, Мэйшаньское на реке
        Шихэ. Северная и южная части провинции получают с муссонами разное количество осадков, поэтому и специализация у
        них разная: на севере — сладкий картофель, на юге — рис. И уж совершенно особенная, только для Аньхой
        свойственная культура — пробковое дерево: его не высаживают, растет оно почти как сорняк, но благодаря ему
        провинция стала крупным поставщиком пробки на мировой рынок.
      </p>
      <p>
        Главные полезные ископаемые провинции — железная и медная руда и каменный уголь. Экономика провинции успешно
        развивается, так как обеспечена местным сырьем, в частности развита автомобильная промышленность: здесь
        находятся заводы автомобильной компании Chery, основанной в 1997 г. по инициативе мэрии города Уху. Основной
        акционер Chery — правительство провинции Аньхой.
      </p>
      <p>
        Долгое время проблемой провинции Аньхой было отсутствие современных транспортных магистралей. С 2009 г.
        действует высокоскоростная железная дорога Хэфэй — Ухань, соединившая столицы провинций Хубэй и Аньхой, по
        которой движутся составы со скоростью 250 км/ч. Эта китайская провинция — одно из немногих мест в мире, где
        целые деревни объявлены историческим памятником. Сиди и Хунцунь — два старинных села в китайской провинции
        Аньхой, которые лучше других сохранили традиционную планировку и деревянную застройку эпохи династий Мин и Цин.
        В Сиди, например, уцелело целых 124 деревянных дома названных эпох — в отличном состоянии, а в Хунцуни — 150. В
        2000 г. они были внесены в список Всемирного наследия ЮНЕСКО как Древние деревни Южной Аньхой. Аньхой — родина
        вэньфансыбао, или «четырех сокровищ науки»: традиционного набора предметов для занятий каллиграфией. Именно
        здесь сохранились традиции изготовления аксессуаров для каллиграфического письма: бумаги, чернил, кисточек и
        чернильниц. Предприятия в Сюаньчэне и Хуаншане по старинным технологиям изготавливают бумагу сорта «хуанши»
        (знаменитая рисовая бумага с высоким содержанием коры тутового дерева) и чернила «хуэй», считающиеся лучшими для
        занятий каллиграфией. В районе Шэ делают традиционные китайские каменные чернильницы. Хэфэй — столица провинции
        — один из древнейших городов Китая, самый большой город в Аньхой. В прошлом — торговый город на перепутье важных
        дорог, центр сельскохозяйственного района на плодородных почвах низовья Янцзы, разбогатевший на продаже зерна и
        растительного масла. В наши дни — крупный промышленный город, где производится значительная часть китайской
        электроники и тканей.
      </p>
    </React.Fragment>
  ),
};

export default anhui;
