const QimenHongCha = {
  id: 'QimenHongCha',
  name: 'Цимэнь Хун Ча',
  image: '/img/tea/QimenHongCha.png',
  chiName: '祁门红茶',
  enName: 'Qimen Hong Cha',
  ruName: 'Красный Чай из уезда Цимэнь',
  location: 'Уезд Цимэнь на юге провинции Аньхой',
  say: 'Qímén hóng chá',
  legend: '',
  history:
    'Его история насчитывает уже более ста лет. Ци Мэнь не такой старый, как его сородичи: его производство началось в конце XIX века, сразу же после того, как стал расти спрос на черный чай в Англии. Когда-то один чаевод Нун Ганьчэн путешествовал по провинции Фуцзянь, где увидел, как делают красный чай. По возвращении домой он решил попробовать применить фуцзянский способ обрабатывать чай и открыл чайный дом. До этого в Аньхой выращивали лишь зеленый чай, но увидев популярность красного чая, переориентировались на него, и начали с успехом производить и продавать красный чай, который с течением времени приобрел известность как чай Ци Хун.',
  howto: {
    text:
      'Заваривать кипятком 95°С в чайнике из пористой глины для красных сортов или в гайвани. Пропорция заварки к воде: 3-4 гр. на 100 мл. Пить проливом с постепенным увеличением экспозиции. Выдерживает 6-7 завариваний.',
    temp: '95',
    ratio: '4',
    time: '2',
  },
  taste: {
    bread: 2,
    flower: 2,
    berry: 3,
    spice: 3,
    trees: 4,
  },
  headerMargin: 8,
};

export default QimenHongCha;
