const AnJiBaiCha = {
  id: 'AnJiBaiCha',
  name: 'Аньцзи Бай Ча',
  image: '/img/tea/AnJiBaiCha.png',
  chiName: '安吉白茶',
  enName: 'An Ji Bai Cha',
  ruName: 'Белый Чай из уезда Аньцзи',
  location: 'Провинция Чжэцзян',
  legend: '',
  howto: {
    text:
      'Заваривать лучше всего в гайвани, крышечка которой ненадолго задержит нежный, летучий аромат. Кипяток остудить до 75-80°С. Пропорция заварки к воде: 3-4 г на 100 мл. Пить проливом с постепенным увеличением экспозиции. Выдерживает 7 заварок.',
  },
  headerMargin: 8,
};

export default AnJiBaiCha;
