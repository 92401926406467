// import { HashLink as Link } from 'react-router-hash-link';
import LongJing from './tea/green/LongJing';
import AnJiBaiCha from './tea/green/AnJiBaiCha';
import BiLuoChun from './tea/green/BiLuoChun';
import LiuAnGuaPian from './tea/green/LiuAnGuaPian';
import BaiHaoYinZhen from './tea/white/BaiHaoYinZhen';
import Shoumei from './tea/white/Shoumei';
import BaiMuDan from './tea/white/BaiMuDan';
import JunShanYinZhen from './tea/yellow/JunShanYinZhen';
import HeshanHuangYa from './tea/yellow/HeshanHuangYa';
import MengdingHuangya from './tea/yellow/MengdingHuangya';
import TieGuanYin from './tea/oolong/TieGuanYin';
import DaHongPao from './tea/oolong/DaHongPao';
import YeShengHongCha from './tea/red/YeShengHongCha';
import JinJunMei from './tea/red/JinJunMei';
import QimenHongCha from './tea/red/QimenHongCha';
import Jingmai from './tea/puer/Jingmai';
import LiuBao from './tea/black/LiuBao';

const data = [
  {
    id: 'green',
    name: 'Зеленый',
    chiName: '绿茶',
    ruName: 'люй ча',
    mountain: 250,
    preview: '/img/preview/green.png',
    teaList: [LongJing, AnJiBaiCha, BiLuoChun, LiuAnGuaPian],
    making: ['杀青', '蒸汽', '揉捻', '干燥'],
  },
  {
    id: 'white',
    name: 'Белый',
    chiName: '白茶',
    ruName: 'бай ча',
    mountain: 300,
    preview: '/img/preview/white.png',
    teaList: [BaiHaoYinZhen, Shoumei, BaiMuDan],
    making: ['萎凋', '干燥'],
  },
  {
    id: 'yellow',
    name: 'Желтый',
    chiName: '黄茶',
    ruName: 'хуан ча',
    mountain: 350,
    preview: '/img/preview/yellow.png',
    teaList: [JunShanYinZhen, HeshanHuangYa, MengdingHuangya],
    making: ['杀青', '炒青', '揉捻', '干燥'],
  },
  {
    id: 'oolong',
    name: 'Улун',
    chiName: '乌龙茶', // 烏龍茶 traditional
    ruName: 'улун ча',
    preview: '/img/preview/oolong.png',
    mountain: 400,
    teaList: [TieGuanYin, DaHongPao],
    making: ['萎凋', '揉捻', '杀青', '揉捻'],
  },
  {
    id: 'red',
    name: 'Красный',
    chiName: '红茶',
    ruName: 'хун ча',
    mountain: 240,
    preview: '/img/preview/red.png',
    teaList: [YeShengHongCha, JinJunMei, QimenHongCha],
  },
  {
    id: 'puer',
    name: 'Пуэр',
    chiName: '普洱茶',
    ruName: 'пуэр ча',
    mountain: 100,
    preview: '/img/preview/puer.png',
    teaList: [Jingmai],
  },
  {
    id: 'black',
    name: 'Черный',
    chiName: '黑茶',
    ruName: 'хэй ча',
    mountain: 50,
    preview: '/img/preview/puer.png',
    teaList: [LiuBao],
  },
];

export default data;
