import React from 'react';

const LongJing = {
  id: 'LongJing',
  name: 'Сиху Лунцзин',
  image: '/img/tea/LongJing.png',
  chiName: '龙井茶',
  enName: 'Long Jing',
  ruName: 'Колодец дракона',
  location: 'Район Ханчжоу близь озера Сиху, провинция Чжэцзян',
  legend: (
    <React.Fragment>
      <p>
        {/* <Link smooth to="/location/fujian#test">
        test
      </Link> */}
        Некогда, завершив инспекционную поездку, император Цяньлун, знакомясь с воспетыми еще в древности пейзажами
        озера Сиху, остановился у подножия годы Шифэншань деревни Лунцзин городского округа Ханчжоу и увидел перед
        храмом Хугунмяо 18 чайных деревьев, с которых прекрасные молодые девушки собирали вновь народившиеся почки.
        Император Цяньлун подошел к ним и принял участие в сборе чайного листа. Однако сбор был прерван дворцовым
        гонцом, принесшим известие о болезни матери-императрицы. Обеспокоенный император поспешно сунул собранные чайные
        листья в рукав своего халата и отправился в Пекин. На самом деле, болезнь матери императора оказалась не такой
        уж серьезной. У нее случилось всего лишь несварение желудка. При виде сына мать обрадовалась, а к тому же
        почувствовала внезапно дивный, незнакомый дотоле аромат какого-то растения. «Что ты привез из Ханчжоу? Какой
        аромат!» Император удивился и ответил: «Я уехал из Ханчжоу столь поспешно, что ничего не захватил с собой.
        Откуда быть какому-то аромату?» Оказалось, что это всего лишь пригоршня чайных листьев, собранных им перед
        храмом Хугунмяо в деревне Лунцзин. Листья за время пути из Ханчжоу до столицы успели высохнуть, однако источали
        стойкий благоуханный аромат. Императрица, вдыхая нездешний аромат, почувствовала приятное избавление от тошноты,
        постоянно сопутствовавшей ее болезни, и приказала заварить листья, привезенные сыном. Всего несколько глотков
        устранили рвотные позывы, а после нескольких дней употребления настоя зеленого чая императрица поправилась.
        Обрадованный Цяньлун пожаловал 18 чайным деревьям статус «императорского податного чая» и издал указ о том, что
        свежесобранный чайный лист каждого весеннего сбора отныне является собственностью его матери. С той поры чай
        Лунцзин получил огромную известность.
      </p>
    </React.Fragment>
  ),
  howto: {
    text:
      'Заваривать кипятком, остуженным до 70°С, в фарфоровой или селадоновой гайвани, стеклянной посуде. Пропорция заварки к воде: 4-5 гр. на 100 мл. Пить проливом с постепенным увеличением экспозиции. Выдерживает 5-6 заварок.',
    temp: '70',
    ratio: '4-5',
    time: '5',
  },
  taste: {
    berry: 1,
    flower: 2,
    nuts: 3,
    grass: 4,
  },
  headerMargin: 8,
};

export default LongJing;
