import React from 'react';

const yunnan = {
  id: 'yunnan',
  image: '/img/locations/yunnan.jpg',
  name: 'Юньнань',
  chiName: '云南',
  ruName: '',
  say: 'Yúnnán',
  teas: [
    { name: 'Дянь Хун Мао Фэн' },
    { name: 'Пуэр' },
    { name: 'Юнь Нань Мао Фэн' },
    { name: 'Я Бао' },
    { name: 'Ми Сян Нюй Мао Фэн' },
    { name: 'Ми Сян Нюй Эр Цин' },
    { name: 'Люй Лун Чжу' },
    { name: 'Люй Юй Хуань' },
  ],
  content: (
    <React.Fragment>
      <p>
        Начиная с середины III века до н. э. Дянь и прочие тайские княжества на территории Юньнани подвергаются
        нападениям со стороны китайских государств — сначала княжества Чу, а после объединения китайских земель в единое
        государство — империй Цинь и Хань. К 200 году до н. э. почти вся территория теперешней Юньнани входит в состав
        Ханьской империи. В 122 году до н. э. тайцам ненадолго удалось добиться независимости под эгидой королевства
        Альяо, но уже через 12 лет император У-ди вновь завоевал эти стратегически важные для торговли с Бирмой и Индией
        территории. При нем одно из княжеств, расположенных на этой территории, получило имя Юньнань.
      </p>
      <p>
        В 320 году н. э. в Юньнань, спасаясь от набегов центральноазиатских народов на северокитайские земли,
        переселился клан Цуань, который правил провинцией почти 400 лет.
      </p>
      <p>
        В 650 году на территории провинции возникает царство Наньчжао (столица — Дали), независимость которого признаёт
        империя Тан. Царство находится в дружеских отношениях с Тан, становится её союзником в войнах с Тибетом, а в 735
        году царь Пилогэ присоединяет Наньчжао к империи Тан. Однако уже его сын Гэлофэн объявляет через 17 лет
        независимость Наньчжао. С этого момента начинается череда войн Наньчжао с Тан, ведомых с переменным успехом. С
        877 года оба государства устанавливают мир, в 902 году Наньчжао уходит с исторической арены и в 937 году большую
        часть Юньнани занимает её преемник — царство Дали, стабильно управляющее регионом до тех пор, пока в 1254 году
        его не захватывают монголы во главе с ханом Хубилаем.
      </p>
      <p>
        Первым наместником монгольского императора в Юньнани стал выходец из Бухары мусульманин Сеид Аджаль Шамсуддин.
        Тайские племена эмигрируют на территорию современного Таиланда и Индии.
      </p>
      <p>
        Крыши старого города Лицзян. После этого события история Юньнани сливается с историей Китая. Долгое время
        провинция всплывает в анналах истории только в связи с войной цинской империи против последнего минского
        императора Чжу Юлана, восстаниями У Саньгуя (1674 год), мяо (1735 год) или мусульман-дунган в 1860-х годах. Так
        в 1863 году, когда вождь пантайских повстанцев Ду Вэньсю объявил себя «султаном Сулейманом», правителем
        государства Пиннань Го («Страна умиротворённого Юга») со столицей в Дали. В 1873 году султан был разбит и казнён
        войсками цинской империи.
      </p>
      <p>
        Решающую роль в развитии провинции сыграло строительство французами в 1896—1910 годах железнодорожной ветки
        Ханой — Куньмин.
      </p>
      <p>
        После синьхайской революции 1911 года, в 1916 году провинция Юньнань стала первой из тех, которые воспротивились
        диктаторскому правлению Юаня Шикая. Позже она одной из первых возвращается в сферу влияния Гоминьдана. В 1927
        году произошёл переворот в результате которого генералами был свергнут губернатор Тан Цзияо и был поставлен Лун
        Юнь. Во время китайско-японской войны из Бирмы в Юньнань была построена знаменитая Бирманская дорога —
        единственная связь Китая с Союзниками, по которой поставлялось вооружение и гуманитарная помощь. Куньмин между
        тем на короткое время стал интеллектуальным и культурным центром Китая — туда, спасаясь от японских агрессоров,
        бежала основная часть китайской интеллигенции.
      </p>
      <p>С 1950 года Юньнань входит в состав Китайской Народной Республики.</p>
      <hr />
      <p>
        Юньнань, 云南, или «Страна южнее облаков», четвертая по величине провинция Китая, располагается на его
        Юго-западных рубежах в пространстве 97°—106° в.д. и 21°9′—29°15′ с.ш. Шесть горных систем создают многообразный
        микроклимат на разных высотах: от дождевых тропических лесов Юга до морозных пиков Тибета. Из истоков в
        Тибетском нагорье бурными потоками спускаются по расщелинам гор три великие реки: Цзиньша-цзян, 金沙江, или Река
        Золотого Песка, (Янцзы), Ланьцан-цзян, Вздымающая Волны Река, 瀾滄江(Меконг), и Ну-цзян, Сердитая Река,
        怒,(Наг-чу), разбегаясь каждая в свою сторону.
      </p>
      <p>
        Климат Юньнани сильно отличается от остальных провинций Китая. С конца октября по май сюда приходят воздушные
        потоки из пустынных районов Запада - Ирана, Пакистана и Индии. Наступает сухой сезон: небо проясняется, много
        солнца и мало дождя. Но высокогорные перепады температур рождают на смене дня и ночи туман, который, как живой,
        движется в расселинах гор и над речными долинами, а в пасмурные и дождливые дни заполняет все пространство – до
        самого неба. С июня по октябрь тропические муссоны с Юго-запада и Юго-востока приносят теплое дыхание океана:
        становится влажно и тепло, как в оранжерее. Это сезон дождей. Из-за отсутствия выраженных сезонных скачков
        температуры Юньнань называют «Землей вечной весны».
      </p>
      <p>
        НЕМНОГО ИСТОРИИ В 200 г. до н. э. древнее государство Дянь, расположенное на территории современной Юньнани,
        вошло в состав Ханьской империи. Затем в 650 году на территории провинции возникает царство Нань-чжао со
        столицей Дали, независимость которого признаёт династия Тан. Царство то находится в дружеских отношениях с
        Китаем, то ведет с ним войну, пока в 902 году не уходит с исторической арены, и с 937 г. управляет регионом его
        преемник, царство Дали. В 1254 г. монголы во главе с ханом Хублаем захватывают в власть в Срединном Государстве
        и первым наместником императора в Юньнани становится мусульманин Сеид Аджаль Шамсуддин, после чего история
        Юньнани сливается с историей Китая. В династию Цин здесь произошло несколько сепаратистских восстаний, но все
        они были в конце концов подавлены. Важную роль в развитии провинции сыграло строительство французами в 1896—1910
        гг. железнодорожной ветки Ханой — Куньмин. Во время китайско-японской войны из Бирмы в Юньнань была построена
        знаменитая Бирманская дорога, а Куньмин на короткое время стал интеллектуальным и культурным центром Китая —
        туда, спасаясь от японских агрессоров, бежала основная часть китайской интеллигенции. С 1950 года Юньнань входит
        в состав Китайской Народной Республики.’
      </p>
      <p>
        На заре времен юньнаньский чай не выходил за пределы районов естественного произрастания. Но с VII в чайные
        лепешки начали отправляться в Тибет и далее по знаменитому Ча Ма Гу Дао, Чайно-лошадиному Пути в качестве
        предмета меновой торговли. Согласно записи 696 г. на дайском языке, высеченной на камне в буддийском монастыре в
        горах Цзин Май, связь между Тибетом и Си Шуан Бань На поддерживается уже более чем 1300 лет.’
      </p>
      <p>
        В династию Тан юньнаньский чай уже был широко известен за пределами своей облачной родины под именем Инь шен
        ча银生茶 «Серебристо-зеленый чай». В эпоху монгольской династии Юань он изменил название на Пу ча普茶, а в
        династию Мин, в годы правления под девизом Вань Ли («бесчисленные годы» 1573-1619 гг.) окончательно
        отождествился с городком Пу Эр 普洱, местом сосредоточения чайной торговли. Крестьяне близлежащих горных районов
        свозили сюда высушенный на солнце чай-сырец шай цин мао ча 晒青毛茶, где его скупали представители заводов и
        мануфактур, чтобы уже у себя на производстве прессовать в форму блинов и кирпичей и продавать оптовым торговцам.
      </p>
      <p>
        Открытие морской торговли в Гуанчжоу廣州 (европейцы называли его Кантон) привело к столь резкому повышению
        спроса на чай, что его производство стало привлекательным по экономическим причинам. Начался «золотой век»
        чайного дела в районах Пу Эр и Си Шуан Бань На: «На 800 ли вокруг число людей, ходящих в горы и делающих чай,
        насчитывало сотню тысяч». Помимо регионов естественного произрастания в XVI веке рукотворные чайные сады
        появились в Линь Цан, Да Ли, Кунь Мин и других округах благодаря приехавшим и осевшим здесь переселенцам из
        соседних Сычуани и Шибина. Сегодня чай из листьев высаженных тогда цай пэй гу цяо му 栽培古乔木 , культивируемых
        чайных деревьев, идет на приготовление высококачественного шэн-пуэра.
      </p>
      <p>
        До 30-х годов ХХ века производство чая повсеместно было кустарным, но с появлением трех первых больших чайных
        заводов в районах Мэн Хай, Ся Гуань и Кунь Мин началось массовое производство чая Пуэр - с этикетками, номерами
        партий и т.д. В 70-х на волне экономического подъема в стране, качества жизни и, как следствие, спроса на
        хороший чай в производстве начались большие изменения. Пуэр стал модным, вокруг него как снежный ком выросла
        целая инфраструктура.
      </p>

      <p>
        Растущая в геометрической прогрессии популярность, "пуэрный бум нулевых" образовал дефицит местного сырья,
        которое завозили не только из близлежащих регионов, но даже из соседних стран – Вьетнама и Лаоса. Появились
        многочисленные заводы и заводики, где наладили его выпуск, все новые и новые участки юньнаньских гор
        обустраивали террасами под чайные плантации. В 2008 году был утвержден стандарт GB/T22111-2008, "Product of
        geographical indication Puer tea" или "Продукт местности Пуэр", который закреплял за "настоящим чаем Пуэр" его
        основные характеристики.
      </p>

      <p>
        Согласно этому стандарту, 普洱茶,’
        <em>
          Пуэр – это чай обладающий особыми характеристиками, произведенный по специально установленной технологии в
          местах контролируемого происхождения из вяленого на солнце зеленого чая Юньнань Да-е, Юньнаньский
          Крупнолистный,’
        </em>
        <em>云南大叶种茶</em>
        <em>
          ’ , из районов контролируемого происхождения. В зависимости от особенностей технологии производства чай пуэр
          делится на два вида — пуэр шэн-ча
        </em>
        <em>生茶 </em>
        <em>и пуэр шу-ча </em>
        <em>熟茶</em>
        <em>.</em>
      </p>

      <p>
        <em>Юнь Нань Да Е Чжун Ча </em>云南大叶种茶 юньнаньский крупнолистный чай – это общее наименование для
        распространенных в провинции Юньнань различных сортов древовидных и полудревовидных чайных растений. Что
        касается "особенностей технологии", это <em>хоу фа-сяо </em>后发酵 или <em>пост-ферментация,</em> серия
        происходящих в определенных условиях под воздействием микроорганизмов, ферментов, влажности и высокой
        температуры, а также окисления преобразований веществ, содержащихся в вяленом на солнце зеленом чае’
        <em>юньнань да-е</em> или в <em>пуэре шэн-ча</em>, а также приобретения последними особых свойств чая’
        <em>пуэр шу-ча</em>.
      </p>

      <p>В шестом параграфе стандарта прописаны основные этапы технологии.</p>

      <p>
        <em>6.5.1 </em>
        <em>晒青茶</em>
        <em>
          ’ Шай-цин ча: подвяливание листа — ша-цин (фиксация) — скрутка — разделение слипшихся листов — сушка на солнце
          — упаковка.
        </em>
      </p>

      <p>
        <em>6.5.2 </em>
        <em>普洱茶</em> <em>（生茶）</em>
        <em>Пуэр-ча (шэн-ча): переборка шай-цин — пропаривание и прессовка — сушка — упаковка.</em>
      </p>

      <p>
        <em>6.5.3 </em>
        <em>普洱茶</em> <em>（熟茶）</em> <em>散茶</em>
        <em> Пуэр ча (шу-ча) листовой: пост-ферментация шай-цин — сушка — переборка.</em>
      </p>

      <p>
        <em>6.5.4 </em>
        <em>普洱茶</em> <em>（熟茶）</em>
        <em>紧压</em>
        <em>茶</em>
        <em>
          ’ Пуэр шу-ча, прессованный: Пуэр шу-ча листовой — пропаривание и прессовка — сушка — упаковка или переборка
          шай-цин — пропаривание и прессовка — сушка — постферментация — пуэр шу-ча прессованный — упаковка.
        </em>
      </p>

      <p>Таким образом, согласно стандарту, пост-ферментированные шэн-пуэры и шу-пуэры объединены в одну категорию.</p>

      <p>
        <em>
          Районами, подходящими для культивации чаев Юньнань Да-е и производства чая пуэр являются районы, расположенные
          между 21°10’ и 26°22’ градусами северной широты и 97°31’
        </em>
        <em>～</em>
        <em>
          105°38’ градусами восточной долготы. Места производства чая пуэр находятся в тропических широтах, на больших
          высотах, чайные плантации в основном расположены в горных районах с высотой от 1000 до 2100 метров над уровнем
          моря, с уклоном равным или превышающим 25
        </em>
        °. Это следующие уезды и округа: Куньмин (昆明), Илян (宜良), Дали (大理), Сягуань (下关), Наньцзянь (南涧),
        Линьцан (临沧), Фэнцин (凤庆), Юнь (云), Шуанцзян (双江), Чжэнькан (镇康), Гэнма (耿马), Цанъюань (沧源),
        Баошань (保山), Луси (潞西), Лянхэ (梁河), Юаньцзян (元江), Люйчунь (绿春), Цзиньпин (金平), Пуэр (普洱, экс –
        Сымао, 思茅), Цзингу (景谷), Цзиндун (景东), Чжэньюань (镇沅), Нинъэр (宁洱, экс – уезд Пуэр), Цзянчэн (江城),
        Мэнлянь (勐连), Мэнхай (勐海), Цзинхун (景洪), Мэнла (勐腊).
      </p>

      <p>
        По сортам пуэры разделяются следующим образом.’
        <em>
          Высший сорт: флеши с 1 почкой 1 листом составляют более 70% , флеши с 1 почкой 2 листами составляют менее 30%.
          Первый сорт: флеши с 1 почкой 2 листами составляют более 70% , другой лист сходной степени нежности составляет
          менее 30%. Второй сорт: флеши с 1 почкой 2, 3 листами составляют более 60% , другой лист сходной степени
          нежности составляет менее 40%. Третий сорт: флеши с 1 почкой 2, 3 листами составляют более 50% , другой лист
          сходной степени нежности составляет менее 50%. Четвертый сорт: флеши с 1 почкой 3, 4 листами составляют более
          70% , другой лист сходной степени нежности составляет менее 30%. Пятый сорт: флеши с 1 почкой 3, 4 листами
          составляют более 50% , другой лист сходной степени нежности составляет менее 50%.
        </em>
      </p>

      <p>
        При этом сырье со старых чайных деревьев не имеет сортности, его стоимость определяют вкус и статус региона.
      </p>
      {/* Map "Юньнань чай пуэр регионы */}

      <p>
        В Дянь, 滇 (сокращенное наименование Юньнани) условно выделяют пять чайных областей: Запад, 滇西 Дянь-си, Юг,
        滇南Дянь-нань, Центр, 中滇Чжун-дянь, Северо-восток, 滇东北 Дянь-дунбэй, и Cеверо-запад, 滇西北 Дянь-сибэй.
        Живыми памятниками чайной истории являются южные и юго-западные округа Линь Цан, Сы Мао и Си Шуан Бань На, где
        чайные деревья растут в естественных условиях тропических лесов. В центральных и северо-восточных районах
        провинции чайные плантации промышленных масштабов появились сравнительно недавно. Чай, собранный в каждом из
        этих районов, имеет свой оригинальный вкус и аромат.
      </p>

      <p>ДЯНЬ НАНЬ 滇南</p>

      <p>
        К чайным районам Юга, или Дянь-нань, относят области Пу Эр, Си Шуан Бань На <strong>西双版</strong>
        <strong>纳</strong>, Вэнь Шань 文山 и Хун Хэ 红河. С Севера на Юг здесь проходят, разделенные реками, три
        большие горные гряды. На Севере их высота достигает 3300 м, к югу уменьшается до 2000 м. С Востока Дянь-нань
        ограничен горным хребтом Ай Лао Шань, 哀牢山. На его западных склонах находятся чайные районы Лао Цан Фу Дэ, Ай
        Лао Шань и Ми Ди. Западнее - хребет У Лян Шань, 无量山, с чайными районами около города Цзин Дун на Севере, и
        знаменитыми чайными местами между городами Цзин Гу и Чжэнь Юань. У Лян Шань переходит в горы района Пу Эр и
        уходит в восточную часть Си Шуан Бань На с её знаменитыми Чайными Горами. Река Ланьцанцзянь (Меконг) делит
        Дянь-нань на Восточную и Западную части.
      </p>

      <p>
        <strong>Си Шуан Бань На, </strong>
        <strong>西双版</strong>
        <strong>纳</strong>
      </p>

      <p>
        В связи с географией чайных районов Юньнани часто упоминаются Лю Да Ча Шань 六大茶山 Шесть Больших Чайных Гор. В
        действительности это не отдельно стоящие горы, а единый массив, состоящий из соединяющихся друг с другом чайных
        районов. Шесть лежат к востоку от реки Ланьцанцзян: это И Бан, Ю Лэ, Ман Чжи, Мань Чжуань, Гэ Дэн и И У или
        "внутренний регион". А "внешний регион" располагается к западу от Ланьцанцзян, в уезде Мэнхай: это горы Нань Но,
        Цзин Май, Бу Лан, Бань Чжан, Ба Да и Мэн Сун.
      </p>

      <p>
        Первоначально производство чая не выходило за пределы района его естественного произрастания, Шести Больших
        Чайных Гор, Лю Да Ча Шань, 六大茶山: Ю Лэ (攸乐), Гэ Дэн (革登), И Бан (倚邦), Ман Чжи (莽枝), Мань Чжуань
        (蛮专) и Мань Са (慢撒). По легенде, великий мудрец и военачальник Чжу Гэ Лян (181 - 234 гг) "сделал так, чтобы
        эти горы благодаря ему вошли в историю. Он оставил на горе Ю Лэ медный гонг, потерял медный котёл на горе Ман
        Чжи, закопал чугунную болванку на горе Мань Чжуань, зарыл лошадиное седло на горе Гэ Дэн, подарил деревянную
        колотушку горе И Бан и на горе Мань Са потерял мешочек для разбрасывания семян. По названию предметов горы и
        получили свои имена." Был ли Чжу Гэ Лян вообще на этих горах - неизвестно, но чай неразрывно вплетен в
        культурный контекст китайской истории вместе с именами великих героев, как живой свидетель и участник
        становления и развития великой нации.
      </p>

      {/*  map Юньнань Лю Да Ча Шань */}

      <p>
        <strong>Си Шуан Бань На, </strong>
        <strong>西双版</strong>
        <strong>纳: Восточная часть</strong>
      </p>

      <p>
        <strong>Горы И У, </strong>
        <strong>易武山</strong>
      </p>

      <p>
        Горы И У расположены на севере уезда Мэн Ла 勐腊县. Район включает как собственно горы И У,’
        <strong>易武</strong> и соседние Ман Са, 慢撒. Самая высокая точка – 2023 м, самое высокое поселение, Сань Хэ Шэ
        三合社 располагается на высоте 1433 м., а самое низкое, На Мэ Тянь 纳么田 – 730 м. Около пещеры Ло Шуй растет
        Королевское 700-летнее чайное дерево высотой 10,33 м, которое достигает в обхвате у основания 1,32 м. Около реки
        Тун Цин растет другое древнее 400-летнее дерево высотой 14,52 м, в обхвате у основания 1,8 м.
      </p>

      <p>
        Традиции коренных жителей неразрывно связаны со сбором и производством чая. Буланы верят, что чай подарил миру
        их предок Пу Жень, другие иусцы каждый год в 23-й день 7-й луны устраивают "Собрание Чайных Патриархов"
        (чацзу-хуэй, 茶祖会), посвященное Кунь Мину. В эпоху династии Цин здесь производилось ежегодно более 200 тонн
        чая, известность региона привлекала переселенцев их соседних областей, далекие потомки которых и сегодня
        занимаются его сбором и производством. Чайные деревья старых садов представляют серьезный коммерческий интерес
        для жителей, поэтому каждое из них закреплено за конкретными семьями деревень И У, Ма Хэй, Ло Шуй Дунь, Гуа Фэн
        Чжай, Лао Дин Цзя Чжай и Ман Сю. Ежегодный объем мао-ча составляет примерно 60-70 тонн.
      </p>

      <p>
        Чай из И У наполнен первозданной энергией диких гор, его изначальные горечь и терпкость со временем
        трансформируются в объемный, выдержанный, сладкий и сочный вкус.
      </p>

      <p>
        <strong>Лао Ман Са</strong>
        <strong>慢撒山</strong>
      </p>

      <p>
        Гора Лао Ман Са (Посевной Мешок) расположена в восточной части района Мэн Ла 勐腊县. Административно входит в
        состав района И У и включает чайные сады деревень Ман Са, Ман Хей, Ман Най и Ман Ла, расположенных на высоте
        820-2000 м. Более тысячи лет чай, производимый местными крестьянами, уходил отсюда по Чайно-лошадиному пути в
        Тибет. Но в эпоху династии Цин, после того, как район Ман Са был объединен с районом И У, его слава несколько
        померкла.
      </p>

      <p>Чай, собранный и приготовленный на склонах Лао Ман Са, отличается крепким, сладковатым вкусом.</p>

      <p>
        <strong>Гора Ю Лэ, </strong>
        <strong>攸</strong>
        <strong>乐</strong>
        <strong>山</strong>
      </p>

      <p>
        Гора Ю Лэ (Медный Гонг) находится на Востоке уезда Цзин Хун. Другое название – Цзинно Шань (гора народности’
        <em>цзин но</em>). Протяженность региона с Севера на Юг составляет 50 км, а с Востока на Запад – 75 км. Сбором
        чая занимаются жители деревень Цзин Хун, Ман Си, Ши Цзуй и Си Тун. Чайные плантации расположены на высоте
        570-1650 м. Здесь много дикорастущих Е Шэн Гу Цяо Му (野生古乔木) и древних посадок Цай Пэй Гу Цяо Му
        (栽培古乔木). В конце правления династии Цин площадь чайных садов доходила до 6,7 км<sup>2</sup>, и был построен
        чайный завод, выпускавший до 100 тонн чая, часть которого поставлялась в столицу. Самый большой чайный сад,
        площадью более 1,2 км<sup>2</sup>, сохранившийся до наших дней, находится на высоте 1200-1500 м.’
      </p>

      <p>
        Чай с гор Ю Лэ хорошо утоляет жажду, имеет сильный терпкий вкус, который быстро переходит в сладкое послевкусие.
        Цвет настоя - светло-оранжевый.
      </p>

      <p>
        <strong>И Бан, </strong>
        <strong>倚邦山</strong>
      </p>

      <p>
        И Бан (Деревянная Трещотка) - огромный горный район, более 360 кв.км. Это самые северные из Лю Да Ча Шань, Шести
        Больших Чайных Гор, которые на юге смыкаются с Мань Чжуань, на западе переходят в Гэ Дэн, с востока достигают
        Иу. Самый высокогорный чай растет у Шань Шэнь Мяо山 神庙 Храма Горных Духов на высоте 1950 м, а самая низкая
        отметка в месте слияния рек Мочжэхэ и Сяохэйцзян – 565 м. Это самые северные горы в "Большой Чайной Шестёрке".
        Здешние жители зовут гору Танла, "Чайный колодец", потому что с начала времен они заняты его сбором и
        производством. В XVII веке к ним присоединились переселенцы из соседней Сычуани, которые привезли с собой
        саженцы мелколистного чая, свои традиции и жизненный уклад. В династию Цин чай с И Бан достиг императорского
        двора, был удостоен высочайшего внимания Сына Неба и был занесен в реестр податных.
      </p>

      <p>
        <strong>Гэ Дэн </strong>
        <strong>革登山</strong>
      </p>

      <p>
        Чайные горы Гэ Дэн 革登, где легендарный Чжу Гэ Лян оставил Лошадиное Седло, относятся к уезду Сян Мин Сян
        象明乡. По площади горы Гэ Дэн самые маленькие: и в годы наивысшего расцвета делали чая здесь не больше 25 тонн.
        Максимальная высота этих гор - 1950 м, чайные сады располагаются выше отметки 565 м, средний возраст садов
        300-500 лет. Большинство деревьев относятся к агротипу сяо цяо му с большими ворсистыми почками, поэтому местные
        жители называют свой чай "большим белым чаем с Гэ Дэн". Среди других народностей преобладают айни (ветвь
        народности хани). Они верят, что первые чайные деревья здесь посажены самим Чжу Гэ Ляном, поэтому построили храм
        в его честь из древесины чайных деревьев. Еще одной достопримечательностью здешний мест является гора Куньмина
        (высота – 1722 метра), которую местные жители почитают воплощением чайного патриарха, и каждый год перед началом
        сбора весеннего чая приходят сюда совершать обряды у алтаря Королевского чайного дерева.
      </p>

      <p>
        В династию Цин здешнее поселение Лао Чжай было процветающим, количество дворов достигало 400. Но начале ХХ века
        из-за войн и беспорядков оно пришло в запустение. На сегодняшний день лучше всего сохранились древние сады сел
        Чжи Банчжай 直蚌寨, Синь Фа 新发или Ака Чжай，阿卡寨, и Ча Фан 茶房. В конце ХХ в. на склонах Гэ Дэн были
        высажены обширные плантации тай ди ча.
      </p>

      <p>
        Вкус у чая с Гэ Дэн немного терпкий, но горечь быстро переходит в сладкое послевкусие. Аромат - слабый и чистый,
        сначала дымный, потом сладкий цветочный. Цвет настоя - тёмно-оранжевый.
      </p>

      <p>
        <strong>Мань Чжуань, </strong>
        <strong>蛮</strong>
        <strong>砖</strong>
        <strong>山</strong>
      </p>

      <p>
        В районе гор Мань Чжуань (Железный Кирпич) чайные деревья растут на высоте 850-1900 м. Это и почтенные деревья,
        имеющие многовековую историю, и сравнительно молодые посадки.
      </p>

      <p>
        Здешний чай по вкусу терпкий, китайцы называют его "чунь нун" 醇浓, то есть крепкий и плотный. У него долгое и
        сладкое послевкусие, он хорошо утоляет жажду. Аромат с нотками сухофруктов. Цвет настоя тёмно-жёлтый.
      </p>

      <p>
        <strong>Мань Чжи </strong>
        <strong>莽枝山</strong>
      </p>

      <p>
        Гора Мань Чжи (Медный Котелок) расположена в районе Мен Ла между горными цепями И Бан, Мань Чжуань и Гэ Дэн.
        Чайные плантации расположены на высоте 850-1950 метров над уровнем моря. Несмотря на долгую историю чайного дела
        в регионе и значительное количество древних садов, в начале прошлого века большинство их них было заброшено, и
        только в 80-х годах ХХ века чайное производство стало вновь развиваться.
      </p>

      <p>
        Чай с горы Мань Чжи отличается относительно высокой терпкостью и горечью, которые быстро переходят в сладкое
        послевкусие, и высоким, свежим ароматом. Стимулирует секрецию жидкости в организме. Настой темно-оранжевый.
      </p>

      <p>
        <strong>Си Шуан Бань На, </strong>
        <strong>西双版</strong>
        <strong>纳: Западная часть, округ Мэнхай</strong>
      </p>

      <p>
        <strong>Бу Лан Шань - </strong>
        <strong>布朗山</strong>
      </p>

      <p>
        Район Бу Лан занимает площадь около 70 га на юге уезда Мэн Хай. Это большая высокогорная территория, которая
        включают районы Хэ Кай, Бань Чжан, небольшой город Бу Лан Шань и Сяо Мэн Сун. Пуэры частенько прессуют из мао-ча
        соседних бирманских деревень. Большие древние чайные сады расположены на севере - от Лао Мань Э до Хэ Кай. Самые
        высокогорные (1900 м над уровнем моря) ведут свою историю с VII века.
      </p>

      <p>
        Название чая из этого региона обычно дается по имени деревни, где он сделан, причем вкус чая из разных деревень
        сильно отличается. Самый известный производят Лао Бань Чжан 老班章, Синь Бань Чжан 新班章 и Лао Мань Э 老曼娥.’
      </p>

      <p>
        Лао Бань Чжан расположена в северной части горы Бу Лан. В 114 ее дворах проживает 460 человек. Высота над
        уровнем моря 1700 м. Чайные деревья растут в непосредственной близости от жилья и за деревней в лесу. У них
        длинные и тяжелые листья, чай имеет явно выраженный горький, терпкий и наполненный вкус. Здешний считается
        лучшим в Бань На и самым дорогим.
      </p>

      <p>
        Синь Бань Чжан, Новый Бань Чжан, находится в 7 километрах от Старого. Высота района 1600 м. Большая часть чайных
        деревьев растёт в окрестностях деревни. Специальная служба строго следит за качеством чая, новые посадки
        запрещены.
      </p>

      <p>
        Деревня Лао Мань Э (143 двора, 700 человек населения) находится в 10 км от Синь Бань Чжан на плоскогорье. Высота
        1200 метров над уровнем моря. Большая часть чайных деревьев, среди которых очень много по-настоящему старых,
        растёт в окрестности деревни.
      </p>

      <p>
        Чай со старых деревьев, производимый в поселках Лао Ман Е, Мен Сун и Ман Син Лун отличает горечь, которая быстро
        переходит в сладкое послевкусие. Чай с новых плантаций на Юге Бу Лан Шань не такой горький, как чай со старых
        деревьев.
      </p>

      <p>
        <strong>Ба Да </strong>巴达<strong>山</strong>
      </p>

      <p>
        Чайный регион Ба Да расположен на границе Сишуанбаньна и Бирмы. Крупнолистные юньнаньские чайные деревья растут
        среди джунглей на высоте 670-2250 м. Основные древние чайные сады сосредоточены вблизи деревни Mан Май и
        занимают почти 135 гектар. С апреля по октябрь гору окутывает густой туман. В 80-е годы ХХ века чайная фабрика
        Мэнхай посадила здесь почти 670 гектар новых плантаций.
      </p>

      <p>
        Чай со старых деревьев горы Ба Да ценится знатоками и любителями шенов за яркую фруктовую ноту букета и плотный
        вкус, горечь которого трансформируется в сладость.
      </p>

      <p>
        <strong>Мэн Сун </strong>
        <strong>勐宋山</strong>
      </p>

      <p>
        Чайные сады Мэн Сун располагаются на высоте 870-2219 метров над уровнем моря. Крестьяне из деревень Да Ан, Нун
        Бэн, На Ка и Бао Тан (народности <em>дай</em> и <em>лаху</em>) выращивают зерно, чай и коноплю. Самой интересной
        является На Ка 那 卡, деревня народности <em>лаху</em>, расположенная на севере, у самого высокого пика
        Сишуанбаньна (1800-2000м) в окружении фантастического лабиринта древнего чайного леса. Воздух здесь чист и
        прохладен, а пейзажи - дивной первозданной красоты. Плохое состояние дорог делает эту деревню изолированной от
        внешнего мира в сезон дождей. Ближайший город в часе езды на мотоцикле. Как и в других деревнях лаху, здесь
        сохранен традиционный уклад, и большинство из 560 ее жителей не говорят по-китайски.
      </p>

      <p>Чай района Мэн Сун отличает сладкий, мягкий вкус, легкая терпкость и уникальный аромат.</p>

      <p>
        <strong>Нань Но </strong>
        <strong>南</strong> <strong>糯山</strong>
      </p>

      <p>
        Гора Нань Но расположена на границе водораздела округов Цзин Хун и Мэн Хай. Это один из древнейших чайных
        районов, известный со времен династии Хань. На языке народности <em>дай</em> гора зовется "Бамбуковый Соус". По
        легенде во времена далекой древности вождь одного из поселений хани устроил большой пир, на котором были люди
        дай. В числе блюд был рис, приправленный бамбуковым соусом, угощение так понравилось дайцам, что они до сих пор
        называют так эту местность. По другой легенде Чжу Гэ Лян во время военного похода лечил здесь солдат чайным
        отваром, поэтому гору долгое время называли Горой Кунь Мина. Буланы ведут здесь свою чайную историю со времен
        династии Южное Чжао (VIII в.), а представители народности айни насчитывают 58 поколений чайных мастеров. Средняя
        высота чайных садов 1400 м над уровнем моря. Большая часть мао-ча, собранного здесь, поставляется на Мэнхайскую
        чайную фабрику, сбором занимаются крестьяне, проживающие в 30 мелких населенных пунктах. Общая площадь чайных
        плантаций занимает здесь 21600 га, из которых 800 га – древние чайные сады. Старейшее Чайное дерево растет в у
        деревни Бань По 半坡. Во владении семей этой деревни находится 280 га чайных плантаций, из которых 230 га
        приходится на долю древних садов.
      </p>

      <p>
        Чай, сделанный в районе Нань Но из разного сырья отличается, но общими характеристиками являются мощная
        энергетическая составляющая, плотный настой, продолжительная сочная терпкость и легкая горечь, которая быстро
        переходит в сладкое послевкусие. Стимулирует секрецию жидкостей в организме.’
      </p>

      <p>
        <strong>Хэ Кай, </strong>
        <strong>贺</strong>
        <strong>开山</strong>
      </p>

      <p>
        Гора Хэ Кай – южная оконечность горной цепи Нань Нуо, которая включает несколько пиков и горных долин.
        Историческое место проживания народностей хани и лаху (около 2600 жителей в 6 небольших поселках). Общая площадь
        чайных садов составляет 9100 га, из которых 7240 га приходится на долю древних чайных деревьев, которые растут в
        естественных условиях тропического леса на высоте 1170-1800 м. Ежегодно здесь производится до 250 тонн
        высококачественного <em>мао ча</em>. Производство сосредоточено в деревнях Манмай и Маннон.
      </p>

      <p>
        Чай с древних деревьев горы Хэ Кай отличается золотистым цветом настоя, ярким ароматом, мятной ноткой во вкусе и
        горчинкой, переходящей в сладкое, долгое послевкусие.
      </p>

      <p>
        <strong>Префектура Пу Эр </strong>普洱
      </p>

      {/* Юньнань карта чайные районы Пу Эр */}

      <p>
        <strong>Цзин Май </strong>
        <strong>景</strong>
        <strong>迈</strong>
        <strong>山</strong>
      </p>

      <p>
        Горы Цзин Май входят в 12 Больших чайных гор Дань-нани. Располагаются на стыке административного округа Пуэр (в
        состав которого входит), Бирмы и Си Шуан Бань На. Общая площадь района составляет 67 кв.км. В 10 небольших
        поселках общей численностью 692 двора проживают чуть менее 3000 человек). Самые большие - Ман Цзин芒景
        народности булан и Цзин Май<strong>景</strong>
        <strong>迈</strong>народности дай.
      </p>

      <p>
        Первое поселение дай возникло здесь, согласно историческим хроникам, в 106 г до н.э. По легенде его основателем
        был принц Чжао, который обнаружил эту прекрасную местность во время охоты за чудесным оленем. А на каменных
        стелах монастыря деревни Ман Цзин 芒景 есть упоминание о производстве чая (датируется 696 г.н.э.). Местных
        жителей связывают давние "чайные узы": у правителя народности дай было семь дочерей, и седьмую, самую
        прекрасную, он выдал за Ба Янь Лэна, чайного патриарха буланов. В "Кумирне Чайного Предка" и "Беседке прекрасной
        принцессы" и сегодня в календарные праздники проходят торжественные обряды.
      </p>

      <p>
        Общая площадь чайных садов составляет 10,78 кв.км, из которых старыми посадками занято 2,8 га. Высота колеблется
        в пределах 1100-1662 м над уровнем моря. Здесь большое количество высоких древних деревьев с пышной кроной,
        которые растут на отдалении друг от друга, что является непременным условием <em>шань е ци юнь </em>
        山野气韵 яркого и сильного аромата. Их ветви покрыты мхом, на стволах находят приют орхидеи и другие
        растения-эпифиты.
      </p>

      <p>
        Настоящий чай из Цзин Май отличается насыщенным, плотным телом (заваривается более 10 раз), ярким, долгим
        ароматом и очень стойким сладким послевкусием. Терпкость и горечь в вкусе незначительны.
      </p>

      <p>
        <strong>Кунь Лу Шань </strong>
        <strong>困鹿山</strong>
      </p>

      <p>
        Горы Кунь Лу Шань общей площадью около 674 гектар входят в южный участок горной цепи У Лян Шань 无量山.
        Территория водораздела бассейнов рек Ланьцан-цзян 澜沧江 и Хун Хэ 红河. Максимальная высота - 2271 м. Средняя
        высота произрастания чайных садов - 1600 м. Это самые высокие горы округа Нин Эр 宁洱 и самый большой регион
        произрастания древних чайных деревьев, средний возраст которых составляет 200-400 лет, но есть и более древние.
        На языке народности дай "Кунь Лу" означает "Птичьи Горы". Сбором сырья занимаются крестьяне деревень Куань Хун
        宽宏, Фэн Ян 凤阳 и Ба Бянь 把边. По рассказам старожилов, во времена династии Цин каждый год весной сюда
        приезжали императорские чиновники для отбора самого лучшего чая. Сегодня сырье отсюда поставляется для обработки
        на чайные фабрики округа Куньмин. Древние чайные сады составляют с человеческим жильем единое целое, растут на
        территории деревень. Насчитывается 372 чайных дерева, возраст которых, согласно экспертизе, более 400 лет.
        Высаженные когда-то людьми старые деревья теперь очень похожи на дикорастущие.
      </p>

      <p>
        Чай из Кунь Лу Шань отличает покрытый ворсом лист. Настой плотный, золотисто-зеленый, аромат спокойный,
        устойчивый. Вкус многогранный, запоминающийся, легкая горечь в нем быстро переходит с сладость, а терпкость
        отсутствует.
      </p>

      <p>
        <strong>Цзин Гу, </strong>
        <strong>景谷</strong>
      </p>

      <p>
        Чайный район Цзин Гу занимает площадь 7777 кв.км в западной части префектуры Пу Эр. Самый высокий горный пик -
        2920 метров над уровнем моря. На языке народности дай, аборигенов здешних мест, гора называется Мэн Во, что
        означает "Соленый Колодец". Согласно археологическим раскопкам, первые поселения племен айлао возникли здесь во
        2 тысячелетии до н.э. и входили в состав древнего государства Ичжоу. В эпоху династии Тан район входил в состав
        государства Иньшен, и в исторических хрониках того времени есть упоминания о "превосходном чае из Иньшена".
        Сбором сырья традиционно занимаются крестьяне народностей <em>и</em> и <em>дай</em>. Чайные деревья растут в
        естественных условиях, их не подрезают и не опрыскивают химикатами. Большинство из них высажены в ХIХ веке во
        время расцвета чайного дела в округе Пуэр, но есть и более древние растения.
      </p>

      <p>
        Чайные почки деревьев в горах Цзин Гу большие и ворсистые, дают при заваривании плотный, ароматный настой,
        наполненный "первозданной энергией гор". Вкус кисло-сладкий, с приятным освежающим послевкусием.
      </p>

      <p>
        <strong>Чжэнь Юань </strong>
        <strong>镇</strong>
        <strong>沅</strong>
      </p>

      <p>
        Чайный регион Чжэнь Юань входит в состав префектуры Пу Эр, Чжэньюань-И-Хани-Лахуского автономного округа. Общая
        площадь составляет 4137 кв.км, из которых около 19 кв.км занято древними чайными садами в зоне заповедника
        Айлаошань哀牢山 на высоте 2100-2500 м. Максимальная высота - 3137 м. Здесь в диком тропическом лесу растет
        25-меровое чайное дерево, которому более 2700 лет. Диаметр ствола у корней 1,2 м, сам ствол - 0,89 м., размеры
        кроны 22х20 м. Обнаружено оно было в 1996 г. и с тех пор является объектом исследования ученых, туристической
        достопримечательностью и национальным достоянием.
      </p>

      <p>
        <strong>Цзян Чэн</strong>
        <strong>江城</strong>
      </p>

      <p>
        Цзян Чэн Хани-Ийский автономный округ расположен в 130 километрах от города Пу Эр на пересечении префектур Пу
        Эр, Хун Хэ, Сышуаньбанна, границы Лаоса и Вьетнама. Основные чайные плантации Цзянь Чэн сосредоточены вблизи
        поселков Кань Пин и Цзин Дун. Ежегодно регион поставляет на чайные фабрики Юньнани около 1000 тонн чайного
        сырья.
      </p>

      <p>
        <strong>景</strong>
        <strong>东</strong>
        <strong> Цзин Дун</strong>
      </p>

      <p>
        Чайный регион Цзин Дун – самый северный в префектуре Пу Эр, занимает площадь 4466 кв.км и является частью горной
        цепи У Лян Шань无量山. Принадлежит к районам с давней чайной историей, входившим в эпоху династии Тан в состав
        государства Иньшен. Начиная с VII века экспортировал чай в Тибет.
      </p>

      <p>
        <strong>ЧАЙНЫЕ РАЙОНЫ ЗАПАДА, ДЯНЬ-СИ</strong>
      </p>

      <p>
        Чайные районы Запада, Дянь-си, включают округа Линь Цан 临沧市, Бао Шань <strong>保山</strong>, Дэ Хун и Да Ли’
        <strong>大理</strong>.
      </p>

      {/* map округ ЛиньЦан */}

      <p>
        <strong>Мэн Ку </strong>勐库
      </p>

      <p>
        Чайный регион Мэн Ку勐库 (уезд Шуан Цзян双江) располагается в Да Сюэ Шань 大雪山, Больших Снежных горах –
        огромной горной цепи протяженностью с Севера на Юг около 400 км, отхватывающей территории Тибета и Сычуани.
        Высочайший пик Снежных Гор Гун Га Шань 贡嘎 достигает отметки 7556 м над уровнем моря. В Мэн Ку чайные деревья
        произрастают на высоте 2200-2750 м, максимальной для вида камелиевых. Лучшее сырье берут с диких чайных
        деревьев, растущих в тропическом лесу среди других видов флоры. Среди них есть одно, достигающее в обхвате у
        корня четырех метров. Высота этого дерева – 20 м, а размеры зеленой кроны – 15,8 х 15,3 м. Сбором сырья
        занимаются крестьяне народностей лаху, ва, булан и дай – в общей сложности около 30 000 человек из 103 деревень.
        Мао ча отправляется на фабрику Шуан Цзян Мэн Ку, которой принадлежит 166 500 кв.м заводских площадей, а объем
        ежегодного производства составляет 10 000 тонн. Древние сады, разбитые здесь в эпоху династии Мин, в которых
        сохранилось около 1000 деревьев диаметром 0,3-0,6 м, сосредоточены в районе деревень Бин Дао 冰岛 Ледяной
        Остров, и Гун Нун 公弄.
      </p>

      <p>
        Чай региона Мэн Ку сочный, сладкий, с лёгкой терпкостью и долгим послевкусием, обладает высоким потенциалом для
        выдержки.
      </p>

      <p>
        <strong>Фэн Цин </strong>凤庆
      </p>

      <p>
        Самые большие плантации чайных деревьев в уезде Фэн Цин находится в регионе Фэн Шань, горы Феникса. Чайные
        традиции здесь имеют очень давнюю историю. Сбором сырья заняты крестьяне из 13 поселков, чайные сады находятся
        как в непосредственной близости от человеческого жилья, так и на удалении, в джунглях. Ежегодно с плантации
        собирается более 10000 тонн чайных листьев. Помимо пуэров в уезде с 1939 г производят знаменитый Дянь Хун.
        Отсюда технология его производства распространилась более чем в 20 уездов областей Линьцан, Баошань, Пуэр,
        Сишуанбаньна, Дэхун и Хунхэ.
      </p>

      <p>
        <strong>Бао Шань </strong>
        <strong>保山</strong>
      </p>

      <p>
        Регион Бао Шань <strong>保山市</strong>граничит на востоке с округом Фэн Цин, на юге с округом Юн Дэ. Сбором
        сырья и производством мао ча занимаются крестьяне народностей йи, мяо, булан, хуэй, бай и лаху. Древние чайные
        сады занимают 1,6 кв.км на средней высоте 1840 м., среди них насчитывается 400 деревьев возрастом около 500 лет.
      </p>

      <p>
        <strong>Бан Вэй </strong>
        <strong>邦崴</strong>
      </p>

      <p>
        В районе Бан Вэй 邦崴村(относится к адм.округу Ланцан澜沧县) древние чайные деревья растут среди тропического
        леса на высоте 1900 м (площадь более 30 кв.км), деревне принадлежит старый чайный сад эпохи династии Мин
        (площадь 1,1 кв.км). Самое старое дерево высотой 11,8 м имеет диаметр ствола у основания более 1 м, размер кроны
        – 8,2х9 м. Изготовлением чая в Бан Вэй занимаются более 1000 лет представители местной народности лаху и хани. В
        апреле 1993 г. представительная комиссия из 181 экспертов побывала здесь в рамках Международного симпозиума.
        Ученые пришли к заключению, что местные чайные деревья являются носителями древнейшего генотипа. В 2013 г. чай
        из Бан Вэй был награжден титулом Ши Цзе Ча Юань 世界茶源 "Мировой Источник Чая".
      </p>

      <p>
        Чай из Бан Вэй дает желтовато-зеленый настой, сочный, слегка горьковатый, с продолжительной мягкой терпкостью,
        очень устойчивый в заваривании.
      </p>

      <p>
        <strong>大理</strong>
        <strong> Да Ли</strong>
      </p>

      <p>
        Регион является родиной Camellia taliensis, камелии Далийской, которая произрастает в смешанных вечнозеленых
        лесах на высоте 1300-2700 м. История чайного дела здесь насчитывает сотни лет, в исторических очерках 滇略 "Дянь
        Люэ" династии Мин упоминается "чай пу", который употребляют местные крестьяне. Район Сягуань下关镇 или "Нижняя
        Застава" уже тогда был одной из отправных точек на древнем Чайно-лошадином пути из Юньнани в Тибет. Сегодня
        Сягуань называют Юньнаньской Швейцарией за потрясающей красоты панораму горной долины c уникальным
        микроклиматом: муссоны с Индийского океана, поднимаясь по руслу реки Си Эр Цзян, встречаются здесь с холодными
        воздушными потоками, спускающимися с горы Цан Шань. Крупнейшим производителем чая является сягуаньская чайная
        фабрика, ежегодно выпускающей 6 000 тонн чайной продукции 60 наименований.’
      </p>
    </React.Fragment>
  ),
};

export default yunnan;
