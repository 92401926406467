import chahu from './stuff/chahu';
import chabei from './stuff/chabei';
import gaiwan from './stuff/gaiwan';
import chadui from './stuff/chadui';
import chahe from './stuff/chahe';
import chahai from './stuff/chahai';
import chaban from './stuff/chaban';
import chayeguan from './stuff/chayeguan';
import chaju from './stuff/chaju';

const stuff = [chahu, chabei, gaiwan, chadui, chahe, chahai, chaban, chayeguan, chaju];

export default stuff;
