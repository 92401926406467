import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { listVariants, itemVariants } from '../data/framerVars';

import stuff from '../data/stuff.js';

import s from './Stuff.module.css';
import sh from './share.module.css';
import FamilyCard from '../components/FamilyCard';

const Stuff = () => {
  return (
    <motion.section
      className={classNames([sh.PageWrapper, s.StuffGrid])}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={listVariants}
    >
      {stuff.map((item) => {
        return <FamilyCard key={item.id} to={`/stuff/${item.id}`} variants={itemVariants} elem={item} ruName />;
      })}
    </motion.section>
  );
};

export default Stuff;
