import React from 'react';

const BaiMuDan = {
  id: 'BaiMuDan',
  name: 'Бай Му Дань',
  image: '/img/tea/BaiMuDan.png',
  chiName: '白牡丹',
  enName: 'Bai Mu Dan',
  ruName: 'Белый пион',
  location: 'горы Уишань провинции Фуцзянь',
  say: 'Bái mǔdān',
  legend: (
    <React.Fragment>
      <p>
        Легенда № 1. Во времена императора в местах, которые сейчас лежат вблизи горы Таймушань в уезде Фудин жила
        добрая женщина, которая занималась сбором растений и изготовлением лекарств. Однажды жителей окрестных деревень
        поразила невиданная доселе болезнь, лекарства от которой она найти не смогла. Лекарь сильно переживала по этому
        поводу, и в результате провидения, а именно явившегося к ней духа горы, она пошла искать загадочный чайный куст.
        В результате напиток, полученный из засушенных чайных листьев с того куста, излечил всех крестьян, которые
        назвали в честь нее гору – Таймушань (???), где Тайму – «кормилица, матушка, добрая бабушка».{' '}
      </p>
      <p>
        Легенда № 2. Разочаровашийся в службе государству молодой хипстер-чиновник отправился для уединенного проживания
        в горы со своей матушкой. В поисках места для новой жизни, он наткнулся на красивейшее место на севере Фуцзяня,
        где воздух был пропитан ароматом белого пиона. Аромат исходил из кустов белого пиона, росших неподалеку от
        источника. Там они и поселились. Однажды зимой матушка заболела, и молодой человек никак не могу найти средства
        для ее лечения, он очень сильно переживал, и ему пришло видение, где дух ближайшей горы указал на то, что
        матушку можно излечить чаем. Но где же найти чай зимой? Бродив по окрестностях своего нового дома, он с
        удивлением обнаружил, что кусты пиона, которые источали столь тонкий аромат летом, превратились в чайные кусты,
        которые расцвели зимой. Собрав урожай, он высушил листья и вылечил маму. Так и появился чай Бай Мудань – Белый
        пион!
      </p>
      <p>
        Считается, что белый чай начали производить во время династии Сун (X-XIII вв.), упоминается в трактатах он уже в
        период Мин (IV-XII вв.), а коммерческое производство отмечено с 1796 года, в эпоху Цин. Существует древняя
        легенда о том, откуда появился белый чай. Она гласит о том, что во времена императора Яо женщина по имени Лан У
        жила у подножия горы, которая теперь называется Таймушань. Она бежала от войны, добралась до горы, осела в
        пещере с названием Хун Сюэ и стала зарабатывать на жизнь разведением орхидей. В то время в тех местах случилась
        эпидемия кори, и огромное количество жителей, особенно детей, умерло из-за отсутствия лекарств. И однажды как-то
        ночью госпоже Лан У приснился сон, в котором к ней явился старый даосский маг, который также был звездочетом.
        Маг сказал ей, что неподалеку от пещеры растет небольшое чайное деревце, которое выросло из чайного семени,
        которое однажды было им обронено, когда он нес семена этого растения для сада царицы Западного рая Си Ванму.
        Листья чайного дерева могут избавить людей от кори. Госпожа Лан У сразу же пробудилась и была очень удивлена,
        поэтому сразу же выбежала из пещеры, хотя и была ночь. Она стала искать чайное деревце и, в конце концов, нашла
        его среди густого кустарника. Госпожа Лан У сорвала зеленые листочки, высушила их и поутру передала в те деревни
        страдающим от кори жителям. Волшебный отвар из этих листьев избавил людей от болезни. С тех пор госпожа Лан У
        начала постоянно выращивать этот чудесный чай, а также научила всех деревенских жителей в округе ухаживать за
        чайным деревом. Через некоторое время эта округа превратилась в чаеводческий городок. Люди уважительно стали
        называть госпожу Лан У матушка Тайму — за ее покровительство и доброту. А гора Таймушань, на самом деле, была
        названа по имени матушки Тайму. Метод, которым госпожу Лан У приготовила чай, был продолжен и развит. Говорят,
        что в свое время первый Фудинский белый чай был сделан по этому же методу. И по сегодняшний день стоит старое
        дерево Фудинского белого чая, которое, по местным верованиям, было выращено самой госпожой Лан У. В настоящее
        время оно включено в список старых деревьев, находящихся под защитой правительства в провинции Фуцзянь.
      </p>
    </React.Fragment>
  ),
  howto: {
    text:
      'Заваривать горячей водой (70-80°С) в фарфоровой гайвани, чайнике из пористой глины или стеклянной посуде. Пропорция заварки к воде: 5 г на 100 мл. Для первого раза настоять 10-15 секунд, после чего пить проливом с постепенным увеличением экспозиции. Держит вкус и аромат 12 завариваний.',
    temp: '70-80',
    ratio: '5',
    time: '5',
  },
  taste: {
    grass: 3,
    berry: 3,
    spice: 4,
    flower: 3,
    mint: 3,
  },
  headerMargin: 14,
};

export default BaiMuDan;
