import React from 'react';

const henan = {
  id: 'henan',
  image: '/img/locations/henan.jpg',
  name: 'Хэнань',
  chiName: '河南',
  ruName: '',
  say: 'Hénán',
  teas: [{ name: 'Синь Ян Мао Цзянь' }],
  content: (
    <React.Fragment>
      <p>
        В мае 2014 г. близ деревни Линцзин в провинции Хэнань была сделана находка черепа и других частей скелета
        второго «сюйчанского человека» возрастом 100 тыс. лет, как назвало Государственное управление по охране
        культурного наследия КНР гоминида, которому принадлежал череп, найденный за 7 лет до этого буквально в том же
        квадрате раскопок.
      </p>
      <p>
        Стоянки человека разных периодов неолита были обнаружены в местах, которые ныне называются Пейлиган, Яншао,
        Дайхэ. Исследователи склонны считать, что это были в основном мирные земледельцы, занимавшиеся также охотой,
        рыболовством, собирательством. Были найдены и керамические сосуды культур Пейлиган и Яншао.
      </p>
      <p>
        Вблизи крупного города Аньяна находится важное свидетельство древности государственности Хэнани, руины Иньсю,
        столицы царства Шан-Инь (XVI-XI вв. до н. э.). Из семи древних столиц Поднебесной три — Лиан, Кайфэн и Кинься
        (современный город Баньян, сохранились развалины Кинься) — находились в Хэнани. Города Шанцю и Чжэнчжоу
        (современная столица Хэнани) — имеют статус культурно-исторических городов национального значения. Древнейшие
        китайские государства — династии Ся (III тыс. до н. э.) и династии Шан-Инь, пришедшая на смену Ся в XVI в. до н.
        э., возникли на территории Хэнани.{' '}
      </p>
      <p>
        Чжэнчжоу — более 3000 лет, здесь располагались обсерватории, примерные ровесники города, развалины которых
        сохранились. И хотя сегодня Чжэнчжоу выглядит во многом как этакий типовой мегаполис, ярко выраженных черт
        национальной культуры в его урбанизированной среде встречается немного. Но стоит зайти в музей провинции, как
        эта картина меняется на прямо противоположную. В музее представлена колоссальная (более 300 тыс. экспонатов)
        коллекция артефактов от древнейших времен, всех эпох расцвета Китая при всех 20 его династиях, имевших владения
        на территории Хэнани. И не только расцвета, конечно, но и всех испытаний, которые прошла Хэнань: крестьянские
        восстания, катастрофические последствия засух, наводнений, тайфунов, войн. Как государственное образование,
        провинция Хэнань в границах, близких к современным, возникла в XIII в.{' '}
      </p>
      <p>
        Сложившаяся как сельскохозяйственный регион, благо природные условия и плодородные почвы этому благоприятствуют,
        в наше время Хэнань является в Китае одним из самых динамично развивающихся экономических центров, что отнюдь не
        идет в ущерб земледелию: провинция не теряет своей позиции «житницы Китая» и занимает в целом по уровню ВРП 5-е
        место в стране и 1 -е место среди ее внутренних регионов. Большую роль играет Хэнань в энергетической и
        транспортной системе государства. В рамках политики открытых дверей созданы льготные налоговые условия для
        иностранных инвесторов, которые оборачиваются ростом рабочих мест. Сейчас в провинции выполняются еще несколько
        программ, рассчитанных на перспективу: развития ее интеллектуального потенциала, особенно в небольших городах,
        реконструкции и модернизации имеющихся промышленных предприятий, открытия новых, высокотехнологичных
        производств, расширения сферы услуг, которая пока оставляет желать лучшего.
      </p>
      <p>
        Растительный и животный мир провинции столь же богат и разнообразен, как и ее живописные ландшафты, создаваемые
        сочетанием равнин и лесистых гор. Здесь насчитывается 3830 видов высших растений и 418 видов животных.
      </p>
      <p>
        С трех сторон — северной, западного и южной — провинция Хэнань опоясана горными массивами Тайхатиань, Фуиюшань,
        Тунбошань, Дабешань, перемежаемых долинами. В Китае рельеф страны ассоциируется с четырьмя ступенями. По этой
        градации провинция Хэнань находится в зоне второй ступени, переходящей в третью ступень. Разные уровни
        «ступеней» чередуются между собой в сложных комбинациях, но постепенно рельеф провинции понижается от
        северозападного высокого «угла» на восток. В целом равнины и впадины занимают площадь 93 000 км2, высокогорья и
        низкогорья — 74 000 км2, что составляет соответственно 55,7% и 44,3% площади провинции.
      </p>
      <p>
        Провинция Хэнань — один из столпов традиционной древней китайской культуры во всем ее многообразии. Здесь более
        100 «достопримечательных мест» по китайской терминологии, и архитектурных, и природных.
      </p>
      <p>
        О любом из них можно рассказывать долго и в каждом случае находить для рассказа неповторимые детали. Сложный
        выбор предстоит всякому путешественнику, собирающемуся в Хэнань, чтобы получить незабываемые впечатления и
        обогатить свой личный внутренний мир эмоциями, которые можно пережить здесь. Есть места, которые в Хэнани
        миновать просто невозможно, потому что это было бы неправильно. К таким местам относятся прежде всего всемирно
        известные — пещерные гроты Лунмэнь (V-IX вв.), что в переводе означает «Врата дракона», в 15 км от Лояна и,
        конечно, Шаолинь.
      </p>
      <p>
        Пещеры Лунмэнь расположены над рекой Ихэ в массивах невысоких гор Сяньшань и Лунмэньшань. Точное количество
        гротов и небольших углублений неизвестно, но обычно называется цифра 2345. Храмов здесь 43, отдельно исчисляются
        настенные изображения, их более 110 000. Самыми искусными скульптурными изображениями Будды и бодхисаттв
        считаются те, что находятся в пещере Бин-Ян-Чжун-Дун. Грот Десяти Тысяч Будд (Ванфо-Дун) украшает рельеф из
        несметного количества миниатюрных Будд (гораздо больше, чем тысяча), а по центру в позе лотоса сидит Будца
        Гаутама, прикрыв глаза и подняв правую руку, он проповедует. Самые большие и величественные скульптуры Будды
        находятся в гроте Поклонения Предкам (Фэнсянсы), и среди них — 17-метровая скульптура Будды Вайрочаны. В конце
        череды гротов возвышается прекрасный храм Гуян. На восточном берегу тоже имеется ряд пещер. Замыкает комплекс
        усадьба матери великого китайского поэта IX в. Бо Цзюйи с его могилой, культовой для китайцев. Усадьба —
        классический образец китайского парка с ручьями, прудами с лотосами, зарослями бамбука, кипарисовыми и
        пальмовыми рощами, чайным домиком и площадкой с каменными стелами, на которой выбиты стихи Бо Цзюйи.
      </p>
      <p>
        Место для строительства монастыря Шаолинь на горе Суншань, одной из пяти священных гор Китая, было выбрано со
        смыслом: форма горы напоминает лотос. В XX в. во всем мире Шаолинь обрел необычайную популярность. Произошло это
        благодаря многочисленным кинофильмам, сюжет и жанровые особенности которых строились на поэтизации и
        прославлении боевого искусства кун-фу. Кинематограф склонен к преувеличениям ради выразительности, это
        нормально, однако не на экране, а в жизни монахи-мастера кун-фу могут бегать, не причиняя себе вреда, по
        осколкам стекла, ходить по вертикальным плоскостям, спать на столбах, разрубать ладонью камни, пробивать дерево
        пальцем, отбивать удары пик разными частями незащищенного доспехами тела, дотрагиваться языком до огня и не
        обжечься, и это еще далеко не все чудеса, которые они способны творить. Обучение кун-фу всех желающих сегодня
        ведется в монастыре на коммерческой основе, но надо понимать, что не все тайны этого боевого искусства
        открываются неофитам. К тому же быть физически натренированным, знать приемы — еще далеко не всё, хорошему бойцу
        необходимо владеть также целым сводом знаний в медицине и философии, вести определенный образ жизни. Кроме кунфу
        в Шаолине практикуют шаолиньский бокс, битву с копьем, мечом, прутом; цингун (легкий бой), нэйгун (внутренний
        бой), тунцзигун (бой мальчиков), чжуангун (бой на столбах), машангун (бой на спине лошади), буягун (бой босиком)
        и оздоровительные техники дыхательной гимнастики цигун.
      </p>
      <p>
        Шаолинь на горе Суншань и гора как таковая, со всеми ее пагодами, сохранившимися и пребывающими в руинах,
        другими многочисленными историческими памятниками, и культурная сокровищница провинции — город Дэнфэн все на той
        же горе, находятся близко друг от друга, и вкупе их принято именовать подчеркнуто значительно и пышно:
        исторический монументальный ансамбль «Центр Неба и Земли».
      </p>
    </React.Fragment>
  ),
};

export default henan;
