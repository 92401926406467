import React from 'react';

import s from './CookBlock.module.css';

import { ReactComponent as Dash } from '../svg/dash.svg';

const CookBlock = (props) => {
  const { tea } = props;

  if (!tea.howto) return null;
  if (!tea.howto.temp && !tea.howto.ratio && !tea.howto.time) return null;

  return (
    <div className={s.CookBlock}>
      <div className={s.chiName}>食谱</div>
      <div className={s.Grid}>
        {tea.howto.temp && (
          <React.Fragment>
            <div>Температура</div>
            <div className={s.Center}>
              <Dash />
            </div>
            <div>
              <span>{tea.howto.temp}°</span> <small>C</small>
            </div>
          </React.Fragment>
        )}
        {tea.howto.ratio && (
          <React.Fragment>
            <div>Пропорция</div>
            <div className={s.Center}>
              <Dash />
            </div>
            <div>
              <span>{tea.howto.ratio}</span> <small>г/100мл</small>
            </div>
          </React.Fragment>
        )}
        {tea.howto.time && (
          <React.Fragment>
            <div>Время</div>
            <div className={s.Center}>
              <Dash />
            </div>
            <div>
              <span>{tea.howto.time}</span> <small>с</small>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default CookBlock;
