import React from 'react';

const jiangsu = {
  id: 'jiangsu',
  image: '/img/locations/jiangsu.jpg',
  name: 'Цзянсу',
  chiName: '江苏',
  ruName: '',
  say: 'Jiāngsū',
  teas: [{ name: 'Дун Тин Би Ло Чунь' }, { name: 'Нань Цзин Юй Хуа Ча' }],
  content: (
    <React.Fragment>
      <p>
        Китайские летописные источники сообщают, что эта территория была заселена приблизительно шесть тысяч лет назад.
        Представить, как жили здесь люди за весь этот исторический период, можно и сейчас. Их верным союзником всегда
        была природа. Климат провинции — умеренный к северу от ирригационного канала Хуайхэ и классический
        субтропический — к югу от него. В обоих вариантах это муссонный, но мягкий климат — дожди здесь идут не слишком
        сильные и с перерывами. Плюс — обилие рек. Озер в Цзянсу 290. Два из них — Тайху и Хунцзэху — входят в пятерку
        самых крупных озер Китая. Плюс морское побережье. Словом, только трудись и не пропадешь, чего китайцам объяснять
        не надо.
      </p>
      <p>
        Этнические корни первых известных по летописям обитателей Цзянсу китайские ученые связывают с хуэй и. Хуэй, или
        хуай (в зависимости от транскрипции на другие языки иероглифа с таким значением), по-китайски означает «народ»,
        таким образом, название этого народа — и. Его сегодняшняя численность — около 8 млн человек. И (народ) относится
        к так называемым южным монголоидам. Они составляли основную часть населения территории Цзянсу в эпохи древних
        династий Чжоу (ХI-III вв. до н. э.), Ся (2070-1765 гг. до н. э.) и Шан (1600-1027 гг. до н. э.). Сегодня и живут
        в разных регионах Китая, а также во Вьетнаме, а в Цзянсу — нет, они как бы растворились в других этносах, т. е.
        ассимилировались с ними.
      </p>
      <p>
        Вассал Китайской империи царство У, или By (до 585-473 гг. до н. э.), населенное народом юэ (современные вьеты),
        управляло Цзянсу в период Весен и Осеней (722-481 гг. до н. э.). Столицей У с 514 г. до н. э. был город Сучжоу
        (Гусу). В 473 г. до н. э. У было завоевано царством Юэ, а Юэ затем — царством Чу. В составе Чу в 221 г. до н. э.
        территория Цзянсу вошла в империю Цинь (221-206 гг. до н. э.). При династии Хань (206 г. до н. э. — 220 г. н.
        э.) Цзянсу была разделена на северную и южную части со столицей Нанкином в южной части. При династии Тан
        (618-907 гг.) в провинции начинает развиваться торговля, при династии Сун (960-1279 гг.) это уже источник
        больших состояний, а города Сучжоу и Янчжоу считаются самыми богатыми и роскошными в Поднебесной. К Цзянсу
        относился и Шанхай, тогда заштатный городок (бурно расти он начал со второй половины XIX в.).
      </p>
      <p>
        Нанкин, сейчас столица Цзянсу, за свою историю пребывает в этом статусе в шестой раз. В 1368-1425 гг., при
        императоре династии Мин Чжу Юаньнжане, который кардинально перестроил город, Нанкин был столицей всей империи
        (Южной столицей) и считался крупнейшим городом мира, пока император династии Мин Юнлэ (Чжу Ди) не сделал центром
        Поднебесной Пекин (Северную столицу).
      </p>
      <p>
        Территория, оформившаяся как Цзянсу в 1666 г., не раз меняла свои границы, но все же не настолько, чтобы не
        считаться практически постоянной с XVII в. Название она получила от первых слогов названий города Цзянсинь,
        одного из других имен Нанкина (он назывался также и Нянцзинь) и города Сучжоу. После Синьхайской революции 1911
        г., когда в Китае была свергнута власть маньчжурской династии Цин, Цзянсу не раз переходила из рук в руки разных
        политических сил, пока в 1927 г. не была занята Гоминьданом. В 1937 г. в ходе Второй японо-китайской войны
        провинцию оккупировали японцы, ознаменовав это ужасным преступлением — так называемой Нанкинской резней —
        убийством около 250 тыс. китайцев. Провинция оставалась фактически под властью Японии (при марионеточном
        правительстве Китайской Республики) до окончания Второй мировой войны в сентябре 1945 г. Затем в Китае была
        гражданская война. С 1949 г. Цзянсу — провинция КНР.
      </p>
    </React.Fragment>
  ),
};

export default jiangsu;
