import React from 'react';
import { NavLink } from 'react-router-dom';

import s from './TeaHeader.module.css';

const TeaHeader = (props) => {
  const { data } = props;
  return (
    <header className={s.TeaBlockName}>
      <NavLink to={`/family/${data.id}`}>
        {data.chiName && <span className={s.SecondName}>{data.chiName}</span>}
        <span className={s.SubName}>
          {data.name && <span className={s.Name}>{data.name}</span>} ∙{' '}
          {data.ruName && <span className={s.RuName}>{data.ruName}</span>}
        </span>
      </NavLink>
    </header>
  );
};

export default TeaHeader;
