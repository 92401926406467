const DaHongPao = {
  id: 'DaHongPao',
  name: 'Да Хун Пао',
  image: '/img/tea/DaHongPao.png',
  chiName: '大红袍',
  enName: 'Da Hong Pao',
  ruName: 'Большой Красный Халат, ДХП, Дахунпао',
  location: 'Северо-запад провинции Фуцзянь, в горах Уи',
  legend:
    'Как гласят исторические записи монастыря Тян Син Сы, в 1385 г. в династию Мин (в 18 год под девизом Хун У), студент Дин Сянь, направляясь на сдачу императорских экзаменов, получил тепловой удар, и один из монахов вышеназванного монастыря использовал чай, чтобы его вылечить. Успешно сдав экзамен и получив высокую должность Чжуан Юань (которой соответствовал большой красный халат с изображением драконов), желая отблагодарить Будду за чудесное излечение, Дин Сянь вернулся в монастырь, где преподнес Халат монаху, который его излечил. Но монах, как истинный буддист, не принял столь высокий дар. Тогда чиновник укрыл Халатом кусты чая. Так и появилось название Да Хун Пао: «Большой красный халат».',
  howto: {
    text:
      'Заваривать горячей водой (95°С) в чайнике из пористой глины или в фарфоровой гайвани. Пропорция заварки к воде: 6 г на 100 мл. Пить проливом с постепенным увеличением экспозиции. Держит вкус и аромат 5 завариваний.',
    temp: '95',
    ratio: '6',
    time: '2',
  },
  taste: {
    fruit: 3,
    spice: 4,
    bread: 4,
  },
  headerMargin: 8,
};

export default DaHongPao;
