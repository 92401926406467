import React from 'react';

const zhejiang = {
  id: 'zhejiang',
  image: '/img/locations/zhejiang.jpg',
  name: 'Чжэцзян',
  chiName: '浙江',
  ruName: '',
  say: 'Zhèjiāng',
  teas: [
    { name: 'Сиху Лун Цзин' },
    { name: 'Лань Си Мао Фэн' },
    { name: 'Ю Цзи Сян Мн=ин Мао Фэн' },
    { name: 'Цзю Цюй Хун Мэй' },
    { name: 'Кай Хуа Лун Дин' },
    { name: 'Аньцзи Бай Ча' },
  ],
  content: (
    <React.Fragment>
      <p>
        Чжэцзян является одной из колыбелей китайской культуры. Во времена неолита в области современного региона
        проживали представители культур хэмуду, мацзябинь и лиангджу. Предполагается, что они были предками многих
        современных народностей, проживающих в Китае, в том числе и ханьцев. Остатки многочисленных стоянок древних
        племен были обнаружены археологами, в том числе и вдоль побережья реки Янцзы.
      </p>
      <p>
        В 1-м тысячелетии до н. э. в регионе стали появляться племена, которые ныне известны под общим названием байюэ.
        Около VII в. до н. э. на севере провинции Чжэцзян этой племенной группой было создано Царство Юэ. В IV в. до н.
        э., в период Сражающихся царств, государства Чу и Ци вместе захватили территории Юэ и распределили их между
        собой. В 222 г. до н. э. земли Чжэцзян были аннексированы государством Цинь, оно сформировало префектуру
        Куайдзи. Именно она впоследствии стала своеобразной базой для восстания против династии Цинь, лидером которого
        стали Сян Лян и Сян Юй. В результате династия Цинь была свержена, и государство Юэ на время было восстановлено.
        Однако владения Сян Юя были разрозненными, что обеспечивало почву для междоусобных конфликтов. Уязвимое
        государство было захвачено новой династией Хань, чья власть над регионом, правда, была номинальной.
      </p>
      <p>
        В III в., во время Троецарствия, провинция Чжэцзян находилась под управлением государства У, которое было самым
        богатым и процветающим из трех. Кроме того, оно обладало серьезной военной мощью, особенно флотом. В 1132 г. во
        времена династии Южная Сун чжэцзянский город Ханчжоу, носивший тогда название Линьань, служил столицей. Вскоре
        он стал одним из нескольких крупных портов провинции Чжэцзян, которые богатели за счет международной морской
        торговли, что на тот момент преобладала над торговлей по суше. Регион стал крупным экспортером шелка, фарфора и
        бумаги, набрало обороты и рыболовство.
      </p>
      <p>
        К 1279 г. монголам удалось взять под контроль весь Китай. Современные границы провинции Чжэцзян сложились к XIV
        в., уже после того как монголы были вытеснены из региона стараниями представителей династии Мин. Тогда же
        провинция приобрела и свое название.
      </p>
      <p>
        В ходе Японо-китайской, а затем и Второй мировой войны провинция Чжэцзян была оккупирована японскими войсками.
        На территориях, подвергшихся оккупации, была установлена власть марионеточного государства, причем власть
        поддерживалась японцами с помощью террора по отношению к китайцам. Любые попытки оказывать поддержку союзникам
        влекли за собой жестокое наказание. После основания Китайской Народной Республики в 1949 г. для провинции
        Чжэцзян наступили не лучшие времена, и причины тому были, в том числе экономические. Новые власти делали ставку
        на железнодорожную инфраструктуру, поэтому порты, которые всегда кормили регион, оказались менее
        востребованными, чем прежде.
      </p>
      <hr />
      <p>
        Провинция была образована после основания империи Мин в 1368 году. В 1381 году в её состав были включены
        Хучжоуская и Цзясинская управы, до этого напрямую подчинённую императорскому двору, и провинция приобрела
        современные границы.
      </p>
      <h3>Озеро Сиху</h3>
      <p>
        Озеро Сиху («Западное озеро») расположено с западной стороны исторического центра города Ханчжоу; с трёх
        остальных сторон оно окружено горами.{' '}
      </p>
      <p>
        Остров Гушань, дамбы Суди, Боди и Янгунди делят озеро на пять зон: Внешнее Западное озеро (外西湖), Западное
        внутреннее озеро (西裡湖), Северное внутреннее озеро (北裡湖), Малое южное озеро (小南湖) и озеро Юэ (岳湖).
        Остров Гушань — это самый крупный естественный остров на озере. В центре Внешнего Западного озера лежат три
        рукотворных острова: Сяоинчжоу (小瀛洲), Хусиньтин (湖心亭) и Жуаньгундунь (阮公墩).
      </p>
      <h5>От династии Цинь до династии Тан</h5>
      <p>Озеро постепенно образовалось около двух тысяч лет назад из лагуны реки Цяньтан.</p>
      <p>
        При династии Тан озеро занимало примерно 10,8 км² — вдвое больше своего нынешнего размера. Так как в то время не
        велось никаких мелиоративных работ, то озеро выходило из берегов в случае сильных дождей и уменьшалось в
        размерах в случае засухи.
      </p>
      <p>
        В 781 году губернатором Ханчжоу был назначен Ли Ми. Чтобы обеспечить горожан питьевой водой, он приказал
        выкопать шесть глубоких колодцев, и соединить их с озером подземными трубами. Остатки одного из этих колодцев
        сохранились до нашего времени.
      </p>
      <p>
        В конце VIII века губернатором Ханчжоу стал Бо Цзюйи. Он понял, что сельское хозяйство зависит от воды из Сиху,
        но из-за нерадивости прежних губернаторов дамбы пришли в негодность, и поля теперь страдают от засухи. По его
        приказу была сооружена крепкая и прочная дамба, контролирующая уровень воды, и тем самым была решена проблема
        орошения полей. Благосостояние жителей улучшилось, и у Бо Цзюйи появилось время, чтобы посещать озеро и
        любоваться его красотами. По его приказу, вдоль дороги, идущей по дамбе, были высажены деревья, чтобы сделать
        пешие путешествия более удобными и приятными.
      </p>
      <h5>От Эпохи пяти династий до династии Сун</h5>
      <p>
        В эпоху пяти династий и десяти царств город Ханчжоу стал столицей царства У Юэ. Удобное прибрежное положение
        Ханчжоу сделало город центром торговли — как прибрежной, так и заморской. В связи с тем, что императоры У Юэ
        почитали буддизм, в этот период было построено большое количество буддийских храмов и пагод, а сам район стали
        называть «землёй буддизма».
      </p>
      <p>
        Два века спустя, уже при династии Сун, губернатором Ханчжоу стал другой великий поэт — Су Ши. Ему тоже пришлось
        решать проблемы орошения окрестных земель, и он также прославился построением дамбы.
      </p>
      <p>
        С 1227 года Ханчжоу стал столицей династии Южная Сун. Начался резкий рост численности населения, активно
        развивалась экономика, и красоты озера Сиху стали широко известны, так как о них писали многие столичные поэты.
      </p>
      <h5>От династии Юань до династии Цин</h5>
      <p>
        Во времена династии Юань Ханчжоу оставался процветающим городом. Согласно «Истории династии Юань», в 1309 году
        только за полгода город посетило свыше 1200 иностранных визитёров. В период монгольского правления в Ханчжоу
        побывал знаменитый венецианский путешественник Марко Поло. Во времена Хубилая, в годы правления под девизом
        «Чжиюань» (1264—1294) озеро было вновь углублено. Однако к концу монгольского правления частные владельцы
        монополизировали водные ресурсы, внимание к озеру ослабло, и оно стало постепенно превращаться в болото.
      </p>
      <p>
        При династии Мин Ханчжоу начал восстанавливать своё богатство. Губернатор Ян Мэнъин при поддержке специального
        уполномоченного Цзюй Ляна сломил сопротивление местных магнатов и получил разрешение углубить озеро. Работы
        длились 152 дня, стоили 23.607 лян серебра, было уничтожено много незаконных полей, озеро Сиху восстановило вид,
        который оно имело при династиях Тан и Сун. За счёт извлечённой из озера земли была расширена дамба Суди, а также
        выстроена дамба Янгунди.
      </p>
      <p>
        Во времена династий Мин и Цин озеро многократно углублялось; из извлечённой земли были насыпаны острова
        Сюйсиньтин и Сяоинчжоу.
      </p>
      <p>
        При династии Цин императоры, правившие под девизами «Канси» и «Цяньлун», неоднократно посещали южный Китай и
        много раз бывали в Ханчжоу; они оставили многочисленные записи о видах озера Сиху. Во времена правления под
        девизом «Цяньлун» братья Цюй Хао и Цюй Хань написали книгу «Взгляд на озеро и холмы», в которой описали 1016
        достопримечательностей в районе озера Сиху — это был первый в истории туристический путеводитель по Ханчжоу.
      </p>
      <h5>От Китайской республики до третьего тысячелетия</h5>
      <p>
        В конце династии Цин и в начале периода Китайской республики были построены железные дороги Шанхай-Ханчжоу-Нинбо
        и Чжэцзян-Цзянси, а также автострады Ханчжоу-Шанхай, Ханчжоу-Нанкин и Ханчжоу-Нинбо. Улучшенные транспортные
        возможности привели к росту числа туристов как из Китая, так и из-за рубежа. Местные власти стали создавать
        условия для отдыха и улучшать достопримечательности в Ханчжоу и вокруг озера Сиху.
      </p>
      <p>
        25 сентября 1924 года, из-за постоянных подкопов под фундамент, неожиданно обрушилась пагода Лэйфэн, в
        результате чего озеро Сиху лишилось одного из классических видов. Это событие оказалось шоком для
        общественности; знаменитый писатель Лу Синь оставил две заметки об этом инциденте.
      </p>
      <p>
        6 июня — 20 октября 1929 года правительство провинции Чжэцзян провело международную «Выставку у озера Сиху»,
        которую посетило свыше 20 миллионов человек.
      </p>
      <p>
        После образования КНР Ханчжоу стал первым туристским центром страны. Местные власти взяли под охрану горный
        район вокруг озера и высадили много деревьев, а также провели большие работы по углублению озера. В 1959 году
        озеро Сиху посетили 1400 иностранных туристов, 2300 туристов из Гонконга и свыше 5 миллионов туристов из Китая.
        После Культурной революции произошёл резкий рост числа туристов: в 1978 году озеро Сиху посетило 53 тысячи
        туристов из-за границы (включая Гонконг и Макао) и свыше 6 миллионов туристов из Китая.
      </p>
      <p>
        С 20 октября по 10 ноября 2000 года, после перерыва в 71 год, вновь состоялась международная «Выставка у озера
        Сиху», которую посетило 1.400.000 человек из Китая и из-за границы.
      </p>
    </React.Fragment>
  ),
};

export default zhejiang;
