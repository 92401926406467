import React from 'react';
import { /* NavLink, */ useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import classNames from 'classnames';

import locations from '../data/locations';
// import { basic, listVariants, itemVariants } from '../data/framerVars';

import s from './LocationItem.module.css';
import sh from './share.module.css';

// import { ReactComponent as BackIcon } from '../svg/back.svg';

const basic = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
  exit: { opacity: 0 },
};

const imgVars = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0, transition: { delay: 0.5 } },
  exit: { opacity: 0, x: 100 },
};

const infoVars = {
  initial: { opacity: 1, y: 0 },
  animate: { opacity: 1, y: 0, transition: { delay: 0.2, staggerChildren: 0.15 } },
  exit: { opacity: 0, y: 50 },
};

const contVars = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

const LocationItem = () => {
  const params = useParams();
  const locationData = locations.find((el) => el.id === params.locationId);

  return (
    <motion.section
      className={classNames([sh.PageWrapper])}
      variants={basic}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {/* <motion.div variants={contVars} className={s.Back}>
        <NavLink to={`/locations`}>
          <BackIcon />
        </NavLink>
      </motion.div> */}
      <motion.div variants={contVars} className={s.chiName}>
        {locationData.chiName}
      </motion.div>
      <motion.div variants={contVars} className={s.Name}>
        {locationData.name} ∙ {locationData.say}
      </motion.div>
      <div className={s.Grid}>
        <motion.div variants={infoVars} className={s.Info}>
          <motion.div variants={contVars}>{locationData.content}</motion.div>
        </motion.div>
        <motion.div variants={contVars} div className={classNames([s.ImageWrap])}>
          <img src={locationData.image} alt="" />
          <br />
          <br />
          <img src={`/img/maps/${params.locationId}.jpg`} alt="" />
        </motion.div>
      </div>
    </motion.section>
  );
};

export default LocationItem;
