import React from 'react';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

import MenuItem from './MenuItem';

import s from './Navigation.module.css';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const MenuList = React.memo((props) => {
  const { links = [], toggle } = props;
  return (
    <motion.ul className={s.ul} variants={variants}>
      {links.length > 0 &&
        links.map((link, i) => {
          return (
            <MenuItem key={i}>
              <NavLink onClick={toggle} to={link.to} className={s.link}>
                {link.text}
                {link.chiName && <span className={s.chiName}> ∙ {link.chiName} </span>}
              </NavLink>
            </MenuItem>
          );
        })}
    </motion.ul>
  );
});

export default MenuList;
