import React from 'react';

import s from './NamesTable.module.css';

const NamesTable = () => {
  return (
    <table className="tea-sorta">
      <tbody>
        <tr className="tea-vid">
          <th>白茶</th>
          <th>Бай Ча</th>
          <th>Белый чай</th>
          <th></th>
        </tr>
        {/* <tr>
          <th>Иероглифы</th>
          <th>Имя чая</th>
          <th>Название чая</th>
          <th>Вид</th>
        </tr> */}
        <tr>
          <td>白牡丹</td>
          <td>Бай Му Дань</td>
          <td>Белый пион</td>
          <td>Белый чай</td>
        </tr>
        <tr>
          <td>白毫银针</td>
          <td>Бай Хао Инь Чжэнь</td>
          <td>Серебряные иглы с белыми ворсинками</td>
          <td>Белый чай</td>
        </tr>
        <tr>
          <td>贡眉</td>
          <td>Гун Мэй</td>
          <td>Дворцовые брови</td>
          <td>Белый чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Гу Шу Бай Я</td>
          <td>Я Бао, весенние почки Юннаня</td>
          <td>Белый чай</td>
        </tr>
        <tr>
          <td>银螺</td>
          <td>Инь Ло</td>
          <td>Серебряная улитка</td>
          <td>Белый чай</td>
        </tr>
        <tr>
          <td>茉莉白毛猴</td>
          <td>Моли Бай Мао Хоу</td>
          <td>Беловолосая обезьяна</td>
          <td>Жасминовый белый чай</td>
        </tr>
        <tr>
          <td>天山银针勾</td>
          <td>Тянь Шань Инь Чжэнь Гоу</td>
          <td>Гнутые серебрянные иглы</td>
          <td>Белый чай</td>
        </tr>
        <tr>
          <td>红茶味白牡丹</td>
          <td>Хун Ча Вэй Бай Му Дань</td>
          <td>Белый пион со вкусом красного чая</td>
          <td>Белый чай</td>
        </tr>
        <tr>
          <td>寿眉</td>
          <td>Шоу Мэй</td>
          <td>Брови долголетия</td>
          <td>Белый чай</td>
        </tr>
        <tr>
          <td>月光白</td>
          <td>Юэ Гуан Бай</td>
          <td></td>
          <td>Белый чай</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="tea-vid">
          <th>黄茶</th>
          <th>Хуан Ча</th>
          <th>Желтый чай</th>
          <th></th>
        </tr>
        {/* <tr>
          <th>Иероглифы</th>
          <th>Имя чая</th>
          <th>Название чая</th>
          <th>Вид</th>
        </tr> */}
        <tr>
          <td>银针王</td>
          <td>Инь Чжэнь Ван</td>
          <td>Владыка Серебрянных Игл</td>
          <td>Желтый чай</td>
        </tr>
        <tr>
          <td>蒙顶黄芽</td>
          <td>Мэн Дин Хуан Я</td>
          <td>Желтые почки с горы Мэн Дин</td>
          <td>Желтый чай</td>
        </tr>
        <tr>
          <td>茉莉银针</td>
          <td>Моли Иньчжэнь</td>
          <td>Серебряные иглы с белыми ворсинками</td>
          <td></td>
        </tr>
        <tr>
          <td>君山银针</td>
          <td>Цзюнь Шань Инь Чжэнь</td>
          <td>Серебряные иглы с гор Цзюнь-шань</td>
          <td>Желтый чай</td>
        </tr>
        <tr>
          <td>君山银针极品</td>
          <td>Цзюнь Шань Инь Чжэнь Цзи Пинь</td>
          <td>Серебряные иглы с гор Цзюнь-шань, высшее качество</td>
          <td>Желтый чай</td>
        </tr>
        <tr>
          <td>霍山黄牙</td>
          <td>Хоу Шань Хуан Я</td>
          <td>Желтые почки с горы Хоу Шань</td>
          <td>Желтый чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Юэ Ян Хуан Ча</td>
          <td></td>
          <td>Желтый чай</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div>Вэй Шань Мао Цзянь</div>
          </td>
          <td></td>
          <td>Желтый чай</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div>Хуо Шань Хуан Да Ча</div>
          </td>
          <td></td>
          <td>Желтый чай</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="tea-vid">
          <th>绿茶</th>
          <th>Люй Ча</th>
          <th>Зеленый чай</th>
          <th></th>
        </tr>
        {/* <tr>
          <th>Иероглифы</th>
          <th>Имя чая</th>
          <th>Название чая</th>
          <th>Вид</th>
        </tr> */}
        <tr>
          <td>安吉白茶</td>
          <td>Аньцзи Байча</td>
          <td>Белый чай из уезда Аньцзи</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Бай Мао Хоу</td>
          <td>Беловолосая обезьяна</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>碧螺春</td>
          <td>Би Ло Чунь</td>
          <td>Изумрудные спирали весны из Дунтина</td>
          <td>Зеленый спиральный чай</td>
        </tr>
        <tr>
          <td>碧螺春极品</td>
          <td>Би Ло Чунь Цзи Пинь</td>
          <td>Изумрудные спирали весны из Дунтина, высшее качество</td>
          <td>Зеленый спиральный чай</td>
        </tr>
        <tr>
          <td>平水珠茶</td>
          <td>Ганпаудер</td>
          <td>Чжу Ча, Храм неба или Жемчужный чай</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>高山云雾</td>
          <td>Гао Шань Юнь У</td>
          <td>Облачный туман с Высоких Гор</td>
          <td>Зеленый спиральный чай</td>
        </tr>
        <tr>
          <td>顶谷大方</td>
          <td>Дин Гу Да Фан</td>
          <td>Долина на вершине, большой квадрат</td>
          <td>Зеленый плоский чай</td>
        </tr>
        <tr>
          <td>顶谷极品</td>
          <td>Дин Гу Цзи Пин</td>
          <td>Долина на вершине, большой квадрат, высшее качество</td>
          <td>Зеленый плоский чай</td>
        </tr>
        <tr>
          <td>洞庭碧螺春</td>
          <td>Дун Тин Би Ло Чунь</td>
          <td>Изумрудные спирали весны из Дунтина</td>
          <td>Зеленый спиральный чай</td>
        </tr>
        <tr>
          <td>野毛峰</td>
          <td>Е Мао Фэн</td>
          <td>Ворсистые Пики. Дикоростущий</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>野生</td>
          <td>Е Шэн Люй Чай</td>
          <td>Дикорастущий</td>
          <td>Зеленый спиральный чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Зеленый Лю Хао</td>
          <td></td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>银环</td>
          <td>Инь Хуань</td>
          <td>Серебрянные кольца</td>
          <td>Зеленый скрученный чай</td>
        </tr>
        <tr>
          <td>银芽</td>
          <td>Инь Я</td>
          <td>Серебрянные почки</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>荔枝</td>
          <td>Ли Чжи</td>
          <td>Чай в форме плода личжи</td>
          <td>Зеленый связанный чай</td>
        </tr>
        <tr>
          <td>龙顶</td>
          <td>Лун Дин</td>
          <td>Драконий пик</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>龙井茶</td>
          <td>Лун Цзин</td>
          <td>Колодец дракона</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>六安瓜片</td>
          <td>Лю Ань Гуа Пянь</td>
          <td>Тыквенные семечки из Люаня</td>
          <td>Зеленый листовой чай</td>
        </tr>
        <tr>
          <td>绿银针</td>
          <td>Люй Инь Чжень</td>
          <td>Серебрянные иглы, зеленый чай</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>绿莲</td>
          <td>Люй Лянь</td>
          <td>Зеленый Лотос</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>绿牡丹</td>
          <td>Люй Му Дань</td>
          <td>Зеленый пион</td>
          <td>Зеленый листовой чай</td>
        </tr>
        <tr>
          <td>绿飘雪</td>
          <td>Люй Пяо Сюэ</td>
          <td>Кружащийся снег</td>
          <td>Зеленый скрученный чай</td>
        </tr>
        <tr>
          <td>绿玉露</td>
          <td>Люй Юй Лу</td>
          <td>Нефритовая роса</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>绿芽</td>
          <td>Люй Я</td>
          <td>Зеленые почки</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>毛香针</td>
          <td>Мао Сян Чжэнь</td>
          <td>Ворсистые ароматные иглы</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉白龙须</td>
          <td>Моли Бай Лун Сюй</td>
          <td>Усы белого дракона</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉白毛尖</td>
          <td>Моли Бай Мао Цзянь</td>
          <td>Беловолосые острия</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉白雪</td>
          <td>Моли Бай Сюэ</td>
          <td>Белый снег жасмина</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉白雪香</td>
          <td>Моли Бай Сюэ Сян</td>
          <td>Аромат белого снега</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉白玉扣</td>
          <td>Моли Бай Юй Коу</td>
          <td>Моток белого нефрита (застежка из белого нефрита)</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉白玉兔</td>
          <td>Моли Бай Юй Ту</td>
          <td>Белый нефритовый заяц</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉飘雪</td>
          <td>Моли Би Тань Пяо Сюэ</td>
          <td>Кружащийся снег с косы Би Тань</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉大方</td>
          <td>Моли Да Фан</td>
          <td>Жасминовый большой квадрат</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉银毫</td>
          <td>Моли Инь Хао</td>
          <td>Серебрянный ворс</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉银针</td>
          <td>Моли Инь Чжэнь</td>
          <td>Жасминовые серебряные иглы</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉兰雪</td>
          <td>Моли Лань Сюэ</td>
          <td>Орхидеевый снег</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉龙珠</td>
          <td>Моли Лун Чжу</td>
          <td>Жасминовая жемчужина</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉茗眉</td>
          <td>Моли Мин Мэй</td>
          <td>Жасминовые чайные брови</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉女儿环</td>
          <td>Моли Нюй Эр Хуань</td>
          <td>Девичьи локоны</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉般螺</td>
          <td>Моли Пань Ло</td>
          <td>Сплющенные шары</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉绣球</td>
          <td>Моли Сю Цю</td>
          <td>Жасминовае Скрученные шары</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉雪花</td>
          <td>Моли Сюэ Хуа</td>
          <td>Жасминовый Снежный цветок</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉香针</td>
          <td>Моли Сян Чжэнь</td>
          <td>Ароматные иглы</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉投天香</td>
          <td>Моли Тоу Тянь Сян</td>
          <td>Аромат, пронизывающий небо</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉凤眼</td>
          <td>Моли Фэн Янь</td>
          <td>Глаз феникса</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉猴王</td>
          <td>Моли Хоу Ван</td>
          <td>Повелитель обезьян</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉花茶</td>
          <td>Моли Хуа Ча</td>
          <td></td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉黑金</td>
          <td>Моли Хэй Цзинь</td>
          <td>Черное золото</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉金龙凤眼</td>
          <td>Моли Цзинь Лун Фэн Янь</td>
          <td>Золотой дракон и феникс</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉金丝银构</td>
          <td>Моли Цзинь Сы Инь Го</td>
          <td>Жасминовые золотые нити серебряные крючки</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉金螺</td>
          <td>Моли Цзинь Сы Ло</td>
          <td>Золотые скрученные нити</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉金针</td>
          <td>Моли Цзинь Чжень</td>
          <td>Золотые иглы</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉金针王</td>
          <td>Моли Цзинь Чжэнь Ван</td>
          <td>Владыка золотых игл</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉君针</td>
          <td>Моли Цзюнь Чжень</td>
          <td>Жасминовые Иглы с горы Цзюнь Чжень</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉茶王</td>
          <td>Моли Ча Ван</td>
          <td>Князь Жасмина</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉茶王金葫芦</td>
          <td>Моли Ча Ван Цзинь Ху Лу</td>
          <td>Владыка Жасмина – Золотая тыква</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉针王</td>
          <td>Моли Чжэнь Ван</td>
          <td>Правитель жасминовых игл</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉针螺</td>
          <td>Моли Чжэнь Ло</td>
          <td>Жасминовые драгоценные шары</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉针形茶王</td>
          <td>Моли Чжэнь Син Ча Ван</td>
          <td>Владыка жасмина в форме игл</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉珍珠</td>
          <td>Моли Чжэнь Чжу</td>
          <td>Жасминовые жемчужины</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉春针</td>
          <td>Моли Чунь Чжэнь</td>
          <td>Жасминовые весенние иглы</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉云峰</td>
          <td>Моли Юнь Фэн</td>
          <td>Облачный пик</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>茉莉云芽</td>
          <td>Моли Юнь Я</td>
          <td>Облачные почки</td>
          <td>Жасминовый зеленый чай</td>
        </tr>
        <tr>
          <td>梅家乌龙井</td>
          <td>Мэй Цзя У Лун Цзин</td>
          <td>Колодец дракона из оврага Мэй Цзя</td>
          <td>Зеленый плоский чай</td>
        </tr>
        <tr>
          <td>蒙顶甘露</td>
          <td>Мэн Дин Гань Лу</td>
          <td>Сладкая роса с горы Мэн Дин</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>蒙顶云雾</td>
          <td>Мэн Дин Юнь У</td>
          <td>Облачный Туман с горы Мэн Дин</td>
          <td>Зеленый спиральный чай</td>
        </tr>
        <tr>
          <td>西湖龙井</td>
          <td>Си Ху Лун Цзин</td>
          <td>Колодец дракона с озера Си Ху</td>
          <td>Зеленый плоский чай</td>
        </tr>
        <tr>
          <td>龙井</td>
          <td>Синь Чань Лун Цзин</td>
          <td>Колодец дракона из Синь Чань</td>
          <td>Зеленый плоский чай</td>
        </tr>
        <tr>
          <td>雪花</td>
          <td>Синь Чунь Сюэ Хуа</td>
          <td>Снежный Цветок из Синь Чунь</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>信阳毛尖</td>
          <td>Синь Ян Мао Цзянь</td>
          <td>Ворсистые лезвия из Синь Ян</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Сенча</td>
          <td></td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>松绿</td>
          <td>Сун Люй</td>
          <td>Сосновые иглы</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>绣球</td>
          <td>Сю Цю</td>
          <td>Скрученные шары</td>
          <td>Зеленый скрученный чай</td>
        </tr>
        <tr>
          <td>雪花</td>
          <td>Сюэ Хуа</td>
          <td>Снежный цветок</td>
          <td>Зеленый скрученный чай</td>
        </tr>
        <tr>
          <td>雪针</td>
          <td>Сюэ Чжэнь</td>
          <td>Снежные иглы</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>香针</td>
          <td>Сян Чжэнь</td>
          <td>Ароматные иглы</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>太平魁尖</td>
          <td>Тай Пин Куй Цзянь</td>
          <td>Острые кончики из Тай-Пин</td>
          <td>Зеленый листовой чай</td>
        </tr>
        <tr>
          <td>太平猴魁</td>
          <td>Тай Пин Хоу Куй</td>
          <td>Главарь из Хоу-Кэна</td>
          <td>Зеленый листовой чай</td>
        </tr>
        <tr>
          <td>武夷龙条</td>
          <td>У И Лун Тяо</td>
          <td>Тело Дракона с гор У И</td>
          <td>Зеленый листовой чай</td>
        </tr>
        <tr>
          <td>芾蓉</td>
          <td>Фу Жун</td>
          <td>Лотосовый</td>
          <td>Зеленый листовой чай</td>
        </tr>
        <tr>
          <td>富溪贡品</td>
          <td>Фуси Гун Пинь</td>
          <td>Императорский чай с Ручья Счастья</td>
          <td>Зеленый листовой чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Хуа Ли Чжи</td>
          <td>Жасминовый Личи</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Хуа Лун Чжу</td>
          <td>Жасминовая жемчужина дракона</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Хуан Хуа Чжень</td>
          <td>Солнечные лучи</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>黄山白牡丹</td>
          <td>Хуан Шань Бай Му Дань</td>
          <td>Белый Пион с Желтых гор</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>黄山贡芽</td>
          <td>Хуан Шань Гун Я</td>
          <td>Отборные почки с гор Хуан-шань</td>
          <td>Зеленый игольчатый чай</td>
        </tr>
        <tr>
          <td>黄山毛峰</td>
          <td>Хуан Шань Мао Фэн</td>
          <td>Ворсистые пики горы Хуан-шань</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>极品竹叶青</td>
          <td>Цзи Пинь Чжу Е Цин</td>
          <td>Бамбуковые листья высшее качество</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>山毛尖</td>
          <td>Цзин Шань Мао Цзянь</td>
          <td>Ворсистые лезвия с гор Цзин Шань</td>
          <td>Зеленые чай</td>
        </tr>
        <tr>
          <td>君山绿牡丹</td>
          <td>Цзюнь Шань Люй Му Дань</td>
          <td>Зеленый пион с гор Цзюнь Шань</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>君山毛尖</td>
          <td>Цзюнь Шань Мао Цзянь</td>
          <td>Ворсистые лезвия с гор Цзюнь Шань</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>尖峰</td>
          <td>Цзянь Фэн</td>
          <td>Острый пик</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>尖毫</td>
          <td>Цзянь Хао</td>
          <td>Ворсистый клинок</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>雀舌</td>
          <td>Цюэ Шэ</td>
          <td>Воробьиные язычки</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>竹叶青</td>
          <td>Чжу Е Цин</td>
          <td>Бамбуковые листья</td>
          <td>Зеленый плоский чай</td>
        </tr>
        <tr>
          <td>珠兰茶</td>
          <td>Чжу Лань ча</td>
          <td>Магнолиевый чай</td>
          <td>Цветочный чай</td>
        </tr>
        <tr>
          <td>珠茶天坛</td>
          <td>Чжу ча «Тянь Тань»</td>
          <td>Скрученный чай «Храм Неба»</td>
          <td>Зеленый скрученный чай</td>
        </tr>
        <tr>
          <td>蒸青</td>
          <td>Чжэн Цин</td>
          <td>Пропаренный зелёный чай</td>
          <td>Зеленый пропаренный чай</td>
        </tr>
        <tr>
          <td>寿眉</td>
          <td>Шоу Мэй</td>
          <td>Реснички долголетия</td>
          <td>Зеленый игольчатый чай</td>
        </tr>
        <tr>
          <td>生态绿茶</td>
          <td>Шэн Тай Люй Ча</td>
          <td>Великородный зеленый чай</td>
          <td>Зеленый спиральный чай</td>
        </tr>
        <tr>
          <td>峨眉竹叶青</td>
          <td>Э Мэй Чжу Е Цин</td>
          <td>Бамбуковые листья с горы Эмэйшань</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>玉花</td>
          <td>Юй Хуа</td>
          <td>Дождевые цветы</td>
          <td>Зеленый скрученный чай</td>
        </tr>
        <tr>
          <td>玉环</td>
          <td>Юй Хуань</td>
          <td>Нефритовые кольца</td>
          <td>Зеленый скрученный чай</td>
        </tr>
        <tr>
          <td>玉尖</td>
          <td>Юй Цзянь</td>
          <td>Нефритовые лезвия</td>
          <td>Зеленый чай</td>
        </tr>
        <tr>
          <td>玉珠</td>
          <td>Юй Чжу</td>
          <td>Нефритовые жемчужины</td>
          <td>Зеленый скрученный чай</td>
        </tr>
        <tr>
          <td>玉坠</td>
          <td>Юй Чжуй</td>
          <td>Нефритовые подвески</td>
          <td>Зеленый скрученный чай</td>
        </tr>
        <tr>
          <td>玉针</td>
          <td>Юй Чжэнь</td>
          <td>Нефритовые иглы</td>
          <td>Зеленый игольчатый чай</td>
        </tr>
        <tr>
          <td>玉芽</td>
          <td>Юй Я</td>
          <td>Нефритовые почки</td>
          <td>Зеленый игольчатый чай</td>
        </tr>
        <tr>
          <td>云南宝焰牌</td>
          <td>Юннань Бао Янь Пай</td>
          <td>Драгоценное пламя из Юннани</td>
          <td>Прессованный зеленый чай</td>
        </tr>
        <tr>
          <td>峨眉竹叶青</td>
          <td>Эмей Цуэ Ше</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="tea-vid">
          <th>红茶</th>
          <th>Хун Ча</th>
          <th>Красный чай</th>
          <th></th>
        </tr>
        {/* <tr>
          <th>Иероглифы</th>
          <th>Имя чая</th>
          <th>Название чая</th>
          <th>Вид</th>
        </tr> */}
        <tr>
          <td>安徽祁红</td>
          <td>Ань Хуй Ци Хун</td>
          <td>Красный чай из Цимэнь</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>高极云南红茶</td>
          <td>Гао Цзи Юньнань Хун Ча</td>
          <td>Красный чай из Юннани высшего качесства</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Гаошань Хун Ча</td>
          <td>Высокогорный Красный</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Гун Фу Хун Ча</td>
          <td>Прессованный красный чай из Фудина</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Гуй Хуа Хун Ча</td>
          <td>Красный чай с османтусом</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>滇红</td>
          <td>Дянь Хун</td>
          <td>Красный чай из Дяньси</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>滇红毛峰</td>
          <td>Дянь Хун Мао Фэн</td>
          <td>Красный чай из Дяньси “Ворсистые Пики”</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>滇红金毫</td>
          <td>Дянь Хун Цзинь Хао</td>
          <td>Красный чай из Дяньси Золотые ворсинки</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>宜兴绿茶</td>
          <td>И Син Люй Ча</td>
          <td>Зеленый чай из Исин</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>宜兴红茶</td>
          <td>И Син Хун Ча</td>
          <td>Красный чай из Исин</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>银骏眉</td>
          <td>Инь Цзюнь Мэй</td>
          <td>Серебряные брови</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Кимун</td>
          <td>Кхимун</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>老松小锺</td>
          <td>Лао Сун Сяо Чжун</td>
          <td>Старая сосна с гор Чжэншань, королевский</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>冷香</td>
          <td>Лэнсян</td>
          <td></td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>荔枝红茶</td>
          <td>Ли Чжи Хун Ча</td>
          <td>Красный чай со вкусом плода личжи</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Мао Фэн Красный</td>
          <td></td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>玫瑰红茶</td>
          <td>Мэй Гуй Хун Ча</td>
          <td>Красный чай с розовым ароматом</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Най Сян Хун Ча</td>
          <td>Красный молочный чай</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>女儿滇红</td>
          <td>Ню Эр Дянь Хун</td>
          <td>Красный чай из Дянь си</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>松针</td>
          <td>Сун Чжень</td>
          <td>Сосновые Иглы</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Хун Бин Ло</td>
          <td>Красная спираль</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Хун Цзинь Я</td>
          <td></td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Хуан Цзин Ло</td>
          <td></td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>黑金</td>
          <td>Хэй Цзинь</td>
          <td>Черное золото</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>金滇红</td>
          <td>Цзинь Дянь Хун</td>
          <td>Золотой чай из Дянь си</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Цзинь Гуань Инь</td>
          <td></td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>滇红金毫</td>
          <td>Цзинь Хао Дянь Хун</td>
          <td></td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>金毫红茶</td>
          <td>Цзинь Хао Хун Ча</td>
          <td>Золотые ворсинки</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>金骏眉</td>
          <td>Цзин Цзюнь Мэй</td>
          <td>Золотые брови</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>正山老松</td>
          <td>Чжэн Шань Лао Сун</td>
          <td>Старая сосна с гор Чжэншань</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td>正山小锺</td>
          <td>Чжэн Шань Сяо Чжун</td>
          <td>Малые кусты с гор Чжэншань</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td></td>
          <td>Эмэй Цзинь Я</td>
          <td>Золотые Почки горы Эмэй</td>
          <td>Красный чай</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="tea-vid">
          <th>乌龙茶</th>
          <th>Улун Ча</th>
          <th>Улун</th>
          <th></th>
        </tr>
        {/* <tr>
          <th>Иероглифы</th>
          <th>Имя чая</th>
          <th>Название чая</th>
          <th>Вид</th>
        </tr> */}
        <tr>
          <td>安溪黄金桂</td>
          <td>Ань Си Хуан Цзинь Гуй</td>
          <td>Золотая корица из Анси</td>
          <td>Южно-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td></td>
          <td>Алишань</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>八仙</td>
          <td>Ба Сянь</td>
          <td>Восемь бессмертных</td>
          <td>Южно-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>白鸡冠</td>
          <td>Бай Цзи Гуань</td>
          <td>Белый петушиный гребень</td>
          <td>Северо-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>半天妖</td>
          <td>Бань Тянь Яо</td>
          <td>Полуденный дух</td>
          <td>Северо-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>本山</td>
          <td>Бэнь Шань</td>
          <td>Улун с горы Бэньшань</td>
          <td>Южно-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td></td>
          <td>Габа Алишань</td>
          <td></td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>桂花乌龙</td>
          <td>Гуй Хуа Улун</td>
          <td>Улун с ароматом цветов корицы</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>大红梅</td>
          <td>Да Хун Мэй</td>
          <td>Большая красная слива</td>
          <td>Северо-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>大红袍</td>
          <td>Да Хун Пао</td>
          <td>Большой красный халат</td>
          <td>Северо-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>冻顶</td>
          <td>Дун Дин</td>
          <td>Улун с Морозного Пика</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>东方美人</td>
          <td>Дун Фан Мэй Жэнь</td>
          <td>Восточная красавица</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>人参乌龙</td>
          <td>Жень Шэнь Улун</td>
          <td>Женьшеневый Улун</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td></td>
          <td>Лао Ча Ван</td>
          <td>Выдержанный улун</td>
          <td>Улун</td>
        </tr>
        <tr>
          <td></td>
          <td>Лао Те Гуаньинь</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>梨山</td>
          <td>Ли Шань</td>
          <td>Улун с горы Лишань</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>毛蟹</td>
          <td>Мао Се</td>
          <td>Волосатый краб</td>
          <td>Южно-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td></td>
          <td>Ми Сян</td>
          <td>Медовый улун</td>
          <td>Улун</td>
        </tr>
        <tr>
          <td>玫瑰乌龙</td>
          <td>Мэй Гуй У Лун</td>
          <td>Улун с ароматом цветов корицы</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>奶香金萱</td>
          <td>Най Сян Цзинь Сюань</td>
          <td>Огненный цветок с молочным ароматом (молочный улун)</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>水仙</td>
          <td>Светлый Шуй Сян</td>
          <td>Светлый Нарцисс</td>
          <td>Северо-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>西坪铁观音</td>
          <td>Си Пин Те Гуанинь</td>
          <td>Железная Гуанинь из деревни Си Пин</td>
          <td>Южно-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>松柏长</td>
          <td>Сун Бай Чжан</td>
          <td>У-Лун Сосны и Кипариса</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>西季春</td>
          <td>Сы Цзи Чунь</td>
          <td>Весна четырех сезонов</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>仙品王</td>
          <td>Сянь Пинь Ван</td>
          <td>Владыка чаев дающий бессмертие</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>小红袍</td>
          <td>Сяо Хун Пао</td>
          <td>Малый красный халат</td>
          <td>Северо-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>铁观音</td>
          <td>Те Гуаньинь</td>
          <td>Железная Бодисатва Гуанинь</td>
          <td>Южно-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>铁观音王</td>
          <td>Те Гуаньинь Ван</td>
          <td>Владыка Гуаньинь</td>
          <td>Южно-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>铁萝汉</td>
          <td>Те Лохань</td>
          <td>Железный архат</td>
          <td>Северо-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>特选高山</td>
          <td>Те Сюань Гао Шань</td>
          <td>Избранный чай с высоких гор</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>武夷八仙</td>
          <td>У И Ба Сянь</td>
          <td>Восемь бессмертных с гор У И</td>
          <td>Северо-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>武夷肉桂</td>
          <td>У И Жоу Гуй</td>
          <td>Корица с горы У И</td>
          <td>Северо-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>武夷黄奇</td>
          <td>У И Хуан Ци</td>
          <td>Желтое чудо (неожиданность) с гор У И</td>
          <td>Северо-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>武夷水仙</td>
          <td>У И Шуй Сянь</td>
          <td>Нарцисс с горы У И</td>
          <td>Северо-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>雾社茶</td>
          <td>У Шэ Ча</td>
          <td>Пристанище туманов</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>凤凰单枞</td>
          <td>Фэн Хуан Дань Цун</td>
          <td>Одинокие кусты с горы Фэн Хуан</td>
          <td>Гуаньдунский улун</td>
        </tr>
        <tr>
          <td>黄金桂</td>
          <td>Хуан Цзинь Гуй</td>
          <td>Золотая корица</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>金萱</td>
          <td>Цзинь Сюань</td>
          <td>Огненный цветок</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>金钥匙</td>
          <td>Цзинь Яо Ши</td>
          <td>Золотой ключик</td>
          <td>Северо-фуцзяньский материковый улун</td>
        </tr>
        <tr>
          <td>翠玉</td>
          <td>Цуй Юй</td>
          <td>Драгоценная яшма</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td></td>
          <td>Черный Те Гуаньинь</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>陈年老茶</td>
          <td>Чэнь Нянь Лао Ча</td>
          <td>Выдержанный годами старый чай</td>
          <td>Выдержанный тайваньский улун</td>
        </tr>
        <tr>
          <td>杉林溪</td>
          <td>Шань Линь Си</td>
          <td>Ручей горного леса</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>手摘桂花乌龙</td>
          <td>Шоу Чжай Гуй Хуа Улун</td>
          <td>Улун с ароматом цветов корицы</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>玉桂</td>
          <td>Юй Гуй</td>
          <td>Нефриовая корица</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>玉露</td>
          <td>Юй Лу</td>
          <td>Нефритовая роса</td>
          <td>Тайваньский улун</td>
        </tr>
        <tr>
          <td>云雾</td>
          <td>Юнь У</td>
          <td>Облачный туман</td>
          <td>Тайваньский улун</td>
        </tr>
        {/* <tr>
          <th>Иероглифы</th>
          <th>Имя чая</th>
          <th>Название чая</th>
          <th>Вид</th>
        </tr> */}
        <tr>
          <td>苦丁</td>
          <td>Ку Дин</td>
          <td>Кудин</td>
          <td>Нечайный чай</td>
        </tr>
        <tr>
          <td>苦丁水修</td>
          <td>Ку Дин Шуй Сю</td>
          <td>Вода с чистых гор, приводящая в порядок</td>
          <td>Нечайный чай</td>
        </tr>
        <tr>
          <td>苏州苦丁</td>
          <td>Су Чжоу Ку Дин</td>
          <td>Кудин из Сучжоу</td>
          <td>Нечайный чай</td>
        </tr>
        <tr>
          <td>云南普洱茶</td>
          <td>Юннань Пуэр ча</td>
          <td>Чай из Пуэра</td>
          <td>Черный чай</td>
        </tr>
        <tr>
          <td>云南沱茶</td>
          <td>Юннань То ча</td>
          <td>Юннаньский чай чаша</td>
          <td>Прессованный зеленый чай</td>
        </tr>
        <tr>
          <td>云南古老普洱</td>
          <td>Юньнань Гу Лао Пуэр</td>
          <td>Старый юннаньский пуэр</td>
          <td>Прессованный черный чай</td>
        </tr>
        <tr>
          <td>云南宫廷普洱</td>
          <td>Юньнань Гун Тин Пуэр</td>
          <td>Дворцовый пуэр</td>
          <td>Прессованный черный чай</td>
        </tr>
        <tr>
          <td>云南野生普洱</td>
          <td>Юньнань Е Шэн Пуэр</td>
          <td>Дикорастущий пуэр</td>
          <td>Прессованный черный чай</td>
        </tr>
        <tr>
          <td>云南 宫廷普洱</td>
          <td>Юньнань Менхай Гун Тин Пуэр</td>
          <td>Дворцовый пуэр из Менхая</td>
          <td>Прессованный черный чай</td>
        </tr>
        <tr>
          <td>云南普洱野生老茶</td>
          <td>Юньнань Пуэр Е Шэн Лао Ча</td>
          <td>Дикорастущий старый пуэр</td>
          <td>Прессованный черный чай</td>
        </tr>
        <tr>
          <td>云南普洱迷你饼</td>
          <td>Юньнань Пуэр Ми Ни Бин</td>
          <td>Очаровывающий юньнаньский пуэр (в листьях бамбука)</td>
          <td>Прессованный черный чай</td>
        </tr>
        <tr>
          <td>云南普洱沱茶</td>
          <td>Юньнань Пуэр То Ча</td>
          <td>Пуэр в форме чаши</td>
          <td>Прессованный черный чай</td>
        </tr>
        <tr>
          <td>云南普洱金瓜贡</td>
          <td>Юньнань Пуэр Цзинь Гуа Гун</td>
          <td>Пуэр золотая тыква</td>
          <td>Прессованный черный чай</td>
        </tr>
        <tr>
          <td>云南下关绿茶</td>
          <td>Юньнань Ся Гуань Люй Ча</td>
          <td>Зеленый чай из Сягуаня</td>
          <td>Прессованный зеленый чай</td>
        </tr>
        <tr>
          <td>云南香竹普洱</td>
          <td>Юньнань Сян Чжу Пуэр</td>
          <td>Юньнаньский пуэр с ароматом бамбука (в бамбуке)</td>
          <td>Прессованный черный чай</td>
        </tr>
        <tr>
          <td>云南七子饼茶普洱</td>
          <td>Юньнань Ци Цзы Бин Ча Пу Эр</td>
          <td>Знаменитая лепешка из Юннани</td>
          <td>Прессованный черный чай</td>
        </tr>
        <tr>
          <td>云南山陈普洱</td>
          <td>Юньнань Шань Чэнь Пуэр</td>
          <td>Юннаньский выдержанный пуэр</td>
          <td>Прессованный черный чай</td>
        </tr>
      </tbody>
    </table>
  );
};

export default NamesTable;
