import React from 'react';

const chayeguan = {
  id: 'chayeguan',
  image: '/img/stuff/chayeguan.png',
  name: 'Баночка для чая',
  chiName: '茶叶罐',
  ruName: 'чаегуань',
  say: 'cháyèguàn',
  content: (
    <React.Fragment>
      <p>
        Чаегуань, или чайница, предназначена для хранения чая. Чайницы производят из различных материалов – металла,
        стекла, дерева, картона, фарфора. Главное, чтобы чайная баночка была не прозрачна, поскольку солнечный свет
        разрушает микроэлементы в чайном листе.{' '}
      </p>
      <picture>
        <img src="/img/stuff/chayeguan_opt/chayeguan1_1280.jpg" alt="" />
        {/* <img src="/img/stuff/gaiwan_opt/gaiwan3_1280.jpg" alt="" /> */}
      </picture>
      <p>
        В древние времена в коробочки для хранения чая иногда клали куски древесного угля, завернутые в чистую ткань,
        которые действовали как абсорбент влаги.
      </p>
    </React.Fragment>
  ),
};

export default chayeguan;
