import React from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';

import locations from '../data/locations';
import { basic, listVariants, itemVariants } from '../data/framerVars';

import { ReactComponent as ProvMap } from '../svg/chinaprovince.svg';

import s from './Locations.module.css';
import sh from './share.module.css';

import FamilyCard from '../components/FamilyCard';
import Tooltip from '../components/Tooltip';

const highlight = (id) => {
  const el = document.querySelector(`path#${id}`);
  if (el) el.classList.add('hover');
};

const unhighlight = (id) => {
  const el = document.querySelector(`path#${id}`);
  if (el) el.classList.remove('hover');
};

const Locations = () => {
  const [highlighted, setHighlighted] = React.useState(null);
  const [tooltip, setTooltip] = React.useState(null);
  const [zoom, setZoom] = React.useState(false);
  const [active, setActive] = React.useState(false);

  const mapRef = React.useRef();

  // React.useEffect(() => {
  //   if (mapRef.current) {
  //     const paths = mapRef.current.querySelectorAll('path.tea');

  //     paths.forEach((p) => {
  //       let timer;

  //       p.addEventListener('mouseenter', (e) => {
  //         clearTimeout(timer);
  //         const id = e.target.getAttribute('id');
  //         // if (id) setHighlighted(id);
  //         if (id) {
  //           timer = setTimeout(() => {
  //             // e.target.classList.add('hover');
  //             const coords = e.target.getBoundingClientRect();
  //             const info = locations.find((el) => el.id === id);
  //             const locEl = document.querySelector(`#location-${id}`);

  //             setHighlighted(id);
  //             setTooltip(
  //               <Tooltip
  //                 y={coords.top + coords.height / 2 + window.scrollY}
  //                 x={coords.left + coords.width / 2 + window.scrollX}
  //                 data={info}
  //                 onClick={() => {
  //                   setTooltip(null);
  //                   // setZoom(true);
  //                 }}
  //               />
  //             );
  //             if (locEl) locEl.scrollIntoView({ behavior: 'smooth' });
  //           }, 400);
  //         }
  //       });
  //       p.addEventListener('mouseleave', (e) => {
  //         console.log(e.target);
  //         clearTimeout(timer);
  //         // e.target.classList.remove('hover');
  //         // setZoom(false);
  //         // setHighlighted(null);
  //       });
  //     });
  //   }
  // }, []);

  return (
    <React.Fragment>
      <motion.div variants={basic} className={s.Wrap}>
        <motion.section
          className={classNames([sh.PageWrapper, s.Locations])}
          initial="initial"
          animate="enter"
          exit="exit"
          variants={listVariants}
        >
          {locations.map((loc) => {
            return (
              <FamilyCard
                key={loc.id}
                id={`location-${loc.id}`}
                className={`${s.LocationCard}`}
                to={`/location/${loc.id}`}
                elem={loc}
                variants={itemVariants}
                onMouseEnter={() => highlight(loc.id)}
                onMouseLeave={() => unhighlight(loc.id)}
              />
            );
          })}
        </motion.section>
        {/* <motion.div
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          className={`${s.Map} ${zoom && s.zoom}`}
        >
          <ProvMap ref={mapRef} style={{ transformOrigin: '90% 90%' }} />
          {tooltip}
        </motion.div> */}
      </motion.div>
    </React.Fragment>
  );
};

export default Locations;
