import React from 'react';

const fujian = {
  id: 'fujian',
  image: '/img/locations/fujian.jpg',
  name: 'Фуцзянь',
  chiName: '福建',
  ruName: '',
  say: 'Fújiàn',
  teas: [
    { name: 'Бай Хао Инь Чжень' },
    { name: 'Бай Му Тан' },
    { name: 'Да Хун Пао' },
    { name: 'У И Фо Шоу' },
    { name: 'У И Ци Лань' },
    { name: 'Мо Ли Хуа Ча' },
    { name: 'Ли Чжи Ча' },
    { name: 'Ань Си Те Гуань Инь' },
    { name: 'Ань Си Хуан Цзинь Гуй' },
    { name: 'Жэнь Шень Улун' },
    { name: 'Тань Ян Гун фу' },
    // {name:'Юй Чжу'},
    // {name:'Сюе Хуа'},
    // {name:'Бай Юй Ту'},
    // {name:'Е Е Сян'},
    // {name:'У И Лун Тяо'},
    // {name:'Шен Тай Люй Ча'},
  ],
  content: (
    <React.Fragment>
      <p>
        Провинция Фуцзянь была основана в период династии Тан, в 733 году, на месте древнего царства Юе. Сокращенное
        название – Минь, по названию одного из племен, обитавшего здесь во времена легендарных доисторических
        императоров.
      </p>
      <p>
        Фуцзянь расположена на восточном побережье Китая, между провинциями Чжэцзян и Гуандун. Это одна из самых
        населенных провинций Китая. Столица – город-порт Фучжоу. По своему значению в экономике и истории региона он
        соперничает со знаменитым портом Сямэнь, откуда был отправлен первый груз с чаем в Европу в 1601 году.
      </p>
      <p>
        Жители Фуцзяни говорят на множестве местных диалектов, из которых наиболее распространенными являются
        южно-фуцзяньский и северо-фуцзяньский. Они очень сильно отличаются от официального китайского языка. Например,
        слово «чай» на одной из местных версий южно-фуцзяньского диалекта, распространённой в г. Сямэнь, звучит как
        «тэ». Поэтому в западных языках наш любимый напиток звучит не так, как в русском: русские купцы общались в
        основном с торговцами из Северного Китая, где говорят на диалектах, близких к официальному языку.
      </p>
      <p>
        В Фуцзяни чай называют «чайным зерном», подчеркивая его огромную важность для местного хозяйства. Его начали
        выращивать здесь ещё до Лу Юя, в «Чайном каноне» имеются упоминания о фуцзяньском чае. Чай выращенный в уезде
        Бэй Юань (район гор Уишань), в 10 веке стал податным. Тогда его производили по технологии, в целом описанной ещё
        Лу Юем: свежие листья пропаривали, толкли, набивали в формы и высушивали над горячим пеплом. Этот чай перед
        употреблением нужно было размалывать, после чего его либо варили, либо взбивали венчиком, как это сейчас делают
        с японским чаем матча. Большой вклад в продвижение другого чайного региона Фуцзяни, нынешнего уезда Фудин, внес
        придворный каллиграф Цай Сян, который в своем труде «Записки о чае» (Ча Лу, 1049-53 гг.) прославил фудинский
        «белый чай» и предложил правила «чайных состязаний». Благодаря ему Фуцзянь можно назвать также родиной культуры
        сравнительной дегустации и титестерского дела.
      </p>
      <p>
        В 1395 году вскоре после установления династии Мин был издан эдикт, согласно которому прессованный чай
        исключался из списка податных товаров, вместо него производители должны были поставлять рассыпной чай из почек.
        Это решение сильно повлияло на развитие чайной отрасли Фуцзяни, в частности Уишань на долгие 150 лет потеряла
        значение как чаепроизводящий район, т.к. местные крестьяне так и не научились делать чай из типсов надлежащего
        качества. Однако в самом конце 16 века все изменилось с изобретением «черного чая» У Ча, он же Сяо Чжун, он же
        Сушонг. Именно он был вывезен голландцами из Сямэня в 1601 году и получил высокую оценку при дворе Оранских,
        получив звучное название Orange Pekoe (Pekoe искаженное северофуцзяньское «байхао», т.е. все выражение можно
        перевести как «оранский байховый»). Благодаря торговле сначала с голландцами, а потом с англичанами, район гор
        Уи вновь стал процветающим чаепроизводящим регионом. В 18 веке была создана разновидность У Ча, которая
        полюбилась уже и китайским чайным гурманам, и получила название Улун (черно-драконовый чай). Горы Уи стали
        местом паломничества китайской интеллигенции, которая щедро украшала скалы вокруг чайных садов стихами,
        посвященными местным пейзажам и улунам.
      </p>
      <p>
        В 1395 году вскоре после установления династии Мин был издан эдикт, согласно которому прессованный чай
        исключался из списка податных товаров, вместо него производители должны были поставлять рассыпной чай из почек.
        Это решение сильно повлияло на развитие чайной отрасли Фуцзяни, в частности Уишань на долгие 150 лет потеряла
        значение как чаепроизводящий район, т.к. местные крестьяне так и не научились делать чай из типсов надлежащего
        качества. Однако в самом конце 16 века все изменилось с изобретением «черного чая» У Ча, он же Сяо Чжун, он же
        Сушонг. Именно он был вывезен голландцами из Сямэня в 1601 году и получил высокую оценку при дворе Оранских,
        получив звучное название Orange Pekoe (Pekoe искаженное северофуцзяньское «байхао», т.е. все выражение можно
        перевести как «оранский байховый»). Благодаря торговле сначала с голландцами, а потом с англичанами, район гор
        Уи вновь стал процветающим чаепроизводящим регионом. В 18 веке была создана разновидность У Ча, которая
        полюбилась уже и китайским чайным гурманам, и получила название Улун (черно-драконовый чай). Горы Уи стали
        местом паломничества китайской интеллигенции, которая щедро украшала скалы вокруг чайных садов стихами,
        посвященными местным пейзажам и улунам.
      </p>
      <p>
        В 17 веке также произошло событие, которые впоследствии оказало большое влияние на мировую чайную культуру:
        остров Тайвань был присоединен после изгнания оттуда голландских оккупантов. После присоединения туда
        устремились переселенцы из Фуцзяни, среди которых было немало чаеводов, делавших улуны и красные чаи. На острове
        оказались очень хорошие условия для чаеводства, однако вплоть до 1863 года прямой экспорт чая из Тайваня был под
        запретом, и местный чай поступал в полном объёме в Сямэнь для дальнейшего вывоза за границу.
      </p>
      <p>
        После падения последней китайской династии Цин (1644-1911 г) наступил длительный период смуты, которая повлияла
        на спрос на улунские сорта, а в 1917 году случилась Октябрьская революция, которая почти на 20 лет положила
        конец чайной торговле с Китаем. В 1930е годы в Фучжоу правительство Китайской республики основало первое в
        истории страны учебное заведение, где изучалось чаеводство. Выпускники этого вуза положили начало современному
        чаеводству в стране. Самые известные: У Цзюенун, директор первого в Уишане экспериментального производства, где
        ручной труд по изготовлению улунов и черных чаев был частично механизирован за счёт импортных роллеров и
        сушилок. Он также автор фундаментальных трудов, которые служат настольными книгами для современных чаеводов
        Китая.
      </p>
      <p>
        В 1970-е годы началось возрождение чайной культуры на Тайване, и запущены в серию самые известные на сегодняшний
        день сорта тайваньских улунов. В 1979 году в КНР была провозглашена политика реформ и открытости, благодаря
        которой между Тайванем и материком начались торговые контакты. В 1991 году тайваньский чаеторговец Ли Жуйхэ, чьи
        предки так же переехали на Тайвань из уезда Чжанжоу (недалеко от Фучжоу) открыл первый магазин тайваньского чая
        в материковом Китае, конечно же в провинции Фуцзянь. С этого момента началось возрождение чайной культуры в
        материковом Китае.
      </p>
      <p>
        Благодаря магазинам «Тяньфу» китайская публика познакомилась с тайваньскими сортами, тайваньской чайной
        эстетикой, а поскольку в стране начался бурный рост уровня жизни, спрос на качественный чай тайваньского уровня
        не заставил себя ждать. Тайваньские инвесторы и специалисты стали возвращаться в Чжанчжоу, Аньси, Уишань, и
        способствовали возрождению высоких стандартов чаеводства и распространению моды на утонченное чаепитие.
      </p>
      <h3>Уишань</h3>
      <p>
        Город Уишань (武夷山, Wǔyí Shān) расположен на северо-западе провинции Фуцзянь. Название города состоит из трёх
        иероглифов - У (武), И (夷), и Шань (山), первые два иероглифа - это имя собственное, третий означает "гора".
        Согласно легенде о появлении названия города, именно тут прародитель китайской нации Пэн Цзу примирил своих
        сыновей Пэн У и Пэн И, по имени которых и был назван город – У И.
      </p>
      <p>
        В наши дни горы Уишань –заповедная зона. К ней относят 36 горных вершин, 99 скал и реку «девяти изгибов»
        Цзюцюси.
      </p>
      <p>
        Местные жители также говорят, что "уишаньский чай обладает обликом нищего, престижем императора и душой
        божества".
      </p>
      <p>
        Уишань поистине являются чайными горами в прямом смысле этого слова. На выгравированных на камнях наскальных
        надписях, которые здесь встречаются повсюду, Вы можете найти слова из знаменитого стихотворения Фан Чжунъяня,
        посвящённого чаю: «Из года в год весна приходит к нам с юго-востока, сперва теплеют воды в реке Цзяньси, мороз
        ослабевает и уходит прочь. Диковинный чай, растущий по берегам горного ручья, венчает Поднебесную, он
        превосходит все другие чаи, в горах Уи их с древних времён выращивают святые отшельники».
      </p>
      <p>
        В 479 году н.э., в эпоху Южных и Северных династий, чай, который выращивался на территории горного массива
        Уишань, стал известен под именем Вань Гань Хоу – Князь поздней сладости (ведь настой уишаньского чая оставлял во
        рту сладковатый привкус). В период правления династий Тан и Сун в этих местах производили так называемый бин ча
        – чай в форме лепёшки, а при династиях Сун и Юань чай с гор Уишань был удостоен титула гун ча – лучший отборный
        чай, достойный поднесения в дань императорскому двору. Ближе к концу Минской эпохи и в самом начале царствования
        династии Цин здесь стали изготавливать рассыпной чай. Вскоре также появились красный чай и полуферментированные
        улуны. В XVII веке все чаи, которые импортировались в Великобританию из Китая, получили название «Bohea»
        («разновидность Уи»).
      </p>
      <p>
        Во время сбора чая – а происходит трижды в год – весной, летом и осенью – можно наблюдать поистине неповторимое
        зрелище. Горы Уи и их окрестности наводняются бесконечной чередой сборщиц чая с бамбуковыми корзинами за
        плечами. Это самая важная пора для обитателей здешних мест. Женщины порой работают по 24 часа в сутки, чтобы
        успеть собрать тысячи килограмм чайных листочков.
      </p>
      <p>
        Для изготовления утесных чаев с чайных деревьев после образования конечной почки собирают три-четыре верхних
        листочка (когда самый первый листочек уже распрямился, но по размеру он в 1.5 раза меньше второго листочка).
        Лучшим по качеству считается чай весеннего сбора (конец апреля – первая половина мая), за ним идет осенний и
        только потом летний чай.
      </p>
    </React.Fragment>
  ),
};

export default fujian;
