import React from 'react';
import { /* NavLink, */ useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { HashLink as Link } from 'react-router-hash-link';

import data from '../data/data';
// import { basic, listVariants, itemVariants } from '../data/framerVars';

import s from './Tea.module.css';
import sh from './share.module.css';

// import { ReactComponent as BackIcon } from '../svg/back.svg';
import TasteChart from '../components/TasteChart2';
import CookBlock from '../components/CookBlock';

const basic = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
  exit: { opacity: 0 },
};

const imgVars = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0, transition: { delay: 0.5 } },
  exit: { opacity: 0, x: 100 },
};

const infoVars = {
  initial: { opacity: 1, y: 0 },
  animate: { opacity: 1, y: 0, transition: { delay: 0.2, staggerChildren: 0.15 } },
  exit: { opacity: 0, y: 50 },
};

const contVars = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

const SubHeader = (props) => {
  const { chiName, ruName } = props;
  return (
    <h3 className={s.SubHeader}>
      <span className={s.SubChi}>{chiName} • </span>
      <span className={s.SubRu}>{ruName}</span>
    </h3>
  );
};

const Tea = () => {
  const params = useParams();
  const family = data.find((el) => el.id === params.familyId);
  const { teaList } = family;
  const tea = teaList.find((el) => el.id === params.teaId);

  return (
    <motion.section
      className={classNames([sh.PageWrapper, s.Tea])}
      variants={basic}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {/* <motion.div variants={contVars} className={s.Back}>
        <NavLink to={`/family/${params.familyId}`}>
          <BackIcon />
        </NavLink>
      </motion.div> */}
      <div className={s.Grid}>
        <motion.div variants={infoVars} className={s.Info}>
          <motion.div
            variants={contVars}
            className={s.chiName}
            style={{ marginLeft: tea.headerMargin ? `-${tea.headerMargin}px` : 0 }}
          >
            {tea.chiName}
          </motion.div>
          <motion.div variants={contVars} className={s.Name}>
            <h2 className={s.h2}>{tea.enName}</h2>
            <h2 className={s.h2}>{tea.name}</h2>
            <div className={s.ruName}>{tea.ruName}</div>
            {/* {tea.enName} ∙ {tea.name} ∙ {tea.ruName} */}
          </motion.div>
          <motion.div variants={contVars} className={s.Location}>
            {tea.locationLink ? <Link to={tea.locationLink}>tea.location</Link> : tea.location}
          </motion.div>
          <motion.div variants={contVars} className={classNames([s.Mobile])}>
            <div className={classNames([s.ImageWrap])}>
              <img src={tea.image} alt="" />
            </div>
          </motion.div>
          <motion.div variants={contVars} className={s.Taste}>
            {tea.taste && <TasteChart taste={tea.taste} />}
            <CookBlock tea={tea} />
          </motion.div>
          {tea.legend && (
            <motion.div variants={contVars} className={s.Legend}>
              <SubHeader chiName="传说" ruName="Легенда" />
              {tea.legend}
            </motion.div>
          )}
          {tea.history && (
            <motion.div variants={contVars} className={s.History}>
              <SubHeader chiName="故事" ruName="История" />
              {tea.history}
            </motion.div>
          )}
          <motion.div variants={contVars} className={s.HowTo}>
            <SubHeader chiName="食谱" ruName="Приготовление" />
            {tea.howto.text}
          </motion.div>
        </motion.div>
        <motion.div variants={imgVars} className={classNames([s.Center, s.UnMobile])}>
          <div className={s.ImageWrap}>
            <img src={tea.image} alt="" />
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Tea;
