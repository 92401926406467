import React from 'react';
import { motion } from 'framer-motion';

import s from './TeaTypeBlock.module.css';
import TeaHeader from './TeaHeader';

import { itemVariants } from '../data/framerVars';

// const thumsbVariants = {
//   initial: { x: -20, rotate: -80, opacity: 0 },
//   enter: { x: 0, opacity: 1, rotate: 0 },
//   exit: { x: -20, rotate: -80, opacity: 0 },
// };

const thumsbVariants = {
  initial: { x: -10, opacity: 0 /* y: 40 */ },
  enter: { x: 0, opacity: 1 /* y: 0 */ },
  exit: { x: -10, opacity: 0 /* y: 40 */ },
};

const TeaTypeBlock = (props) => {
  const { data } = props;
  return (
    <motion.li
      variants={itemVariants}
      className={s.TeaTypeItem}
      // style={{ marginBottom: data.mountain ? `${data.mountain}px` : 0 }}
    >
      <motion.span variants={thumsbVariants} className={s.TeaPreview}>
        <img src={data.preview} alt="" />
      </motion.span>
      <TeaHeader data={data} />
    </motion.li>
  );
};

export default TeaTypeBlock;
