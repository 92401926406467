import React from 'react';

const hunan = {
  id: 'hunan',
  image: '/img/locations/hunan.jpg',
  name: 'Хунань',
  chiName: '湖南',
  ruName: '',
  say: 'Húnán',
  teas: [{ name: 'Цзюнь Шань Инь Чжэнь' }, { name: 'Сун Чжэнь' }, { name: 'Цзюнь Шань Мао Цзянь' }],
  content: (
    <React.Fragment>
      <p>
        «К югу от озера» — так переводится название провинции Хунань. Озеро называется Дунтинху и почитаемо во всей
        стране как священное место.
      </p>
      <p>
        По данным археологов Китая, первые стоянки человека появились на территории провинции около 10 000 лет назад, а
        предки современных народностей мяо, туцзя, донг и яо поселились здесь примерно 8000 лет назад. Они занимались
        рыболовством, практиковали подсечно-огневое земледелие — вся территория нынешней провинции в те времена была
        покрыта густыми пущами. Согласно мифологии Китая, все его люди происходят от бога солнца и лета, повелителя Юга
        и пламени, по имени Яньди (Шэньшунь), и его брата Хуанди. Это Яньди, согласно мифам, научил китайцев
        обрабатывать землю, ткать полотно, делать сосуды из глины и вообще — работать с большим тщанием.
      </p>
      <p>
        Считается, что Яньди был захоронен на земле Хунани, около деревни Линь в уезде Яньлин около 5000 лет назад
        (мавзолей Яньди был возведен при династии Хань (206 г. до н. э. — 220 г. н. э.). Описываемая история Хунани
        начинается около 350 г. до н. э., когда эта земля находилась в пределах владений династии Чжоу (около 1100-256
        гг. до н. э.). Династии Восточная Чжу Хунань подчинялась в «Период весен и осеней» (также «Период Чуньцю», или
        «Чу», 722-481 гг. до н. э.) и в «Период Сражающихся царств» (475-221 гг. до н. э.). В конце правления династии
        Восточная Цинь (221-206 гг. до н. э.), и особенно во время династии Хань, сюда хлынули из Центрального Китая
        племена народа хань — опытные земледельцы и искусные ремесленники. Коренные жители Хунани были оттеснены в горы,
        на юг и юго-запад, где они, в основном, обитают и сегодня.
      </p>
      <p>
        Столица и самый крупный город Хунани Чанша был известен уже во времена династий Чу и Восточная Цинь. Хунань как
        территория (вместе с территорией Хубэй) стала известна в конце правления династии Восточная Цинь как южная часть
        провинции Хугуань. Провинция Хунань образовалась, по одним данным — в 1664 г., по другим — в 1668 г., во время
        правления маньчжурской династии Цин (1644-1911/12 гг.).
      </p>
      <p>
        Благодаря своей близости к реке Янцзы и собственной естественной гидросистеме, а также Императорской дороге
        между Севером и Югом, в XVIII в. Хунань становится узлом китайских коммуникаций и одним из самых крупных
        производителей и экспортеров риса в стране. К началу XIX в. в Хунани одно за другим вспыхивают крестьянские
        восстания, поводом для которых часто являлись перенаселенность и конфликты с маньчжурской администрацией. В 1850
        г. в провинции Гуанси начинается крестьянская воина таипинов (слово «тайпин» означает «великое спокойствие»),
        вскоре охватившая и Хунань. Восстание было жестоко подавлено в 1864 г. под руководством уроженца Хунани по имени
        Цзэн Гофай. Однако Чаншу ему так и не удалось взять.
      </p>
      <p>
        В 1910 г. в Хунани, как и в других районах страны, вновь вспыхивает восстание против власти династии Цин. В 1912
        г. император Пуи отрекся от престола, и была провозглашена Китайская Республика. Хунань в этот период, до 1949
        г., когда коммунисты окончательно взяли власть в Китае, не знала спокойствия. Недолго провинция была
        коммунистической республикой в 1927 г. в результате восстания «Осенний урожай», которое возглавил уроженец
        Хунани Мао Цзэдун (1893-1976 гг.). И даже под властью гоминьдановцев в 1927-1949 гг. в провинции существовало
        несколько «красных» партизанских опорных баз. В 1937-1945 гг. значительная часть территории Хунани была
        оккупирована японскими войсками.
      </p>
      <p>
        В годы культурной революции (1966-1976 гг.) Хунань поддерживала Мао, но не слишком активно, гораздо более
        пришлись по душе прагматичной части хунаньского общества реформы Дэн Сяопина (1904-1997 гг.). Ныне провинция
        Хунань — развитый индустриальный и аграрный регион Китая. В августе 2013 г. в Чанше началось строительство
        838-метрового 220-этажного здания «Небесный город». Когда оно войдет в строй, это будет самый высокий небоскреб
        в мире. Провинция Китая Хунань располагается к югу от средней части бассейна реки Янцзы, точнее говоря, по ее
        территории протекают четыре крупнейших водных потока этого бассейна: реки Сянцзян (прямой приток Янцзы), Цзышуй,
        Юаньцзян и Лишуй. 51,2% площади провинции занимают горы, 13,9% — котловины, 13,1% — равнины, 15,4% — холмы. В
        центральной части провинции возвышенности и котловины часто перемежаются друг с другом на довольно близком
        расстоянии, создавая неповторимый пейзажный рельеф. С северо-запада провинцию обрамляют горные цепи Улинъюань и
        Сюесюй на западе, Наньлинь — на юге, Лосяошань — на востоке. На севере расположено озеро Дунтинху и его равнина.
        С востока на запад провинция Хунань протянулась на 667 км, с севера на юг — на 774 км.
      </p>
      <p>
        «Поднебесная» означает буквально «земля, над которой властен китайский император», в широком смысле — Китай как
        таковой. Это и сегодня — второе имя страны. А провинцию Хунань часто называют «Небесными вратами». И не только
        из-за склонности китайцев мыслить и выражать свои мысли метафорично. Природа подарила Хунани редкостную
        величественность и красоту ландшафтов, существующих друг с другом в полной гармонии. Знаменитая ландшафтная зона
        провинции — Улинъюань, с ее скалистыми рельефами, ручьями, пещерами, термальными источниками, реликтовыми
        лесами, включающая в себя районы Чжанцзяцзе, Сосиюй, гору Тяньмэньшань и реку Мэндун-хэ, — внесена ЮНЕСКО в
        список Всемирного природного наследия. Название Тяньмэньшань переводится как «Небесные врата». Самая
        примечательная часть горы — огромный провал размером 137×57 м, образовавшийся много веков назад, когда из горы,
        вероятно во время землетрясения, вывалился огромный массив породы. Вертикальные скалы, края провала, словно
        обрамляющие большой сегмент неба, и дали горе название. Вид, который открывается с вершины на другие горы и
        долины, на серпантин дороги с 99 поворотами, — это поистине духоподъемное место, в прямом и переносном смысле.
        Подняться туда можно на фуникулере, но по местным понятиям правильнее сделать это самостоятельно, преодолев 999
        ступеней. Цифра 9 здесь не случайна: при произнесении по-китайски она становится омофоном, то есть звучит так
        же, как слова «вечный, долгий», а подобные символы в китайской культуре имеют необычайно важное значение.
      </p>
      <p>
        В Хунани свыше 20 тысяч памятников культуры и «пейзажных достопримечательностей» (по китайской терминологии).
        Все они маркируются буквами латинского алфавита. Буква «А» (и цифра 4) символизирует самую высокую степень
        ценности чуда природы. В Хунани под категорию 4А подпадают многие природные достопримечательности, самые
        известные из которых, кроме ландшафтной зоны Улинъюань, — горы Хэншань и Шаошань. На горе Хэншань, или Наньюэ
        (Хэншань означает «Гора равновесия», «Наньюэ» — «Южный пик»), а это на самом деле — горная цепь длиной 150 км,
        высший пик которой — 1300 м, расположены даосские храмы и буддийские монастыри, множество камней имеют надписи с
        посвящениями от императоров, знаменитых ученых и поэтов.
      </p>
      <p>
        Если Хэншань — одна из пяти священных гор Китая, то озеро Дунтинху (Дунчин) — одно из пяти священных озер и
        второе по площади в стране (после Поянху). Считается, что гонки на лодках-драконах, столь любимые китайцами,
        впервые стали проводиться здесь в память о поэте и министре Цюй Юане (340-278 гг. до н. э.), утопившемся в одном
        из притоков озера, будучи не в силах перенести порабощение родины в «Период Сражающихся царств». В последние 20
        лет для здешних мест актуальнее не поэзия, а такая проза, как загрязнение: из-за промышленных выбросов более 100
        бумажных фабрик озеро терпит экологическое бедствие, здесь стало меньше рыбы, водоплавающих птиц, бесперых
        морских свиней, а речной дельфин уже исчез. В 2005 г. был запущен рассчитанный на 10 лет китайско-норвежский
        проект по восстановлению и защите местной биосферы: по нему площадь озера расширяется, одновременно укрепляются
        его берега.
      </p>
      <p>
        Подземные недра Хунани богаты, здесь разведаны месторождения 141 вида полезных ископаемых, из них 37 видов — это
        руды цветных металлов. Провинция занимает первое место в мире по запасам сурьмы и лидирующее в стране по запасам
        вольфрама, висмута, монацита; значительны здесь также залежи свинца и цинка. Добываются полевой шпат, барит,
        сепиолит. Уголь и значительные гидроресурсы обеспечивают энергетику провинции. Ее территория электрифицирована
        более чем на 90%.
      </p>
      <p>
        В Китае жители провинции Хунань слывут чрезмерно эмоциональными и азартными. Именно потому, согласно этой
        логике, там появились на свет Мао Цзэдун и другие революционеры-коммунисты, а также многие знаменитые артисты
        китайской оперы.
      </p>
    </React.Fragment>
  ),
};

export default hunan;
