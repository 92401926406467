const MengdingHuangya = {
  id: 'MengdingHuangya',
  name: 'Мэндин Хуан Я',
  image: '/img/tea/MengdingHuangya.png',
  chiName: '蒙顶黄芽',
  enName: 'Mengding Huangya',
  ruName: 'Желтые почки с горы Мэндин',
  location: 'Гора Мэндин в уезде Миншань провинции Сычуань',
  say: 'mēngdǐng huángyá',
  legend:
    'Как говорят, в стародавние времена жила в реке волшебная рыба. Она много лет посвятила духовным практикам и однажды достигла бессмертия и стала жить в Небесном дворце. Как-то раз, обернувшись крестьянской девушкой, она пошла полюбоваться красотами горы Мен Дин. По дороге она собирала семена чая. На горе она случайно встретила юношу, которого полюбила. В знак своих чувств она подарила ему чайные семена и они поклялись быть вместе до конца жизни. Юноша посадил семена на горе Мен Дин и стал ухаживать за ними. Но недолго влюбленные прожили вместе, небесные законы заставили девушку вернутся в Небесный дворец. Молодой человек очень скучал по ней, до конца жизни оставался верен ей. Он продолжал ухаживать за чайными кустами и собирать с них листья. Вскоре чай из Мэн Дина полюбили люди и он стал очень популярен.',
  howto: {
    text:
      'Заваривать кипятком, остывшим до 85-90°С, в фарфоровой или селадоновой гайвани, чайнике из пористой глины для желтых сортов или в стеклянной посуде. Пропорция заварки к воде: 5 г на 100 мл. Пить проливом с постепенным увеличением экспозиции. Выдерживает 7-8 заварок.',
    temp: '85-90',
    ratio: '5',
    time: '3',
  },
  taste: {
    flower: 2,
    spice: 2,
    grass: 4,
    nuts: 3,
    biscuit: 3,
  },
  headerMargin: 10,
};

export default MengdingHuangya;
