import React from 'react';

const taiwan = {
  id: 'taiwan',
  image: '/img/locations/taiwan.jpg',
  name: 'Тайвань',
  chiName: '台湾',
  ruName: '',
  say: 'Táiwān',
  teas: [
    { name: 'Вэнь Шань Бао Чжун' },
    { name: 'Бай Хао Улун' },
    { name: 'А Ли Шань Улун' },
    { name: 'Ли Шань Улун' },
    { name: 'Дун Дин Улун' },
    { name: 'Най Сян Цзинь Сюань' },
  ],
  content: (
    <React.Fragment>
      <p>
        Остров Тайвань занимает Китайская Республика, но его же континентальный Китай считает одной из своих провинций.
        С 1949 г Тайвань действует как независимое государство, но вопрос о статусе Тайваня до сих пор открыт В состав
        Китайской Республики также входят 155 островов основной группы и 64 Пескадорских острова (или Пэнху)
        Тайваньского пролива. Остров издавна населяли племена двух групп с собирательными названиями, в которые входит
        еще множество подгрупп: горцы-гаошань и жители равнин сирая. Упоминается Тайвань и в китайских документах, еще в
        230 г китайцы предприняли на остров одну из первых военно-разведывательных экспедиций. А к XIII в. — как нечто
        естественное Тайвань официально включили в состав Китайской империи.
      </p>
      <p>
        Со стороны континентального Китая на остров постепенно переселялись китайцы. Потомками этих переселенцев
        являются до 84% жителей современного Тайваня. Их образно называют «бататом» (сладкий картофель один из основных
        продуктов питания местных жителей), что означает «укорененные тайваньцы».
      </p>
      <p>
        С конца XVI в. для Тайваня началась эпоха колонизации. В 1590 г португальцы дали острову прижившееся название
        Формоза, но уже в 1624 г его отвоевали голландцы, хотя претендовали на остров также испанцы и французы. Они были
        изгнаны в 1661-1662 гг китайским патриотом по имени Чжэн Чэнгун (Косинга, 1624-1662 гг.): одни считают его
        государственным преступником, другие — выдающимся полководцем. Третьи добавляют атмосферы приключений,
        утверждая, что он был сыном китайского пирата и японки.
      </p>
      <p>
        Как бы то ни было, остров был очищен от европейских колонизаторов, а потом включен в состав одной из китайских
        провинций. В 1895 г он по условиям Симоносекского договора после Японо-Китайской войны (1894-1895 гг.) был
        передан японцам. Только в 1945 г Тайвань был возвращен Китаю. После победы в Китае коммунистической партии в
        1949 г остров накрыла волна «элитной миграции»: сюда перебралась политическая элита, в том числе представители
        партии Гоминьдан (Китайская национальная народная партия) с частью армии и многие предприниматели. Этих
        эмигрантов символично обозначают «таро» по названию клубнеплода таро (или калоказии съедобной), который способен
        прижиться на любой почве. «Таро», приехавшие из самых разных районов Китая, составляют меньший процент нынешнего
        населения Тайваня, но именно их диалект — так называемый «мандарин» — стал официальным языком Тайваня, а не
        амойский диалект первых переселенцев, прибывших из ближайших китайских провинций. С 1996 г здесь избирают своего
        президента. Экономическое чудо Тайваня стало возможно благодаря развитию производства в сфере высоких технологий
        и электроники. Традиционно развита отрасль лесозаготовки и смежные производства: например, Тайвань до сих пор
        входит в число крупнейших в мире экспортеров камфары.
      </p>
      <p>
        Правда, за процветание пришлось заплатить серьезным ухудшением экологии: значительному загрязнению подверглись
        реки и воздух, случаются кислотные дожди. Главным промышленным центром стала столица — Тайбэй, экономика
        которого продолжает ежегодно расти почти на 5%. В городе, как и в стране, низкий уровень инфляции и безработицы
        (занятость практически полная). Дух современного Тайбэя в полной мере символизирует небоскреб «Тайбэй-101» (2003
        г.): 101 этаж этого торгово-делового здания возносит его вершину на высоту 509,2 м, делая вторым в мире по
        высотности.
      </p>
      <p>
        Храмы и дворцы Тайбэя, да и всего Тайваня, в основном относятся к XVII-XVIII в. — эпохе бурного развития
        острова, который остался хранителем традиционной китайской культуры. Жемчужиной Тайбэя является Национальный
        дворцовый музей в районе Вайшуанси: коллекция из 720 тыс. экземпляров китайского искусства — самое большое
        собрание в мире — составлялась веками (более 500 лет), затем хранилась в «Запретном городе» Пекина, охранялась
        патриотами от захвата японцами, пока, наконец, не попала в Тайбэй, спасаемая от маоистов. Бывшая столица острова
        — город Тайнань знаменит обилием архитектурных памятников: одних храмов здесь около 200. Народные традиции нашли
        отражение в фестивалях и праздниках острова, а современное искусство (особенно популярный здесь абстрактный
        экспрессионизм) представлено в галереях и в музее скульптур авангардиста Чжу Мина. Термальные источники,
        природные парки и окрестные острова (например, остров Орхидей с рыбаками народности ями) привлекают на остров
        все новых гостей.
      </p>
    </React.Fragment>
  ),
};

export default taiwan;
