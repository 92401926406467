import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { basic } from '../data/framerVars';

import s from './About.module.css';
import sh from './share.module.css';

const About = () => {
  return (
    <motion.article
      variants={basic}
      initial="initial"
      animate="animate"
      exit="exit"
      className={classNames([sh.PageWrapper, s.About])}
    >
      <h2 className={classNames([sh.h2, s.h2])}>
        该项目 ∙ <span className={s.normal}>О проекте</span>
      </h2>
      {/* <h3>О проекте</h3> */}
      <p>Проект создан исключительно в информационных целях, для агрегирования знаний о чае и чайной культуре.</p>
      <p>Была использована информация из следующих источников:</p>
      <ul>
        <li>
          <a href="litres.ru/bronislav-vinogrodskiy/put-chaya-28746965/">Путь Чая - Бронислав Виногродский</a>
        </li>
        <li>
          <a href="https://www.litres.ru/cu-czipin-21098666/kanon-chaya-v-illustraciyah-48483979/">
            «Канон чая» в иллюстрациях - Цю Цзипин
          </a>
        </li>
        <li>
          <a href="https://www.tea-terra.ru/">tea-terra.ru</a>
        </li>
        <li>
          <a href="https://moychay.ru/">moychay.ru</a>
        </li>
        <li>
          <a href="https://daochai.ru/">daochai.ru</a>
        </li>
        <li>
          <a href="https://realchinatea.ru/">realchinatea.ru</a>
        </li>
        <li>
          <a href="https://chainoteka.ru/">chainoteka.ru</a>
        </li>
        <li>
          <a href="http://teahaven.ru/">teahaven.ru</a>
        </li>
        <li>... и множество других "картинок из интернета"</li>
      </ul>
      <p>Спасибо всем вам за вашу работу и ваши зания.</p>
      <p>P.S. Пожалуйста, не подавайте на меня в суд за использование материалов.</p>
      <br />
      <p>
        Отдельно рекомендую youtube канал{' '}
        <a href="https://www.youtube.com/user/brainfilter1">Сергея Шевелева (Мойчай.ру)</a>.
      </p>
    </motion.article>
  );
};

export default About;
