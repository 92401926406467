import React from 'react';
import Quote from '../../components/Quote';

import s from '../../pages/StuffItem.module.css';

const chadui = {
  id: 'chadui',
  image: '/img/stuff/chadui.png',
  name: 'Чайная пара',
  chiName: '茶对',
  ruName: 'чадуй',
  say: 'cháduì',
  content: (
    <React.Fragment>
      <p>
        Высокая узкая чашка называется ВэньСянБэй (闻香杯 • wénxiāngbēi • «чаша аромата») – чаша вкушения аромата, а
        низкая и широкая – ПиньМинБэй (品茗杯 • pǐnmíngbēi • «чаша вкуса»).
      </p>
      {/* <div className={s.splashDuo}>
        <img src="/img/stuff/chadui_opt/chadui5.jpg" alt="" />
        <img src="/img/stuff/chadui_opt/chadui6.jpg" alt="" />
      </div> */}
      <div className={s.splash}>
        <img src="/img/stuff/chadui_opt/chadui4_1280.jpg" alt="" />
      </div>
      <p>
        Высокая и продолговатая (с различными вариациями) форма с точки зрения китайской традиции соответствует
        «небесному» и наиболее подходит для полного восприятия аромата.{' '}
      </p>
      <p>
        Низкая и широкая – соответствует «земному» – позволяет насладиться вкусом, поэтому сянбэй также называют чашей
        Неба или «духом чая», а чабэй – чашей Земли или «телом чая».
      </p>
      <Quote>Чаша аромата - небесная, чаша вкуса - земная</Quote>
      <p>
        Согласно Хэ Цзяню, руководителю Чайного Дома Ye Tang, первое появление чайной пары датируется началом 70х
        прошлого века. Практика широко распространилась только в 1980е, благодаря повышению уровня жизни и улучшению
        качества изготовления чая на Тайване.
      </p>
      <p>
        По словам господина Хэ Цзяня, изящные чайники и чашки были особенно распространены в провинциях Фуцзянь и
        Гуандун в эпоху правления династии Мин (1368-1644).
      </p>
      <picture>
        <img src="/img/stuff/chadui_opt/chadui3_1280.jpg" alt="" />
      </picture>
      <p>
        Тайвань же познакомился с культурой пития чая благодаря немногим ранним иммигрантам из Китая в период Чжэн
        Чэнгуна (1624-1662) (郑成功, китайский пират, руководил освободительной борьбой против маньчжурских завоевателей
        и изгнал голландских колонизаторов с Тайваня). Однако все это было еще очень далеко от того, чтобы стать
        общепринятой, понятной и неотъемлемой частью повседневной жизни тайваньцев. В то время использовались большие
        чайники и чашки лишь из практических соображений — так трудящиеся в поле крестьяне утоляли жажду на протяжении
        всего дня.{' '}
      </p>
      <p>
        Для простых людей привычным было проглатывать еду, запивая съеденное большими глотками жидкости. Для чая,
        который разливался обязательно из большого чайника, обычно использовалась чашка соразмерная плошке для риса или
        супа. То были времена, когда предметы утвари служили в угоду одной только функциональности. Ни у кого не было
        времени на то, чтобы выставлять красивый чайный набор из-за одной чашки чая.{' '}
      </p>
      <p>
        По мере развития Тайваня от сельскохозяйственного общества к процветающему индустриальному, характеризующемуся
        предпринимательской деятельностью, появился класс городских жителей, у которых было время и для размеренного
        отдыха, и для досуга.{' '}
      </p>
      <p>
        Экономический и культурный фонд был достаточно прочным для поддержания стремления к более утонченной
        повседневной жизни. В 1980е сводный индекс фондовой биржи пересек черту 10 000 и у людей появились наличные
        деньги.{' '}
      </p>
      <p>
        Потребность в утонченном вкусе и ведении изысканного образа жизни стала актуальнее – питие чая больше уже не
        преследовало одной лишь цели утолить жажду. Чайная культура благополучно проникала в верхушки общества и, в
        конце концов, стала частью повседневной жизни каждого.{' '}
      </p>
      <p>
        Помимо улучшенного качества жизни, импульс к процветанию чайной культуры был также направлен на продолжающееся
        улучшение процесса производства чая на Тайване. Чай становился все ароматнее. С начала 1970х в чайных районах
        ДунДин и ПиньЛинь начали проводить конкурсы для высококачественного чая.
      </p>

      <p>
        Обретение известности и финансовая прибыль стимулировали производителей чая к беспрерывному улучшению качества
        изготовления чая.
      </p>
      <p>
        Таким образом, хороший аромат чая стал настолько же важным и ценным, как и вкус. В конечном итоге, все вместе
        подготовило почву для появления ВэньСянБэя – чашки для вдыхания аромата.
      </p>
      <p>
        По словам господина Хэ Цзяня, за всю историю культуры пития чая никогда ранее не было посуды, предназначенной
        специально для наслаждения и оценки аромата чая. Тайваньцы всегда будут гордиться тем, что зарождение этой
        культуры пития улунов произошло на их родине, на острове Тайвань.
      </p>
    </React.Fragment>
  ),
};

export default chadui;
