import React from 'react';
import { motion } from 'framer-motion';

import { basic } from '../data/framerVars';

import sh from './share.module.css';

const Page404 = () => {
  return (
    <motion.div
      variants={basic}
      initial="initial"
      animate="animate"
      exit="exit"
      style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}
    >
      <h2 className={sh.h2} title="Пустота">
        空虚 ∙ 404
      </h2>
    </motion.div>
  );
};

export default Page404;
