import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

import s from './FamilyCard.module.css';

const FamilyCard = (props) => {
  const { elem, variants, to = '/', ruName, say, className, ...rest } = props;
  return (
    <motion.div key={elem.id} variants={variants} className={`${s.Card} ${className}`} {...rest}>
      <NavLink to={to} className={s.Link}>
        <span className={`${s.Image} FamilyCardImage`}>
          <img src={elem.image} alt="" />
        </span>
        {elem.chiName && <span className={s.ChiName}>{elem.chiName}</span>}
        <span className={s.Name}>
          {elem.name}
          {ruName && elem.ruName && ` ∙ ${elem.ruName}`}
          {say && elem.say && ` ∙ ${elem.say}`}
        </span>
      </NavLink>
    </motion.div>
  );
};

export default FamilyCard;
