import React from 'react';
import { motion } from 'framer-motion';

import s from './Navigation.module.css';

const Path = (props) => <motion.path fill="hsl(0, 0%, 18%)" {...props} />;

const MenuToggle = (props) => {
  const { toggle } = props;

  return (
    <button className={s.button} onClick={toggle}>
      <svg width="32" height="23" viewBox="0 0 42 33">
        <Path
          variants={{
            closed: {
              d:
                'M14.955 2.68386C19.1 2.35296 25.8358 1.44314 29.3887 0.947106C31.6092 0.781759 33.8297 0.53374 36.0503 0.203047C38.271 -0.293197 40.0474 0.120169 41.3797 1.44314C42.7122 2.76633 41.8979 3.42792 38.937 3.42792C35.9763 3.26257 32.5714 3.34524 28.7224 3.67594C25.4656 4.00683 19.026 4.58565 15.177 4.91634C11.7723 5.41258 6.88694 6.57011 5.40648 6.9008C4.07421 7.2315 2.59384 6.73535 0.965363 5.41238C-0.514917 4.0892 -0.29281 3.51038 1.63168 3.67594C3.70418 3.84128 10.0698 3.34524 14.955 2.68386Z',
            },
            open: {
              d:
                'M13.9604 19.1373C17.2376 16.5779 22.3582 12.1083 25.0503 9.73722C26.8125 8.37608 28.5292 6.94595 30.2003 5.44681C31.7804 3.8094 33.4907 3.17586 35.3314 3.54617C37.1723 3.91656 36.8572 4.91724 34.386 6.54822C31.8238 8.0411 29.0276 9.98562 25.9973 12.3818C23.4615 14.4519 18.4057 18.4821 15.3754 20.8783C12.8071 23.1679 9.36735 26.825 8.31389 27.9165C7.38412 28.9264 5.87529 29.3277 3.7874 29.1206C1.82308 28.8316 1.68962 28.2262 3.38702 27.3043C5.20784 26.3007 10.2474 22.3803 13.9604 19.1373Z',
            },
          }}
        />
        <Path
          d="M14.955 28.6839C19.1 28.353 25.8358 27.4431 29.3887 26.9471C31.6092 26.7818 33.8297 26.5337 36.0503 26.203C38.271 25.7068 40.0474 26.1202 41.3797 27.4431C42.7122 28.7663 41.8979 29.4279 38.937 29.4279C35.9763 29.2626 32.5714 29.3452 28.7224 29.6759C25.4656 30.0068 19.026 30.5856 15.177 30.9163C11.7723 31.4126 6.88694 32.5701 5.40648 32.9008C4.07421 33.2315 2.59384 32.7354 0.965363 31.4124C-0.514917 30.0892 -0.29281 29.5104 1.63168 29.6759C3.70418 29.8413 10.0698 29.3452 14.955 28.6839Z"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          variants={{
            closed: {
              d:
                'M26.9935 17.6303C22.8877 17.6373 16.1926 18.0142 12.6601 18.2288C10.4613 18.2208 8.25734 18.2941 6.04833 18.4485C3.82893 18.7656 2.10347 18.2232 0.871966 16.8214C-0.359709 15.4194 0.483645 14.832 3.40203 15.0592C6.31004 15.4488 9.67108 15.6288 13.4852 15.5994C16.7156 15.5242 23.0984 15.4498 26.9124 15.4203C30.2989 15.1941 35.1855 14.4319 36.6651 14.2207C37.9986 13.9981 39.4271 14.599 40.9505 16.0236C42.3279 17.437 42.0733 17.9885 40.1867 17.6782C38.1541 17.3567 31.8494 17.3555 26.9935 17.6303Z',
            },
            open: {
              d:
                'M16.6452 12.4512C19.8168 15.1404 25.2331 19.2466 28.1015 21.4011C29.7921 22.8502 31.5411 24.2406 33.3483 25.5725C35.2725 26.7871 36.2402 28.3332 36.2514 30.2107C36.2626 32.0885 35.2187 31.9831 33.1199 29.8946C31.1378 27.689 28.666 25.3459 25.7044 22.8654C23.1624 20.8028 18.1895 16.6708 15.2279 14.1903C12.4644 12.1404 8.18496 9.51499 6.90228 8.70514C5.72465 7.99981 5.02525 6.60392 4.8041 4.51747C4.68813 2.53541 5.25384 2.28178 6.50124 3.7566C7.85369 5.33567 12.7158 9.47413 16.6452 12.4512Z',
            },
          }}
        />
      </svg>
    </button>
  );
};

export default MenuToggle;
