const BaiHaoYinZhen = {
  id: 'BaiHaoYinZhen',
  name: 'Бай Хао Инь Чжэнь',
  image: '/img/tea/BaiHaoYinZhen.png',
  chiName: '白毫银针',
  enName: 'Bai Hao Yin Zhen',
  ruName: 'Серебряные иглы',
  location: 'Горы Уишань уезда Чжэнхэ, провинция Фуцзянь',
  legend:
    'Когда-то на территории современного уезда Фудин жил человек по имени Чэнь Хуань. Он был очень почтителен к старшим, целыми днями добросовестно трудился, но всё равно жил в бедности. Тогда, выдержав трёхдневный пост, он отправился к горе Таймушань, чтобы просить помощи у богини Тайму. Чэнь Хуань возжёг благовония, преклонил колени и начал молиться. Во время молитвы ему явилась богиня Тайму, которая сказала: «На этой горе растёт удивительное дерево. Оно принесёт вам богатство и изобилие». На следующий день Чэнь Хуань и в самом деле нашёл в горной пещере чайное дерево. Он посадил его возле своего дома и тщательно ухаживал за ним. Дерево вскоре покрылось пышной кроной. С той поры Чэнь Хуань забыл о бедности: изготовленный из свежих листочков чай обладал необыкновенными свойствами, и люди по всей округе покупали его. Считается, что именно из почек и листочков такого дерева стали изготавливать Белые Чаи, в том числе и чай Серебряные Иглы Небесного Владыки.',
  howto: {
    text:
      'Заваривать горячей водой (70-80°С) в фарфоровой гайвани или чайнике из пористой глины. Пропорция заварки к воде: 5 г на 100 мл. Для первого раза настоять 15 секунд, далее пить проливом с постепенным увеличением экспозиции. Выдерживает 12 завариваний.',
    temp: '80',
    ratio: '5',
    time: '3',
  },
  taste: {
    flower: 4,
    nuts: 3,
    spice: 4,
    berry: 3,
    mint: 4,
  },
  headerMargin: 16,
};

export default BaiHaoYinZhen;
