import React from 'react';

const hubei = {
  id: 'hubei',
  image: '/img/locations/hubei.jpg',
  name: 'Хубэй',
  chiName: '湖北',
  ruName: '',
  say: 'Húběi',
  teas: [{ name: 'Энь Ши Юй Лу' }, { name: 'Юй Лу' }, { name: 'Би Цзянь Ча' }],
  content: (
    <React.Fragment>
      <p>
        Провинция Хубэй, одна из самых экономически развитых и густонаселенных в Китае, находится почти в самом центре
        страны, точнее, в его восточной части. С запада на восток она простирается на 740 км, с севера на юг — на 470
        км. С запада, севера и востока провинция окружена горами разной высоты, но всюду постепенно переходящими в плато
        и холмы. Центральная часть и юг Хубэя — это равнина.
      </p>
      <p>
        Соседняя провинция Хунань («к югу от озера»), на территории которой, собственно, и находится то самое озеро, и
        провинция Хубэй составляют, таким образом, своеобразную пару («ху» — это по-китайски «озеро»). В древности обе
        эти провинции входили в государство Чу, существовавшее в 722-221 гг. до н. э., и до XVII в. Хубэй и Хунань
        составляли одну провинцию, Хугуан, столицей которой был город Учан. Он и теперь существует, но уже как часть
        столицы современной провинции Хубэй города Ухань.{' '}
      </p>
      <p>
        Другие два бывших самостоятельных города, вошедших в эту агломерацию, — Ханькоу и Ханьян. Это слияние произошло
        в 1959 г. после открытия моста через реку Янцзы, связавшего все три города (Учан стоит на южном берегу Янцзы,
        Ханькоу и Ханьян — на северном). Государством Чу управляла династия Чжоу, внесшая большой вклад в развитие и
        распространение китайской цивилизации с севера на юг. Хубэй стал той землей, на которой произошли самые
        знаменательные события в процессе культурной ассимиляции Северного и Южного Китая, в чем немалую роль, само
        собой, сыграла и река Янцзы, связывающая разные регионы.{' '}
      </p>
      <p>
        В период Воюющих царств (475-221 гг. до н. э.) Чу воевала с Цинь и потеряла большую часть своих земель. В 206 г.
        до н. э. Цинь сменила династия Хань, при которой возникла провинция Цзинчжоу (Чжоу), равнозначная по площади
        нынешним провинциям Хубэй и Хунань. Цинь и Хань много сделали для развития земледелия на этих землях, они также
        создали первую систему дамб, уберегавших земли в бассейне Янцзы от губительных наводнений.{' '}
      </p>
      <p>
        В начале IV в. Хуанган захватили кочевники и владели им до 589 г. Затем провинция были раздроблена между разными
        династиями. Возродилась при династии Сун в 982 г. В 1279 г. регион захватили монголы, и при них, в 1334 г.,
        здешних жителей едва полностью не выкосила чума. В 1368 г. монголов изгнала династия Мин, которую в 1644 г.
        сменила могущественная маньчжурская династия Цин, разделившая провинцию Хугуан на Хубэй и Хунань. В 1911 г. в
        Учане вспыхнуло восстание против маньчжуров и династии Цин под руководством Сунь Ятсена, в результате чего была
        образована Китайская Республика, под контролем которой находилась большая часть материкового Китая и Монголия. В
        1920-х гг. Ухань был столицей этого государства, которым управляло правительство Гоминьдана, противостоящее Чан
        Кайши, обосновавшемуся в Нанкине, но потом политические оппоненты нашли общий язык.{' '}
      </p>
      <p>
        Во время Второй мировой войны восточные части провинции Хубэй были завоеваны и оккупированы Японией, а западные
        районы остались под контролем Китая. В декабре 1944 г. Ухань сильно пострадал от американских бомбардировок.
        Другое сильное потрясение столица испытала в 1967 г., в разгар Культурной революции (1966-1976 гг.), пострадали
        и люди, и многие памятники провинции. Жители Уханя даже создали отряды самообороны против фанатичных
        «революционеров». Выведенный из терпения начальник Уханьского военного округа Чэнь Цзайдао задействовал войска
        для наведения порядка, проигнорировав приказы из Пекина прекратить самоуправство, и даже не дал высадиться в
        Ухане председателю компартии — в то время Чжоу Эньлаю. После чего в Хубэй были посланы верные Пекину войска, и
        Чэнь Цзайдао сдался, был осужден. Но тем не менее к 1969 г. хунвэйбины поутихли.
      </p>
      <p>
        Хубэй славится своим чаем, который поставлялся в северный Китай и использовался для меновой торговли с
        монголами. Этот чай прослыл «императорским» после того, как, по легенде, в 1860 г., во времена империи Великая
        Цин (1644-1912 гг.), он спас от диареи любимую наложницу императора, впоследствии ставшую императрицей Цыси.
        Знаменитые хубэйские чаи «Сянь хун», «Чан юй чуань», «Шэн шэнь чуань» и другие растут на холмах нынешнего округа
        Сяньнин. Китайцы всему любят давать лаконичные и предметные нарицательные определения. Для характеристики
        главных особенностей провинции Хубэй они чаще всего употребляют словосочетания «рис и рыба» и «тысяча озер».
      </p>
    </React.Fragment>
  ),
};

export default hubei;
