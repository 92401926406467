import React from 'react';
import Quote from '../../components/Quote';

import s from '../../pages/StuffItem.module.css';

const chahu = {
  id: 'chahu',
  image: '/img/stuff/teapot.png',
  name: 'Чайник',
  chiName: '茶壶',
  ruName: 'чаху',
  say: 'cháhú',
  content: (
    <React.Fragment>
      <Quote>«Вода — мать чая, чайник — его отец, огонь — учитель.»</Quote>
      <div className={s.formsGrid}>
        <div className={s.formItem}>
          <img
            title="Гу Лян Цзы 古莲子 Древнее семя лотоса"
            src="/img/stuff/chahu/2015_08_16_04_001-1024x675.jpg"
            alt="2015_08_16_04_001"
            width="600"
            height="396"
          />
          <em>古莲子 • Гу Лян Цзы • Древнее семя лотоса</em>
        </div>
        <div className={s.formItem}>
          <img
            title="Су Бьен 虚扁"
            src="/img/stuff/chahu/2015_08_16_04_002.jpg"
            alt="2015_08_16_04_002"
            width="600"
            height="413"
          />
          <em>虚扁 • Су Бьен </em>
        </div>
        <div className={s.formItem}>
          <img src="/img/stuff/chahu/2015_08_16_04_003.jpg" alt="2015_08_16_04_003" width="600" height="328" />
          <em>三足传炉 • Сан Зу Чуан Лу</em>
        </div>
        <div className={s.formItem}>
          <img
            title="Фан Гу Жу И 仿古如意 (Фангу - cтаринный стиль, Жуи - наименование узора S-образной формы)"
            src="/img/stuff/chahu/2015_08_16_04_004.jpg"
            alt="2015_08_16_04_004"
            width="600"
            height="452"
          />
          <em>仿古如意 • Фан Гу Жу И • Фан Гу — cтаринный стиль, Жу И — наименование узора S-образной формы</em>
        </div>
        <div className={s.formItem}>
          <img src="/img/stuff/chahu/2015_08_16_04_005.jpg" alt="2015_08_16_04_005" width="600" height="367" />
          <em>西施 • Си Ши • Посвящён прелестным формам красавицы Си Ши, жившей в середине V века до нашей эры</em>
        </div>
        <div className={s.formItem}>
          <img
            title="Лун Дан 龙蛋 Яйцо дракона"
            src="/img/stuff/chahu/2015_08_16_04_006.jpg"
            alt="2015_08_16_04_006"
            width="600"
            height="397"
          />
          <em>龙蛋 • Лун Дан • Яйцо дракона</em>
        </div>
        <div className={s.formItem}>
          <img src="/img/stuff/chahu/2015_08_16_04_007.jpg" alt="2015_08_16_04_007" width="600" height="394" />
          <em>石瓢 • Ши Пяо • Каменный ковш</em>
        </div>
        <div className={s.formItem}>
          <img
            title="Бань Юэ 半月 Половина Луны"
            src="/img/stuff/chahu/2015_08_16_04_008.jpg"
            alt="2015_08_16_04_008"
            width="600"
            height="450"
          />
          <em>半月 • Бань Юэ • Половина Луны</em>
        </div>
        <div className={s.formItem}>
          <img
            title="Дан Син 丹心 Преданное сердце"
            src="/img/stuff/chahu/2015_08_16_04_009-1024x562.jpg"
            alt="2015_08_16_04_009"
            width="600"
            height="329"
          />
          <em>丹心 • Дан Син • Преданное сердце</em>
        </div>
        <div className={s.formItem}>
          <img src="/img/stuff/chahu/2015_09_05_01_001.jpg" alt="2015_09_05_01_001" width="600" height="450" />
          <em>古钟 茶壶 • Гу Чжон Чаху • Древний колокол</em>
        </div>
        <div className={s.formItem}>
          <img src="/img/stuff/chahu/2015_09_05_01_002.jpg" alt="2015_09_05_01_002" width="600" height="422" />
          <em>思亭 • Сы Тин Чаху • Беседка для размышлений</em>
        </div>
        <div className={s.formItem}>
          <img src="/img/stuff/chahu/2015_09_05_01_003.jpg" alt="2015_09_05_01_003" width="600" height="450" />
          <em>洋桶 茶壶 • Ян Тун Чаху • Широкая труба</em>
        </div>
        <div className={s.formItem}>
          <img src="/img/stuff/chahu/2015_09_05_01_004.jpg" alt="2015_09_05_01_004" width="550" height="400" />
          <em>天际茶壶 • Тьэн Ди Чаху • Небосклон / горизонт</em>
        </div>
        <div className={s.formItem}>
          <img src="/img/stuff/chahu/2015_09_05_01_005.jpg" alt="2015_09_05_01_005" width="600" height="411" />
          <em>水平 • Шуй Пин Чаху • Водяной уровень</em>
        </div>
        <div className={s.formItem}>
          <img src="/img/stuff/chahu/2015_09_05_01_006.jpg" alt="2015_09_05_01_006" width="600" height="400" />
          <em>金钟 茶壶 • Цзинь Чжон Чаху • Золотой колокол</em>
        </div>
        <div className={s.formItem}>
          <img src="/img/stuff/chahu/2015_09_05_01_007.jpg" alt="2015_09_05_01_007" width="600" height="373" />
          <em>玉璧 • Юй Би Чаху • Нефритовый диск</em>
        </div>
        <div className={s.formItem}>
          <img src="/img/stuff/chahu/2015_09_05_01_008.jpg" alt="2015_09_05_01_008" width="600" height="405" />
          <em>秦权 • Цин Чуэн • Цинская сила / авторитет</em>
        </div>
        <div className={s.formItem}>
          <img src="/img/stuff/chahu/2015_09_05_01_009.jpg" alt="2015_09_05_01_009" width="600" height="600" />
          <em>Син Чжу Чаху</em>
        </div>
        <div className={s.formItem}>
          <img src="/img/stuff/chahu/2015_09_05_01_010.jpg" alt="2015_09_05_01_010" width="600" height="538" />
          <em>葫芦茶壶 • Хулу Чаху • Чайник в форме тыквы-горлянки</em>
        </div>
        <div className={s.formItem}>
          <img
            title="48 базовых форм"
            src="/img/stuff/chahu/2015_08_16_04_010.jpg"
            alt="2015_08_16_04_010"
            width="554"
            height="1442"
          />
          <em>48 базовых форм</em>
        </div>
      </div>
      <p>
        В конце XVIII века мастер керамики и каллиграф Чэнь Маньшэн (陈曼生) обобщил накопленный опыт, представив 18
        форм чайников. Которые, с одной стороны, были созданы Маньшэном как некий итог коллективного развития искусства
        лепки чайников, с другой — каждая форма им была доведена до совершенства и стала ориентиром для последующих
        поколений мастеров. С тех пор эту коллекцию форм называют «Чайники Маньшэна» (曼生壶) или «18 форм Маньшэна»
        (曼生十八式).
      </p>
      <div className={s.formsGrid2}>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/banyuehu.jpg" alt="Чайник в форме полумесяца (Баньюэ Ху) 半月壶" />
          </picture>
          <span className={s.chahuform}>Чайник в форме полумесяца</span>{' '}
          <span className={s.chahuname}>半月壶 • bànyuè hú • Баньюэ Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/xishihu.jpg" alt="Чайник «Сиши» (Сиши Ху) 西施壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Сиши»</span>{' '}
          <span className={s.chahuname}>西施壶 • xīshī hú • Сиши Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/shipiaohu.jpg" alt="Чайник «Каменный черпак» (Шипяо Ху) 石瓢壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Каменный черпак»</span>{' '}
          <span className={s.chahuname}>石瓢壶 • shípiáo hú • Шипяо Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/fangguhu.jpg" alt="Чайник «Под старину» (Фангу Ху) 仿古壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Под старину»</span>{' '}
          <span className={s.chahuname}>仿古壶 • fǎnggǔ hú • Фангу Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/jinglanhu.jpg" alt="Чайник «Ограда колодца» (Цзинлань Ху) 井栏壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Ограда колодца»</span>{' '}
          <span className={s.chahuname}>井栏壶 • jǐnglán hú • Цзинлань Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/duoqiuhu_0.jpg" alt="Чайник «Скопление сфер» (Доцю Ху) 掇球壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Скопление сфер»</span>{' '}
          <span className={s.chahuname}>掇球壶 • duōqiú hú • Доцю Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/qinquanhu.jpg" alt="Чайник «Циньская гиря» (Циньцюань Ху) 秦权壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Циньская гиря»</span>{' '}
          <span className={s.chahuname}>秦权壶 • qínquán hú • Циньцюань Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/zhoupanhu.jpg" alt="Чайник «Компас» (Чжоупань Ху) 周盘壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Компас»</span>{' '}
          <span className={s.chahuname}>周盘壶 • zhōupán hú • Чжоупань Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img
              src="/img/stuff/chahu2/fanggujinglanhu.jpg"
              alt="Чайник «Ограда колодца под старину» (Фангу Цзинлань Ху) 仿古井栏壶"
              title="Чайник «Ограда колодца под старину»"
            />
          </picture>
          <span className={s.chahuform}>Чайник «Ограда колодца под старину»</span>{' '}
          <span className={s.chahuname}>仿古井栏壶 • fǎnggǔ jǐnglán hú • Фангу Цзинлань Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img
              src="/img/stuff/chahu2/liufangduoqiuhu_0.jpg"
              alt="Шестигранный чайник «Скопление сфер» (Люфан Доцю Ху) 六方掇球壶"
            />
          </picture>
          <span className={s.chahuform}>Шестигранный чайник «Скопление сфер»</span>{' '}
          <span className={s.chahuname}>六方掇球壶 • liùfāng duōqiú hú • Люфан Доцю Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/tilianghu.jpg" alt="Чайник с ручкой Ляна (Тилян Ху) 提梁壶" />
          </picture>
          <span className={s.chahuform}>Чайник с ручкой Ляна</span>{' '}
          <span className={s.chahuname}>提梁壶 • tíliáng hú • Тилян Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img
              src="/img/stuff/chahu2/dabintilianghu.jpg"
              alt="Чайник Дабиня с ручкой Ляна (Дабинь Тилян Ху) 大彬提梁壶"
            />
          </picture>
          <span className={s.chahuform}>Чайник Дабиня с ручкой Ляна</span>{' '}
          <span className={s.chahuname}>大彬提梁壶 • dàbīn tíliáng hú • Дабинь Тилян Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img
              src="/img/stuff/chahu2/xumaotilianghu.jpg"
              alt="Чайник Сюймао с ручкой Ляна (Сюймао Тилян Ху) 旭茂提梁壶"
            />
          </picture>
          <span className={s.chahuform}>Чайник Сюймао с ручкой Ляна</span>{' '}
          <span className={s.chahuname}>旭茂提梁壶 • xùmào tíliáng hú • Сюймао Тилян Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/xubianhu.jpg" alt="Чайник «Плоская пустота» (Сюйбянь Ху) 虚扁壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Плоская пустота»</span>{' '}
          <span className={s.chahuname}>虚扁壶 • xūbiǎn hú • Сюйбянь Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/hehuanhu.jpg" alt="Чайник (Хэхуань Ху) «Совместная радость» 合欢壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Совместная радость»</span>{' '}
          <span className={s.chahuname}>合欢壶 • héhuān hú • Хэхуань Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/yangtonghu.jpg" alt="Чайник в форме бочки (Янтун Ху) 洋桶壶" />
          </picture>
          <span className={s.chahuform}>Чайник в форме бочки</span>{' '}
          <span className={s.chahuname}>洋桶壶 • yángtǒng hú • Янтун Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img
              src="/img/stuff/chahu2/sifangyinjiaozhudinghu.jpg"
              alt="Четырехгранный чайник с углом предельного равновесия и бамбуковой верхушкой (Сыфан Иньцзяо Чжудин Ху) 四方隐角竹顶壶"
            />
          </picture>
          <span className={s.chahuform}>
            Четырехгранный чайник с углом предельного равновесия и бамбуковой верхушкой
          </span>{' '}
          <span className={s.chahuname}>四方隐角竹顶壶 • sìfāng yǐnjiǎo zhúdǐng hú • Сыфан Иньцзяо Чжудин Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/hulenghu_0.jpg" alt="Чайник со скругленными гранями (Хулэн Ху) 弧棱壶" />
          </picture>
          <span className={s.chahuform}>Чайник со скругленными гранями</span>{' '}
          <span className={s.chahuname}>弧棱壶 • húléng hú • Хулэн Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/danbaohu.jpg" alt="Чайник «Яичный мешок» (Дань Бао Ху) 蛋包壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Яичный мешок»</span>{' '}
          <span className={s.chahuname}>蛋包壶 • dàn bāo hú • Дань Бао Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/hanwahu.jpg" alt="Чайник в форме ханьской черепицы (Хань Ва Ху) 汉瓦壶" />
          </picture>
          <span className={s.chahuform}>Чайник в форме ханьской черепицы</span>{' '}
          <span className={s.chahuname}>汉瓦壶 • hàn wǎ hú • Хань Ва Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/wadanghu.jpg" alt="Чайник в форме черепицы (Вадан Ху) 瓦当壶" />
          </picture>
          <span className={s.chahuform}>Чайник в форме черепицы</span>{' '}
          <span className={s.chahuname}>瓦当壶 • wǎdāng hú • Вадан Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/zhuchuhu.jpg" alt="Чайник в форме основания колонны (Чжучу Ху) 柱础壶" />
          </picture>
          <span className={s.chahuform}>Чайник в форме основания колонны</span>{' '}
          <span className={s.chahuname}>柱础壶 • zhùchǔ hú • Чжучу Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/yilizhuhu.jpg" alt="Чайник «Жемчужина» (И Ли Чжу Ху) 一粒珠壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Жемчужина»</span>{' '}
          <span className={s.chahuname}>一粒珠壶 • yī lì zhū hú • И Ли Чжу Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/mengchenhu.jpg" alt="Чайник Мэнчэня (Мэнчэнь Ху) 孟臣壶" />
          </picture>
          <span className={s.chahuform}>Чайник Мэнчэня</span>{' '}
          <span className={s.chahuname}>孟臣壶 • mèngchén hú • Мэнчэнь Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/tangpohu.jpg" alt="Чайник «Хранитель кипятка» (Танпо Ху) 汤婆壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Хранитель кипятка»</span>{' '}
          <span className={s.chahuname}>汤婆壶 • tāngpó hú • Танпо Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/hanbianhu.jpg" alt="Ханьский плоский чайник (Хань Бянь Ху) 汉扁壶" />
          </picture>
          <span className={s.chahuform}>Ханьский плоский чайник</span>{' '}
          <span className={s.chahuname}>汉扁壶 • hàn biǎn hú • Хань Бянь Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/xianyuanhu.jpg" alt="Чайник с нитью вокруг (Сянь Юань Ху) 线圆壶" />
          </picture>
          <span className={s.chahuform}>Чайник с нитью вокруг</span>{' '}
          <span className={s.chahuname}>线圆壶 • xiàn yuán hú • Сянь Юань Ху</span>{' '}
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/jiyuhu.jpg" alt="Коллекционный нефритовый чайник (Цзи Юйху) 集玉壶" />
          </picture>
          <span className={s.chahuform}>Коллекционный нефритовый чайник</span>{' '}
          <span className={s.chahuname}>集玉壶 • jí yùhú • Цзи Юйху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img
              src="/img/stuff/chahu2/chuanluhu.jpg"
              alt="Чайник в форме традиционной курильницы (Чуань Лу Ху) 传炉壶"
            />
          </picture>
          <span className={s.chahuform}>Чайник в форме традиционной курильницы</span>{' '}
          <span className={s.chahuname}>传炉壶 • chuán lú hú • Чуань Лу Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/sengmaohu.jpg" alt="Чайник «Шапка монаха» (Сэнмао Ху) 僧帽壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Шапка монаха»</span>{' '}
          <span className={s.chahuname}>僧帽壶 • sēngmào hú • Сэнмао Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/shuipinghu.jpg" alt="Чайник «Водяной Уровень» (Шуйпин Ху) 水平壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Водяной Уровень»</span>{' '}
          <span className={s.chahuname}>水平壶 • shuǐpíng hú • Шуйпин Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/xuehuahu.jpg" alt="Чайник «Снежинка» (Сюэхуа Ху) 雪华壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Снежинка»</span>{' '}
          <span className={s.chahuname}>雪华壶 • xuěhuā hú • Сюэхуа Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/helinghu.jpg" alt="Чайник «Водяной орех» (Хэ Лин Ху) 合菱壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Водяной орех»</span>{' '}
          <span className={s.chahuname}>合菱壶 • hé líng hú • Хэ Лин Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/linghuahu.jpg" alt="Чайник «Цветок водяного ореха» (Линхуа Ху) 菱花壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Цветок водяного ореха»</span>{' '}
          <span className={s.chahuname}>菱花壶 • línghuā hú • Линхуа Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img
              src="/img/stuff/chahu2/yulanhualiubanhu.jpg"
              alt="Чайник «Шесть лепестков магнолии» (Юйланьхуа Любань Ху) 玉兰花六瓣壶"
            />
          </picture>
          <span className={s.chahuform}>Чайник «Шесть лепестков магнолии»</span>{' '}
          <span className={s.chahuname}>玉兰花六瓣壶 • yùlánhuā liùbàn hú • Юйланьхуа Любань Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/kuifangguhu.jpg" alt="Чайник под старину «Мальва» (Куй Фангу Ху) 葵仿古壶" />
          </picture>
          <span className={s.chahuform}>Чайник под старину «Мальва»</span>{' '}
          <span className={s.chahuname}>葵仿古壶 • kuí fǎnggǔ hú • Куй Фангу Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/longdaihu.jpg" alt="Чайник «Драконий пояс» (Лундай Ху) 龙带壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Драконий пояс»</span>{' '}
          <span className={s.chahuname}>龙带壶 • lóngdài hú • Лундай Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/zhuduanhu.jpg" alt="Чайник «Отрезок бамбука» (Чжудуань Ху) 竹段壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Отрезок бамбука»</span>{' '}
          <span className={s.chahuname}>竹段壶 • zhúduàn hú • Чжудуань Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/nanguahu.jpg" alt="Чайник «Тыква» (Наньгуа Ху) 南瓜壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Тыква»</span>{' '}
          <span className={s.chahuname}>南瓜壶 • nánguā hú • Наньгуа Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img
              src="/img/stuff/chahu2/songshuputaohu.jpg"
              alt="Чайник «Белка и виноград» (Суншу Путао Ху) 松鼠葡萄壶"
            />
          </picture>
          <span className={s.chahuform}>Чайник «Белка и виноград»</span>{' '}
          <span className={s.chahuname}>松鼠葡萄壶 • sōngshǔ pútao hú • Суншу Путао Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/baochunhu.jpg" alt="Чайник «Вестник весны» (Баочунь Ху) 报春壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Вестник весны»</span>{' '}
          <span className={s.chahuname}>报春壶 • bàochūn hú • Баочунь Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/sitinghu.jpg" alt="Чайник Сытина (Сытин Ху) 思亭壶" />
          </picture>
          <span className={s.chahuform}>Чайник Сытина</span>{' '}
          <span className={s.chahuname}>思亭壶 • sītíng hú • Сытин Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img
              src="/img/stuff/chahu2/songzhumeihu.jpg"
              alt="Чайник «Сосна, бамбук и слива» (Сун Чжу Мэй Ху) 松竹梅壶"
            />
          </picture>
          <span className={s.chahuform}>Чайник «Сосна, бамбук и слива»</span>{' '}
          <span className={s.chahuname}>松竹梅壶 • sōng zhú méi hú • Сун Чжу Мэй Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/yinbaohu.jpg" alt="Чайник «Печать в узелке» (Иньбао Ху) 印包壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Печать в узелке»</span>{' '}
          <span className={s.chahuname}>印包壶 • yìnbāo hú • Иньбао Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/shangxinqiaohu.jpg" alt="Чайник «На новом мосту» (Шан Синьцяо Ху) 上新桥壶" />
          </picture>
          <span className={s.chahuform}>Чайник «На новом мосту»</span>{' '}
          <span className={s.chahuname}>上新桥壶 • shàng xīnqiáo hú • Шан Синьцяо Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img
              src="/img/stuff/chahu2/fengjuankuihuhu.jpg"
              alt="Чайник «Ветер закружил озеро мальвы» (Фэнцзюань Куйху Ху) 风卷葵湖壶"
            />
          </picture>
          <span className={s.chahuform}>Чайник «Ветер закружил озеро мальвы»</span>{' '}
          <span className={s.chahuname}>风卷葵湖壶 • fēngjuǎn kuíhú hú • Фэнцзюань Куйху Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/dezhonghu.jpg" alt="Чайник «Колокол добродетели» (Дэчжун Ху) 德钟壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Колокол добродетели»</span>{' '}
          <span className={s.chahuname}>德钟壶 • dézhōng hú • Дэчжун Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img
              src="/img/stuff/chahu2/yuhualonghu.jpg"
              alt="Чайник «Рыба, превращающаяся в дракона» (Юйхуалун Ху) 鱼化龙壶"
            />
          </picture>
          <span className={s.chahuform}>Чайник «Рыба, превращающаяся в дракона»</span>{' '}
          <span className={s.chahuname}>鱼化龙壶 • yúhuàlóng hú • Юйхуалун Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/longdanhu.jpg" alt="Чайник «Яйцо дракона» (Лундань Ху) 龙蛋壶" />
          </picture>
          <span className={s.chahuform}>Чайник «Яйцо дракона»</span>{' '}
          <span className={s.chahuname}>龙蛋壶 • lóngdàn hú • Лундань Ху</span>
        </div>
        <div className={s.formItem2}>
          <picture>
            <img src="/img/stuff/chahu2/gongchunhu.jpg" alt="Чайник Гунчуня (Гунчунь Ху) 供春壶" />
          </picture>
          <span className={s.chahuform}>Чайник Гунчуня</span>{' '}
          <span className={s.chahuname}>供春壶 • gōngchūn hú • Гунчунь Ху</span>
        </div>
      </div>
    </React.Fragment>
  ),
};

export default chahu;
