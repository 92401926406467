import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { basicStagger, basicItem } from '../data/framerVars';

import teaTypes from '../data/data';

import s from './Making.module.css';
import sh from './share.module.css';

const steps = [
  { id: '杀青', chi: '杀青', ru: 'Ша Цин', name: '«убийство зелени»' },
  { id: '揉捻', chi: '揉捻', ru: 'Жоу Нянь', name: 'сминание скручивание' },
  { id: '干燥', chi: '干燥', ru: 'Гань Цзао', name: 'сушка' },
  { id: '萎凋', chi: '萎凋', ru: 'Вэй Дяо', name: 'подвяливание' },
  { id: '发酵', chi: '发酵', ru: 'Фа Цзяо', name: 'ферментация' },
  { id: '过红锅', chi: '过红锅', ru: 'Го Хун Го', name: '«пройти красный котёл»' },
  { id: '薰焙', chi: '薰焙', ru: 'Сюнь Бэй', name: 'копчение' },
  { id: '蒸汽', chi: '蒸汽', ru: 'Чжэн Ци', name: 'пропаривание' },
  { id: '揉切', chi: '揉切', ru: 'Жоу Це', name: 'резка' },
  { id: '炒青', chi: '炒青', ru: 'Чао Цин', name: 'прожаривание' },
  { id: '晒青', chi: '晒青', ru: 'Шай Цин', name: 'сушка или подвяливание на солнце' },
];

const Step = (props) => {
  const { step } = props;
  const { chi, ru, name } = step;
  return (
    <div className={s.Step}>
      <span className={s.chiName}>{chi}</span>
      <span className={s.ruName}>{ru}</span>
      <span className={s.Name}>{name}</span>
    </div>
  );
};

const Making = () => {
  return (
    <motion.article
      variants={basicStagger}
      initial="initial"
      animate="animate"
      exit="exit"
      className={classNames([sh.PageWrapper, s.Making])}
    >
      <motion.div variants={basicItem} className={s.Block}>
        <div className={s.TeaScroll}>
          {teaTypes.map((teaType, i) => {
            return (
              <div key={i}>
                <div className={s.TeaName}>
                  <span className={s.chiName}>{teaType.chiName} • </span>
                  {teaType.name}
                </div>
                {teaType.making &&
                  teaType.making.map((st, i) => {
                    const step = steps.find((el) => el.id === st);
                    return <Step key={i} step={step} />;
                  })}
              </div>
            );
          })}
        </div>
      </motion.div>
      {/* <Step chi="杀青" ru="Ша Цин" name="«убийство зелени»" />
      <Step chi="杀青" ru="Ша Цин" name="подвяливание" />
      <Step chi="过红锅" ru="Го Хун Го" name="«пройти красный котёл»" /> */}
      <motion.div variants={basicItem} className={s.Block}>
        <h5>Ша Цин (杀青) – «убийство зелени».</h5>
        <p>
          Целью данной операции является удаление содержащейся в листе влаги, запаха свежести и создание чайного
          аромата.
        </p>
        <p>
          Также прекращается активность ферментов, затормаживается ферментация и окисление полифенолов. Это позволяет
          сохранить цвет чайного листа зелёным.
        </p>
        <p>
          Очень важно полностью удалить свежесть из листа, но при этом не пересушить его. Лист должен остаться нежным и
          не стать серого цвета.
        </p>
        <p>Ша Цин можно производить тремя разными способами:</p>
        <ol>
          <li>
            В котле Го (锅), которые бывают плоские и вогнутые.
            <br />
            Обычно это происходит при температуре 180-250 градусов, сначала котёл нагревают на большом огне, а потом
            снижают температуру с 250 до 180 градусов.
            <br />
            Закладывают в котёл от 500 гр до 1 кг свежего чайного листа. Время нахождения от 5 до 10 минут в зависимости
            от количества чая. Чай внутри перемешивают либо путём подбрасывания, либо трясением (ворошением).
          </li>
          <li>
            Во вращающемся горячем барабане.
            <br />
            Обычно используют барабаны диаметром 50-80 см.
            <br />
            Скорость вращения – 28-32 оборота в минуту.
            <br />
            Листья в барабане находятся от 2 до 3 минут.
            <br />
            Используется непрерывный способ закладки, что позволяет в час обрабатывать 150-200 кг чайного листа.
          </li>
          <li>
            Пропаривание Чжэн Ци (蒸汽).
            <br />
            Температура пара 95-100 градусов.
            <br />
            Время воздействия от 30 сек до 1 минуты.
          </li>
        </ol>
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        <h5>Чжэн Ци (蒸汽) Пропаривание</h5>
        <p>Процесс обработки паром собранных свежих листьев.</p>
        <p>
          Лист, насыпанный в корзину с дырчатым дном, помещают над кипящей водой на 30 – 60 секунд. Это позволяет при
          невысокой (95-100°С) температуре остановить ферментацию и зафиксировать вкусовую и ароматическую основу в
          чайном листе.
        </p>
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        <h5>Жоу Нянь (揉捻) – сминание-скручивание.</h5>
        <p>
          Задача данной операции - придать форму чайному листу. При этом выделяется сок чайного листа, что способствует
          в дальнейшем большей экстрактивности готового чая.
        </p>
        <p>
          Сминание можно производить ручным и машинным способом. Высококачественные чаи делаются вручную. Во время
          ручного сминания делаются поступательные и вращательные движения руками, сначала слегка надавливая, потом
          сильнее. Если листья старые и большие, то сминают их ещё горячими, если среднего размера, то тёплыми. Нежные
          листочки мнут холодными.
        </p>
        <p>
          При автоматическом сминание также смотрят на лист. Если лист нежный, то не сильно закручивают крышку. Если
          лист грубый, то немного добавляют давление крышки, а в последний момент отпускают.
        </p>

        {/* <p>Для Гунфу Хунов и Сяо Чжунов используется либо ручное сминание, либо с помощью круговой машины с рёбрами.</p>
        <p>
          При изготовлении резаного красного чая лист сначала сминается, чтобы придать форму листу, а потом используется
          машина для резки (есть 2 типа: СТС и LTP).
        </p> */}
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        <h5>Жоу Це (揉切) – резка.</h5>
        <p>
          Целью этой операции является нарушение структуры чайного листа, что ускоряет химические реакции, происходящие
          в листе, способствующие насыщенности чайного настоя.
        </p>
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        <h5>Гань Цзао (干燥) – сушка.</h5>
        <p>
          Цель последней операции – окончательно остановить ферментацию и окисление, убрать влагу и с ней аромат свежей
          травы, и усилить и закрепить аромат чая.
        </p>
        <p>Обычно используют один из двух методов: </p>
        <ul>
          <li>сушка в котле Чао Гань (炒干)</li>
          <li>сушка горячим воздухом Хун Гань (烘干)</li>
        </ul>
        <p>
          Сушка в котле Чао Гань (炒干) включает три стадии: «вторая свежесть», «третья свежесть» и просушка «до
          блеска».
        </p>
        <p>Сушка горячим воздухом Хун Гань (烘干) имеет тоже два этапа: «огонь ворсинок» и «достаточный огонь». </p>
        <p>
          Сначала при температуре 115 градусов, после которой лист теряет 20% влаги, а потом при 90 градусов, где
          количество потерянной влаги доводится до 45% от первоначальной массы листа.
        </p>
        <p>
          Знаменитые зелёные чаи в основном сушат вторым способом. При этом оборудование для сушки бывает разными:
          корзины над углями, решётки, шкафы с подачей горячего воздуха.
        </p>
      </motion.div>
      {/* <motion.div variants={basicItem} className={s.Block}>
        <p>
          История производства красного чая в Китае насчитывает более 200 лет. Красный чай делится на три вида: Сяо
          Чжуны (копчёные чаи), Гунфу Хуны (изысканные чаи) и резаный чай. Вне зависимости от вида чая, все красные чаи
          проходят в своём производстве 4 этапа: Вэй Дяо (萎凋) — подвяливание, Жоу Нянь (揉捻) – сминание-скручивание,
          Фа Цзяо (发酵) — ферментация и Гань Цзао (干燥) — сушка. Для Сяо Чжунов дополнительно проделывают ещё две
          операции: Го Хун Го (过红锅) — «пройти красный котёл» и Сюнь Бэй (薰焙) — копчение.
        </p>
      </motion.div> */}
      <motion.div variants={basicItem} className={s.Block}>
        <h5>Вэй Дяо (萎凋) – подвяливание.</h5>
        <p>В эту операцию чайный лист избавляется от влаги и идут превращения микроэлементов.</p>
        <p>
          Целью операции является подготовка к последующему сминанию (или резке) и ферментации. Во время подвяливания
          количество влаги в листе уменьшается до 58-64%.
        </p>
        <p>
          Время подвяливания зависит от самого листа: в весеннем происходит быстрее, чем в летнем, в нежном листе –
          быстрее, чем в старом.
        </p>
        <p>Существует несколько способов подвяливания: в помещении, на солнце, в баке и в машине для подвяливания.</p>
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        <h5>Фа Цзяо (发酵) – ферментация.</h5>
        <p>
          Процесс ферментации – это нахождение смятого или резаного листа при определённой температуре, влажности и
          доступе кислорода. Он нужен для ускорения протекания химических реакций в чайном листе.
        </p>
        <p>
          Сяо Чжуны и Гунфу Хуны ферментируются в специальных корзинах. Первые в течении 5-6 часов, вторые в течении 3-5
          часов.
        </p>
        <p>Резаные чаи ферментируются в специальной машине 1-2 часа.</p>
      </motion.div>
      {/* <p>Сяо Чжуны в своём производстве имеют две дополнительные стадии:</p> */}
      <motion.div variants={basicItem} className={s.Block}>
        <h5>Го Хун Го (过红锅) — «пройти красный котёл».</h5>
        <p>
          {/* Особенный метод, придающий исключительность данному сорту чая. */} Необходим для того, чтобы остановить
          процесс ферментации и сохранить частично растворимые полифенолы, придать силу чайному настою.{' '}
          {/* При высокой температуре
          запах свежести улетучивается и появляется аромат Сяо Чжуна. */}
        </p>
        <p>
          Го Хун Го представляет собой сушку в котле при температуре 200 градусов. Прошедшие ферментацию листья в
          количестве 1-2 кг вращаются 2-3 минуты в котле и быстро удаляются из него.
        </p>
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        <h5>Сюнь Бэй (薰焙) – копчение.</h5>
        <p>
          {/* После операции Го Хун Го и повторного сминания  */}
          Чайные листья раскладывают на сите слоем в 7-10 см. Потом сита помещаются над тлеющими углями. Сосновый дым
          впитывается в чайный лист и придаёт специфический аромат чаю,
          {/* который является особенностью Сяо Чжуна. */} Во время операции чай переворачивают один или два раза.
          Сушится до достижения 80-90%.
        </p>
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        <h5>Чао Цин (炒青) – прожаривание</h5>
        <p>
          Прожаривание при высокой температуре, используется как для фиксации зелени, так и для окончательной сушки.
        </p>
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        Сушка или подвяливание на Солнце 晒青 (шай цин). Целью этого процесса является уменьшение содержания влаги в
        сыром листе на 25-30%. Чай тонким слоем расстилается на бамбуковых настилах и остается под рассеянными лучами
        Солнца на примерно 2 часа. Обычно не подставляют лист под прямые лучи, а используют что-то типа поликарбоната
        (как в теплицах) для смягчения света.
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        Заключительная сушка 萎凋. Чай окончательно сушится несколько часов в тени в дхулах, хорошо вентилируемых,
        зачастую принудительно, настилах. Толщина слоя чая в таких настилах обычно 4-5 см. Так получается “маоча”,
        рассыпной некупажированный шен пуэр. Такой чай зачастую скупают большие фабрики для купажирования и последующей
        прессовки. Весь процесс приготовления длится менее 1 рабочего дня. Сбор+подвяливание (2 часа)+убийство зелени(5
        минут)+сушка(3-4 часа)
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        Прессовка чая 圧制. Придание чаю формы хорошо известного блинчика 357гр или другого формата. Прессованный чай
        занимает меньше места при его “долгом” хранении. Используются либо классические каменные прессы, либо
        промышленные механические. Перед прессом, нужное количество чая обдается паром, чтобы лист стал “податливым” и
        легко принял требуемую форму. Читайте, почему блин прессуют 357гр блинами?
      </motion.div>
      <motion.div variants={basicItem} className={s.Block}>
        Обертывание в упаковка 包茶. После прессовки чай высушивается и вылеживается некоторое время на стеллажах.
        Иногда очень долго. После этого его обертывают в “этикетку”, собирают блины по 7, 5 или 10 штук в зависимости от
        формата.
      </motion.div>
    </motion.article>
  );
};

export default Making;
