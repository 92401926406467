const tastes = [
  { slug: 'grass', label: 'Травянистый' },
  { slug: 'flower', label: 'Цветочный' },
  { slug: 'honey', label: 'Медовый' },
  { slug: 'nuts', label: 'Ореховый' },
  { slug: 'trees', label: 'Древесный' },
  { slug: 'spice', label: 'Пряный' },
  { slug: 'fruit', label: 'Фруктовый ' },
  { slug: 'berry', label: 'Ягодный' },
  { slug: 'vanilla', label: 'Ванильный' },
  { slug: 'mint', label: 'Мятный' },
  { slug: 'biscuit', label: 'Бисквитный' },
  { slug: 'citrus', label: 'Цитрусовый' },
  { slug: 'smoked', label: 'Копченый' },
  { slug: 'balmy', label: 'Бальзамический' },
  { slug: 'bread', label: 'Хлебный' },
  { slug: 'earth', label: 'Землистый' },
];

export default tastes;
