const JunShanYinZhen = {
  id: 'JunShanYinZhen',
  name: 'Цзюнь Шань Инь Чжень',
  image: '/img/tea/JunShanYinZhen.png',
  chiName: '君山银针',
  enName: 'Jun Shan Yin Zhen',
  ruName: 'Серебряные иглы с горы Бессмертных',
  location: 'Гора Цзюнь Шань на озере Дунтин',
  legend:
    'Более четырех тысяч лет назад, когда император Шунь путешествовал на Юг, он умер под горой Цзю И Шань. И две его любимых жены, Э Хуан и Нюй Ин справляли траур. На лодке плыли они по озеру, осуществляя траурный обряд, и ветер перевернул лодку. Тогда на озере всплыли 72 бирюзовых улитки, которые подхватили их, создав своими телами гору Цзюнь Шань. Поскольку они были женами и наложницами императора, гора стала называться горой императора, государя, Цзюнь шань.',
  howto: {
    text:
      'Заваривать горячей водой (90-95°С) в фарфоровой гайвани, чайнике из пористой глины или стеклянной посуде. Пропорция заварки к воде: 4-5 г на 100 мл. Пить проливом с постепенным увеличением экспозиции. Выдерживает 9 завариваний.',
    temp: '90-95',
    ratio: '4-5',
    time: '2',
  },
  taste: {
    citrus: 2,
    smoked: 3,
    spice: 3,
    balmy: 4,
    fruit: 4,
  },
  headerMargin: 4,
};

export default JunShanYinZhen;
