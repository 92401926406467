const TieGuanYin = {
  id: 'TieGuanYin',
  name: 'Те Гуань Инь',
  image: '/img/tea/TieGuanYin.png',
  chiName: '铁观音',
  enName: 'Tie Guan Yin',
  ruName: 'Железная Бодхисаттва Гуань  Инь, Железная Богиня Милосердия',
  location: 'Северо-запад провинции Фуцзянь, в горах Уи',
  legend:
    'В давние времена в Анси жил набожный старик, который каждый день подносил чашку чая в дар богине Гуань Инь и убирался в ее заброшенном храме. Однажды богиня явилась ему во сне и сказала, что позади храма находится сокровище, которое он может забрать себе, но должен поделиться им и с людьми. На утро за храмом крестьянин обнаружил чайный куст, выкопал его и отнес к себе на огород. А когда приготовил из него напиток — был восхищен его прекрасным вкусом и ароматом, поэтому в благодарность назвал его в честь богини — Те Гуань Инь.',
  howto: {
    text:
      'Заваривать горячей водой (80-85°С) в фарфоровой гайвани, чайнике из пористой глины или селадона. Пропорция заварки к воде: 6 г на 100 мл. Для первого раза настоять 5-7 секунд после чего пить проливом с постепенным увеличением экспозиции. Выдерживает 12 завариваний.',
    temp: '80-85',
    ratio: '6',
    time: '2',
  },
  taste: {
    fruit: 4,
    grass: 4,
    flower: 4,
  },
};

export default TieGuanYin;
