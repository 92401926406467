import React from 'react';

const guanxi = {
  id: 'guanxi',
  image: '/img/locations/guanxi.jpg',
  name: 'Гуаньси',
  chiName: '关系',
  ruName: '',
  say: 'guānxì',
  teas: [{ name: 'Мо Ли Хуа Ча' }, { name: 'Сишань Ча' }, { name: 'Лин Юн Бай Мао' }, { name: 'Гуйлин Мао Цзян' }],
  content: (
    <React.Fragment>
      <p>
        В древние времена территория Гуанси-Чжуанского автономного района заселяли преимущественно чжуаны. Традиционно
        эти земли в культурном отношении тяготели к Вьетнаму, с чем растущая Китайская империя никак смириться не могла.
        Китай завоевал эту территорию еще в III-II вв. до н. э., в эпоху династии Цин. Едва эти земли были официально
        провозглашены частью Китайской империи в 214 г. до н. э., как в результате распада империи Цин они отпали от
        Китая и выделились в царство Наньюэ. Лишь в III г. до н. э. император У-ди покорил Наньюэ и включил его в состав
        империи Хань. Китайцы учли ошибку, и в последующем территориальные завоевания в этом районе сопровождались
        активным массированным заселением и освоением этого края китайцами.
      </p>
      <p>
        Несмотря на колоссальные усилия императорских чиновников, эти земли оставались все такими же беспокойными и
        малоуправляемыми. Чтобы добиться покорности, императоры прибегали к старому методу «разделяй и властвуй»,
        разжигая межнациональную вражду. Так, в 1465 г., в эпоху Великой Минской империи, китайским правителям удалось
        столкнуть народы чжуан и яо, произошла известная битва в Большом ротанговом ущелье, в которой погибло более 20
        тысяч человек.
      </p>
      <p>
        Во времена империи Цин начиная с VII в. об этом районе словно позабыли, и здесь воцарилось спокойствие. Во
        второй половине XVII в. была создана провинция Гуанси. Но уже в XIX в. по району прокатилась волна кровавых
        крестьянских восстаний против империи Цин, самыми известными из которых были мятеж яо в 1831 г., Тайпинское
        восстание 1850-1864 гг. и восстание Хун Сюцюаня в 1851 г.
      </p>
      <p>
        Антикатолическая политика китайских властей, опасавшихся роста влияния католицизма в Гуанси, привела ко Второй
        Опиумной войне 1856-1860 гг. и французскому вторжению. В 1898 г. Франция принудила китайское правительство
        признать Гуанси сферой ее влияния — пусть и ненадолго.
      </p>
      <p>
        Всю первую половину XX в. в Гуанси царила политическая неразбериха: к власти приходили военные, авантюристы и
        откровенные бандиты. Воспользовавшись этим, японцы оккупировали Гуанси с 1944 по 1945 г. После изгнания японцев
        здесь обосновался Гоминьдан, от которого провинцию освободили коммунистические войска в конце 1949 г. В 1958 г.
        был образован Гуанси-Чжуанский автономный район.
      </p>
      <p>
        Название района — Гуанси — означает «необъятная, расширяющаяся», появилось оно еще в 226 г. до н. э. Несмотря на
        то что территория получила статус отдельной провинции еще при династии Юань в ХIII в., даже в наши времена
        жители Китая считают этот край диким и малоосвоенным.
      </p>
      <p>
        Провинция Юньнань является одним из самых этнически разнообразных регионов мира. В разных уголках провинции
        сохранился матриархат, люди все еще пишут пиктографическим или слоговым письмом, живут в домах из бамбука и
        почитают культ предков.
      </p>
      <p>
        Большинство населения района составляют ханьцы, но вторым по численности народом являются чжуаны. Следует
        заметить, что чжуаны были признаны национальным меньшинством в Китае только в 1958 г. Чжуаны — коренной народ
        Южного Китая, самое большое по численности национальное меньшинство в Китае: из общей численности в 16 млн чел.
        более 14 млн проживают именно в этом районе.
      </p>
      <p>
        Несмотря на настойчивую политику китаизации национальных окраин, обойти этот факт нельзя было никак, что и
        получило отражение в полном названии региона. Многочисленность чжуанов и особенности рельефа местности, когда
        отдельные группы народности мало общались друг с другом, породили особенность чжуанского языка, распадающегося
        на целых 16 диалектов.
      </p>
      <p>
        Благодаря своей многочисленности чжуаны сумели сохранить древние традиции массовых празднеств. Самый известный —
        Сам-Ньед-Сам, отмечаемый в третий месяц китайского лунного календаря. Чжуаны сохранили древнейший стиль
        антифонного (хорового) исполнения песен, распеваемых попеременно юношами и девушками. Еще одна древняя традиция
        праздника Сам-Ньед-Сам — танцы у шеста.
      </p>
      <p>
        Многие чжуаны — язычники, и этого не скрывают, поклоняясь Богу Леса и устраивая праздник поминовения предков в
        седьмой месяц лунного календаря. Чжуаны-анимисты устраивают бои буйволов, а в деревнях непременно отмечают
        Фестиваль первых фруктов. Со своей стороны, китайцы-хань преданы старинной китайской традиции отмечать праздник
        Дуань-у, или Праздник драконьих лодок, во время которого устраивают состязание в гребле на лодках в виде
        драконов.
      </p>
      <p>
        Ведущая отрасль экономики — сельское хозяйство. Здесь в течение года снимают 2-3 урожая сельскохозяйственных
        культур. Основная — рис, занимающий почти 2/3 всей обрабатываемой площади.
      </p>
      <p>
        Однако всемирную известность району принес не рис, а анис. В районе собирают практически весь мировой урожай
        аниса, чьи плоды используют в традиционной китайской медицине, а также в качестве пряности.
      </p>
      <p>
        Промышленность развивается преимущественно в городах и морских портах. Гуанси с выгодой использует свои
        природные преимущества: район находится на рубеже между Юго-Восточной Азией и Китаем, став самым выгодным путем
        с юго-запада страны к мировым морским коммуникациям.
      </p>
      <p>
        В районе очень заметно различие между уровнем развития сельских районов и городов. В деревнях живут
        преимущественно чжуаны, занимающиеся пашенным поливным земледелием, где это возможно. Там, где из-за гор сложно
        создать зоны ирригации, обработка земли ведется по старинке — мотыгой. Часть чжуанов работает в тяжелой
        промышленности, прочие занимаются ремеслом: кузнечным, гончарным и ткачеством (чжуанская парча славилась еще в
        эпоху династии Тан в VII в.).
      </p>
      <p>
        Жители района — его большие патриоты и известны на весь Китай тем, что обожают давать красочные названия любому
        мало-мальски выдающемуся уголку природы. Так, лес скал в Гуанси, сформировавшийся под воздействием водной
        эрозии, еще со времен династии Мин известен как «Царство побегов лотоса из зеленой яшмы».
      </p>
    </React.Fragment>
  ),
};

export default guanxi;
