import React from 'react';
import classNames from 'classnames';
import { NavLink /* , useLocation */ } from 'react-router-dom';

import { ReactComponent as ChaIcon } from '../svg/cha.svg';
// import { ReactComponent as ChaIcon } from '../svg/chadao.svg';

import s from './Header.module.css';
import Navigation from './nav/Navigation';
// import Logo from './Logo';

const links = [
  { to: '/', text: 'Чай', chiName: '茶' },
  { to: '/stuff', text: 'Чайная утварь', chiName: '茶具' },
  { to: '/locations', text: 'Провинции', chiName: '省份' },
  { to: '/making', text: 'Производство', chiName: '生产量' },
  { to: '/dictionary', text: 'Словарь', chiName: '词汇量' },
  { to: '/about', text: 'О проекте', chiName: '该项目' },
];

const Header = () => {
  // const location = useLocation();

  return (
    <header className={classNames([s.SiteHeader /* , location.pathname === '/stuff' && s.Fixed */])}>
      <NavLink className={s.LogoLink} to="/">
        {/* 茶道 */}
        {/* <Logo /> */}
        <ChaIcon width="52" height="52" />
        {/* <ChaIcon width="70" height="115" /> */}
      </NavLink>
      <Navigation links={links} />
    </header>
  );
};

export default Header;
