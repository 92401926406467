const YeShengHongCha = {
  id: 'YeShengHongCha',
  name: 'Е Шен Хун Ча',
  image: '/img/tea/YeShengHongCha.png',
  chiName: '古树红茶',
  enName: 'Ye Sheng Hong Cha',
  ruName: 'Дикий Красный Чай',
  location: 'Округ Юндэ, провинция Юньнань',
  legend: '',
  howto: {
    text:
      'Заваривать горячей водой (95°С) в фарфоровой гайвани или чайнике из пористой глины. Пропорция заварки к воде: 5-6 г на 100 мл. Первый раз настоять 10 секунд, после чего пить проливом с постепенным увеличением экспозиции. Выдерживает 10 завариваний.',
    temp: '95',
    ratio: '5',
    time: '3',
  },
  taste: {
    balmy: 3,
    spice: 4,
    trees: 4,
    flower: 4,
    berry: 5,
  },
  headerMargin: 6,
};

export default YeShengHongCha;
