import React from 'react';
// import { NavLink } from 'react-router-dom';

import s from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={s.SiteFooter}>
      {/* <NavLink className={s.Link} to="/about">
        О проекте
      </NavLink> */}
    </footer>
  );
};

export default Footer;
