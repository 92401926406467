const LiuAnGuaPian = {
  id: 'LiuAnGuaPian',
  name: 'Люань Гуапянь',
  altName: 'Лю Ань Гуа Пянь',
  image: '/img/tea/LiuAnGuaPian.png',
  chiName: '六安瓜片',
  altChiName: '',
  enName: 'Liu An Gua Pian',
  altEnName: 'Liuanguapian, Liuan Leaf',
  ruName: 'Тыквенные семечки из Люань',
  altRuName: '',
  location: 'Уезд Цзиньчжай (金寨), префектура Люань, провинция Аньхой',
  say: '',
  legend:
    'Известный своей долгой историей и богатой культурой, Люань Гуапянь много раз упоминался в литературных источниках. К примеру, во времена Танов (618-907) Лю Юй писал в «чайном каноне»: «Чай Люань из уезда Лю». Во времена династии Мин (1368-1644) чай описывается в полном сборнике по вопросам ведения сельского хозяйства «Тыквенные семечки из Уезда Лю Ань – лучший чай». Поставляемый в Запретный город как дань, этот чай любила императорская семья династии Цин. Вдовствующая императрица цинского Китая Цыси́ очень любила Лю Ань Гуа Пянь и выпивала до 14 лянов (700 грамм!) в месяц. В фундаментальном произведении китайской прозы, в романе «Сон в Красном тереме», тыквенные семечки упоминаются почти 80 раз, а автор Цао Сюэциняь мастерски играет словом, описывая мягкий вкус и непревзойденный аромат, будоража тем самым ум читателя.',
  howto: {
    text:
      'Заваривать горячей водой (80°С) в фарфоровой гайвани или чайнике из пористой глины. Пропорция заварки к воде: 3-4 г на 100 мл. Пить проливом с постепенным увеличением экспозиции. Выдерживает 5-6 завариваний.',
    temp: '80',
    ratio: '4',
    time: '2',
  },
  headerMargin: 8,
};

export default LiuAnGuaPian;
