import React from 'react';
import Quote from '../../components/Quote';

import s from '../../pages/StuffItem.module.css';

const chahai = {
  id: 'chahai',
  image: '/img/stuff/chahai.png',
  name: 'Сосуд «море чая»',
  chiName: '茶海',
  ruName: 'чахай',
  say: 'cháhǎi',
  content: (
    <React.Fragment>
      {/* <p>
        Это маленький кувшинчик, в который сливают чайный напиток из чайника, а затем уже из чахая разливают по
        чашкам. Это делается для того, чтобы чай во всех чашках оказался одинаково крепким, ведь заваривается он так
        быстро, что первые капли из чайника гораздо слабее последних.{' '}
      </p> */}
      <Quote>Тайваньское название – гундаобэй, что значит «чаша справедливости»</Quote>
      <p>
        Смысл предназначения чахая хорошо передает его тайваньское название – гундаобэй, что значит «чаша
        справедливости».
      </p>
      <div className={s.splash}>
        <img src="/img/stuff/chahai_opt/chahai1_1280.jpg" alt="" />
      </div>
      <p>Полезные функции «Чаши Справедливости»</p>
      <p>
        Во-первых, чтобы чай не перезаварился, его нужно сразу слить в чахай, где он может отстояться некоторое время.
      </p>
      <p>Во-вторых, после того как чай слит в чахай, мелкие чаинки осядут на дно чахая и не попадут в пиалки. </p>
      <p>
        В-третьих, если разливать чай, минуя чахай, прямо из заварного чайника, получается так, что у каждого сидящего
        за столом будет чай разный по крепости, так как налить чай сразу во все чашки не представляется возможным.
        Поэтому и придумали «Чашу справедливости». С помощью нее в пиале у каждого будет одинаковый чай.
      </p>
      <picture>
        <img src="/img/stuff/chahai_opt/chahai2_1200.jpg" alt="" />
      </picture>
      <p>
        Помимо все этого, переливая чай в чахай, температура его остывает, он становится не таким горячим. Также, все
        кто пьет чай, могут насладиться цветом чая, перед тем как его выпить.
      </p>
    </React.Fragment>
  ),
};

export default chahai;
