export const basic = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const listVariants = {
  enter: { transition: { staggerChildren: 0.1 } },
  exit: { transition: { staggerChildren: 0.1 } },
};

export const transition = { duration: 0.35, ease: [0.43, 0.13, 0.23, 0.96] };

export const itemVariants = {
  initial: {
    scale: 0.9,
    opacity: 0,
  },
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      ...transition,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
  exit: {
    scale: 0.9,
    opacity: 0,
    transition: {
      ...transition,
      // when: 'afterChildren',
      // staggerChildren: 0.1,
    },
  },
};

export const basicStagger = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
  exit: { opacity: 0, transition: { staggerChildren: 0.1 } },
};

export const basicItem = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};
