import React from 'react';

const guangdong = {
  id: 'guangdong',
  image: '/img/locations/guangdong.jpg',
  name: 'Гуандун',
  chiName: '广东',
  ruName: '',
  say: 'Guǎngdōng',
  teas: [{ name: 'Фэн Хуан Дянь Цун' }, { name: 'Люй Инь Чжэнь' }],
  content: (
    <React.Fragment>
      <p>
        Название встречается и поныне: провинцию Гуандун сокращенно называют Юэ, хотя в III в. до н. э. -III в. н. э. ее
        начали осваивать ханьцы, и теперь их здесь абсолютное большинство. Подчиненные в 214 г. до н. э. и включенные в
        китайскую область Наньхай («южное море»), три области восстали и образовали Южное Юэ со столицей Паньюй на месте
        современного Гуанчжоу. Только в 111 г. до н. э. ханьскому императору У Ди удалось усмирить непокорных. Южане и
        впоследствии не раз восставали против пекинских правителей; на этой земле родились и руководитель Тайпинского
        восстания 1850 г. Хун Сюцюань (1813-1864 гг.), и китайский революционер Сунь Ятсен (1866-1925 гг.) — основатель
        партии Гоминьдан и первый «отец нации» (получил этот титул посмертно).
      </p>
      <p>
        За свою историю территория будущего Гуандуна входила в разные административные образования, в том числе с 627 г.
        в область Линнань (буквально «к югу от горных хребтов»), затем в «обширную южную» область Гуаннань. В эпоху
        политических переворотов (907-960 гг.), когда на севере быстро сменились пять династий (от Тан до Сун), а на юге
        было образовано несколько независимых царств (официально принято считать, что 10), тут существовало царство
        Южная Хань. После того как в 971 г. император династии Сун восстановил контроль центрального правительства, была
        восстановлена в своих границах и область Гуаннань; в 997 г. ее поделили на Гуаннаньдунлу (дун «восточный», лу
        «округ») — сокращенно Гуандун, и Гуаннаньсилу (си «западный») — сокращенно Гуанси. В своем нынешнем виде
        провинция Гуандун образована в конце XVII в.
      </p>
      <p>
        Столица провинции — Гуанчжоу (Кантон) — город-порт на реке Чжуцзян (Жемчужной) с древней историей. Ядром города
        стал основанный еще в 887 г. до н. э. Чутин. С самого раннего периода существования это был торговый центр,
        ориентированный на связи с соседними и дальними странами. На юге Китая начинался Великий шелковый путь: отсюда
        шли по горам караваны, здесь же китайские товары грузили на корабли заморские купцы.
      </p>
      <p>
        Рельеф самой южной континентальной провинции КНР, лежащей на морском побережье к югу от хребтов Нанлинь и к
        северу от дельты Жемчужной реки, представляет собой чередование гор и холмов (62%) с плоскогорьями и низменными
        равнинами (38%); самые высокие горы находятся на севере (Нанлинь, до 1922 м). Горы в основном вытянуты с
        северо-востока на юго-запад параллельно береговой линии. Рек насчитывается около 640, из них основные — Чжуцзян
        (Жемчужная) с притоками Бэйцзян и Дунцзян. Гуандун — самый теплый регион Китая, почти на треть покрытый
        вечнозелеными тропическими лесами (куннингамия, камфорное дерево, чайное дерево, сосна и др.).
      </p>
      <hr />
      <p>
        Китайские исследователи чайной культуры говорят, что гуандунский чай начался в Фениксовых горах. На самом деле,
        это скорее относится к улуну. А так в провинции есть ещё немало мест, где чай совершенно точно возделывали и
        производили уже в начале Мин. Некоторые из этих мест ныне превратились в большие города (Гуанчжоу, Фошань), а
        некоторые, наоборот, — в весьма и весьма чайные районы (Индэ). Всё это свидетельствует о богатой и непростой
        истории.
      </p>
      <p>
        В труде «广东省志·农业志» — «Гуандунский альманах сельского хозяйства» говорится, что дикорастущий чай в
        провинции заваривали и пили ещё во времена Восточной Хань, а это 25-220 года н.э. Уже в шестом веке (Южная Лян)
        чай стали культивировать, а спустя 10 веков его прозводили в 27 округах. К концу династии Цин — в 84. Проблема
        изучения истории гуандунских чаев связана с полным уничтожением многих документов за очень большой период: нет
        записей с Южной Лян вплоть до начала Мин.
      </p>
      <p>
        Первые труды, где описано положение чайной культуры, относятся к эпохе Мин. В них говорится о горном народе Шэ,
        который испокон веков жил на этих горах, которые так и называли — горы Шэ. Этот народ культивировал чай с
        династии Суй, а во времена Южной Сун в горных деревнях повсеместно было распространено знание о пользе чая как
        профилактического средства, его пили для утоления жажды, улучшения пищеварения, выведения мокроты, лечения кашля
        и для наслаждения вкусом. В годы правления императора Хунчжи (1487-1505 гг.) чай с Фениксовых гор стали
        отправлять ко двору в качестве дани. Что это был за чай и как он назывался, история умалчивает. Скорее всего,
        это была разновидность рассыпного прожаренного чая, которую называли <b>Фениксовым чаем</b>. В конце династии
        Мин (1600-е годы) из Фуцзяни сюда пришла мода делать улуны; листья местных деревьев, крупные и крепкие,
        подходили для этого как нельзя лучше, правда, обработка в те времена сильно отличалась от современной. Но
        главная особенность — неравномерная ферментация листа из-за специального встряхивания-сминания — уже в ней
        присутствовала.
      </p>
      <p>
        Первый подъём местного производства случился при императоре Канси (Цинская династия) в 1662 году. На протяжении
        44 лет здесь активно поддерживали чайное дело. В уезде Жаопин располагались ссыльные чиновники, армейские
        генералы, присутсвие которых стимулировало местное производство. На Удуне в это время уже собирали листья как
        минимум с десяти разновидностей Дань Цунов — каждый со своим ароматом. Развитие чайного дела привело даже к
        тому, что приходилось нанимать приезжих. И все же большую часть производимого чая крестьяне выпивали сами,
        причем это был очень качественный чай по тем временам. Через недолгое время здесь появилась специальная комната
        для торговых сделок, для хранения чая, предназначенного на продажу — так возник первый прообраз чайного рынка в
        этих краях. Одновременно развивалась морская торговля — европейские суда приходили в Кантон (Гуандун) и увозили
        в числе покупок и чай.
      </p>
      <p>
        На исходе Минской эпохи одна торговая камбоджийская семья привезла в Панаму на Международную торговую ярмарку
        чай с гор Феникса. Он назывался <b>凤凰春茂茶 — Фэн Хуан Чунь Мао Ча</b>, что можно перевести как Весеннее
        буйство с гор Феникса или же как Весеннее богатство (или еще несколькими способами, но пусть это останется
        лингвистической загадкой). Этот чай, был <b>первым улуном с Фэнхуана</b>, что завоевал серебряную награду. Улун
        с гор Феникса продавали во Вьетнаме, Мьянме, Таиланде, на южных островах Тихого океана. В 1930 году уезды Фэн
        Хуана производили совокупно 3000 данов (дан — 50 кг). Но последующая война с Японией приостановила развитие
        чайного дела, и в 1949 году фермерам удалось сделать всего 900 данов чая, а цена на него стала крайне низкой.
        Сады в запустении, традиционная обработка была забыта.
      </p>
      <p>
        Лишь в 1956 году вернувшиеся крестьяне начали восстанавливать чаепроизводство. Этому способствовала земельная
        реформа, создание кооперативов и Культурная революция. Напряженная коллективизация повышает количество
        произведенного чая, но качество его начинает страдать. Создаются сады, каждый имеет своего управляющего, чайные
        сады сециализируются на культивации одного-трех видов деревьев, деревья стригут для повышения урожайности,
        появляется техника клонирования материнских кустов и т.д. За два года с 9879 соток территория садов вырастает до
        6386 гектаров. Небольшое количество уникальных чаев с одиноко растущих кустов пика Удун уходит в пользу
        правительственных нужд: для приема иностранных гостей, подарков представителям других стран. В 80-е годы
        оформилась новая, современная технология обработки — она сложная, многоэтапная, но позволяет получить
        высококачественный чай. С этого момента Фэн Хуан Дань Цун начинает получать многочисленные награды и звания, и
        входит в историю чайного Китая как национальный чай.
      </p>
      <hr />
      <p>
        <strong>Немного о красном матрасе</strong>
        Историю можно переписать. Это происходило настолько часто, что сейчас с одинаковым успехом можно верить
        бабушкиным сказкам и дедушкиным учебникам. Оттого бывает трудно отыскать информацию о чае, за пределами жанра «и
        из его ресниц вырос чай». Впрочем, отчаиваться не стоит, чайное дерево само по себе история, и ни одна буква в
        ней не может быть неправдой. Нужно только умение ее (историю) читать. Этим занимаются ученые — бурное развитие
        науки в последние годы позволило заглянуть так глубоко, как прежде не получалось. Используя методы классической
        и молекулярной генетики, ученые могут сказать какие растения родственники, и кто кому приходится бабушкой.
      </p>
      <p>
        Сейчас в провинции Гуандун, как и века назад, выращивают и обрабатывают чай. Горы Феникса имеют районы,
        сохранившие сообщества (рощи) старых чайных деревьев, в основном они растут на высотах от 800 до 1200 метров над
        уровнем моря. Это большие, высокие деревья (до 6 метров) с разросшимися кронами, им потребовались сотни лет,
        чтобы достичь современности, избежать многих бедствий и все таки выстоять. Эти деревья, растущие на Удуне,
        являются драгоценным историческим наследием. Они позволяют изучать генетическую эволюция чая, а также являются
        источником генетического материала, благодаря которому мы с вами имеем возможность лакомиться такими разными и
        интересными чаями. Чаи из этих мест по всем статьям уникальны, ни в каких краях не сохранилось такого количества
        старых деревьев (здесь более 3000 кустов старше 200 лет). Дань Цуны словно живут сразу в трех временах:
        некоторые из них насчитывают более 600 лет, и все они имеют множество клонированных потомков, которые увидят то
        время, до которого материнские деревья могут и не дотянуть. Увы, старые деревья иногда устают и засыхают.
      </p>
      <p>
        Самое старое дерево потухшего вулкана (не с прошлым ли Удуна связано обилие долгожителей?) это куст сорта{' '}
        <b>Сун Чжун И Хао — Сунский Сорт №1</b>, который растет на высоте 1150 метров, ему около 700 лет( по некоторым
        версиям 800).
      </p>
      <p>
        Всего со времен династии Сун (960-1279гг) сохранилось четыре куста — все они имеют приставку Сун Чжун: это{' '}
        <b>И Хао</b>, чай с которого называется{' '}
        <b>Дун Фан Хун Дань Цун (Алеющий восток), Ми Сян Дань Цун, Чжи Лань Сян Дань Цун</b>. Правда только два из них
        являются материнскими кустами.
      </p>
      <p>
        <b>Ми Сян Дань Цун</b> также называют Фан Шу Сян Дань Цун. Фан Шу — название сладкого картофеля на местном
        диалекте. Ми Сян имеет высокий резкий долгий вкус, цветочный аромат, вкус сильный и освежающий, медовая мелодия
        в послевкусии. У <b>Чжи Лань Сяна</b> аромат орхидей, чистый тонкий свежий вкус, сильное послевкусие, цвет
        настоя прозрачно-желтый, заваривается долго.
      </p>
      <p>
        В настоящее время на Фэнхуане насчитывается около сотни сортов кустов. Между тем, ученые, проведя генный анализ
        современных сортов утверждают, что все разнообразие возникло от двух пра-сортов. Эти два сорта они называют{' '}
        <b>Улун 乌龙</b> и <b>Хун Инь 红茵 (Красный матрас)</b>. Сорт Улун считается самым ранним культивируем сортом, а
        Хун Инь — первородным, местным дикорастущим сортом. Эти два сорта считаются зачинателями рода{' '}
        <b>гуандунских улунов</b>.
      </p>
      <p>
        Народность Ше поколениями выращивала здесь Улун с мелкими листьями, его называли <b>细叶 Си Е,</b> а Да Е
        появился только в 1958 году. Красный матрас 红茵 — дикорастущее чайное дерево, является предком Фэнхуанского Шуй
        Сяня, его нежные молодые листья на переднем кончике красноватые. Сам же Красный матрас воплотился в нынешний
        сорт 鸟嘴茶 — Птичий клюв. Этот чай спокойно растет себе в садах, являясь прямым потомком достославного Красного
        Матраса. После появились природные гибриды Хун Син и Бай Син — Красное сердце и Белое сердце.
      </p>
      <p>
        История и география (время и пространственные перемещения) породили вариации чайного дерева{' '}
        <b>Фэн Хуан Шуй Сянь (Нарцисс с Феникса)</b>, эта родственная группа деревьев имеет один штамм. Известно также,
        что 300 лет назад не было такого разнообразия сортов, но их было не меньше десяти (Ба Сянь Го Хай, Тун Сян, Ха
        Гу Лао, Сун Чжун Ар Хао), а в последние 20-30 лет кропотливая селекция позволила довести число сортов почти до
        сотни. Этому помогла в немалой степени и высадка пяти сортов из других провинций: два юньнаньских, два из УИ,
        один из Аньси — но это произошло не ранее 80-х, когда чаи с Фэнхуана стали регулярно одерживать победы на
        всевозможных соревнованиях.
      </p>
      <p>
        Интересна удивительно разветвленная классификация чаев по разным основаниям, которой владеют только специалисты
        и некоторые потомственные управляющие садов.
      </p>
      <p>
        Есть классификация по аромату, выделяет <b>десять (11) драгоценных сортов</b>:
      </p>
      <ul>
        <li>Хуан Чжи Сян Дань Цун — Аромат Гардении 黄栀香单丛</li>
        <li>Чжи Лань Сян Дан Цун — Аромат Ирисов (орхидей) 芝兰香单丛</li>
        <li>Гуй Хуа Сян Дань Цун — Аромат Османтуса — 桂花香单丛</li>
        <li>Юй Лань Сян Дань Цун — Аромат Магнолии — 玉兰香单丛</li>
        <li>Е Лай Сян Дань Цун — Ночной аромат — 夜来香单丛</li>
        <li>Цзян Хуа Сян Дань Цун — Аромат цветов имбиря — 姜花香单丛</li>
        <li>Ха Гу Лао Дань Цун — Древняя жаба Ха ловит рыбу (выпрыгнула из воды) -蛤古捞单丛</li>
        <li>Моли Сян Дань Цун — Аромат Жасмина — 茉莉香单丛</li>
        <li>Син Жен Сян Дань Цун — Аромат Миндаля — 杏仁香单丛</li>
        <li>Фан Шу Сян Дань Цун — Аромат Сладкого Картофеля — 番薯香单丛</li>
        <li>Ми Лань Сян Дань Цун — Аромат Медовой Орхидеи — 蜜兰香单丛</li>
      </ul>
      <p>
        Есть классификация <b>по форме листьев</b>:
      </p>
      <ul>
        <li>Шань Цзе Е — Лист Горного Баклажана — 山茄叶</li>
        <li>Туан Шу Е — Лист с круглого дерева — 团树叶</li>
        <li>Цзю Цзай Е — Лист молодого мандарина — 桔仔叶</li>
        <li>Цзинь Бянь Е — Золотая сторона (Пномпень) — 金边叶</li>
        <li>Ши Е — Лист Хурмы — 柿叶</li>
        <li>Йоу Е — Лист Помело — 柚叶</li>
        <li>Чжу Цзи Е — Лист бамбука — 竹子叶, и так далее, всего 25 сортов.</li>
      </ul>
      <p>
        <b>По кроне</b> чайного дерева:
      </p>
      <ul>
        <li>Ши Цзюэ Чжон — Выкопанный камень (Копать камнем, Упрямый Камень) — 石掘种</li>
        <li>Нянь Сан Чжон — Девушка с (под) зонтиком — 娘伞种</li>
        <li>Цзин Ши Чжон — Золотой лев — 金狮种 и т.д. Всего 15 видов.</li>
      </ul>
      <p>
        <b>По цвету и размеру</b> листа дерева:
      </p>
      <ul>
        <li>Ши Си Сянь — Каменная (Шелковая) нить — 石丝线</li>
        <li>Да Гу Гун — Подношение с большими костями — 大骨贡</li>
        <li>Юй Гу Цзай — Молодые кости сына — 幼骨仔</li>
        <li>Да У Е — Большой Темный лист — 大乌叶</li>
        <li>Да Бай Е — Большой белый лист — 大白叶 и далее, всего 26 видов.</li>
      </ul>
      <p>
        Начиная с 70-х ученые активно исследовали местные деревья. Они установили, что Фэн Хуан Шуй Сянь подразделяется
        на 8 видов натуральных цветочных ароматов, 3 фруктовых, 4 лекарственных, и 3 других аромата, всего 18 видов:
      </p>
      <ul>
        <li>
          <b>Цветочные:</b> Чжи Лань Сян, Ми Лань Сян , Юй Лань Сян, Гуй Хуа Сян, Юй Хуа Сян (помело), Е Лай Сян, Мо Ли
          Хуа Сян.
        </li>
        <li>
          <b>Фруктовые:</b> Пин Го Сян — аромат яблока, Ян Мэй Сян (Красная восковница, ямбери), Шу Вэй Сян — аромат
          картофеля.
        </li>
        <li>
          <b>Лекарственные:</b> Син Жен Сян — аромат миндаля, Жой Гуй Сян — Аромат Мясной корицы, Цзян Вэй Сян — аромат
          имбиря, Ку Вэй Сян — горький аромат.
        </li>
        <li>
          <b>Другие:</b> Ка Фей Сян — кофейный аромат, Ми Сян — медовый аромат, Се Чжон Вей Сян — аромат цвета или
          призрачный аромат.
        </li>
      </ul>
      <p>
        Среди иных, редких и почти не производящихся сортов есть и несколько желтых чаев и других улунов:{' '}
        <b>Те Гуан Инь, Ци Лань, Фо Шоу, Хуан Му Ян, Мэй Чжань</b> — однако, ввиду сложностей выращивания, от этих
        сортов постепенно отказываются.
      </p>
      <p>
        Даже просто перечитывать яркие неожиданные названия уже достаточно интересно, а ведь за сочными образами стоят
        реальные кусты, с которых делают настоящий чай. Есть к чему стремиться: где-то есть она, настоящая сокровищница
        Феникса, полная загадочного мерцания, манящая и недостижимая.
      </p>

      <h5>Гуандунская легенда о чайном ритуале «поклон рукой»</h5>
      <p>
        <em>
          Жители провинции Гуандун отнюдь не прихотливы в вопросах чайного этикета. Единственное правило заключается в
          том, что когда человек наливает Вам чай, в независимости от его положения – будь то старший по возрасту,
          начальник, гость или даже очень близкий друг, вы обязаны выполнить церемонию – легко постучать средним и
          указательным пальцами по поверхности чайного столика, тем самым выражая свою благодарность.
        </em>
      </p>
      <p>
        Эта церемония сохранилась с древности до наших дней и стала традицией жителей района Линнань. Возникновение
        ритуала связано со следующей историей:
      </p>
      <p>
        Легенда гласит, что как-то раз император династии Цин Цянь Лун отправился инкогнито в инспекционную поездку в
        Цзянань и в сопровождении своего слуги зашел в чайную на западной заставе Гуанчжоу. Вскоре явился служащий
        чайной и поставил на стол перед Цяньлуном и слугой чашечки с чайными листьями. В правой руке служащий принес
        котел с горячей водой. Взяв чашечки в левую руку, правой рукой он приподнял котел и залил чашечки наполовину
        кипятком, так, что ни одна капля не пролилась мимо.
      </p>
      <p>
        Император очень удивился и спросил служащего, почему он так заваривает чай. Служащий с улыбкой ответил, что
        такой способ заваривания чая называется «глаза креветки»: когда вода закипает, на поверхности появляются пузыри,
        которые местные называют «глазами креветки»; воду же надо наливать сверху вниз, на второй же раз воду надо
        наливать в низком положении, тогда чай заварится и даст аромат. Цяньлун так заинтересовался его рассказом, что
        встал, сам взял в руки котел и, подражая служащему, стал заваривать чай своему слуге.
      </p>
      <p>
        У того душа ушла в пятки, где же это слыхано, чтобы император потчевал слугу чаем, как же можно принять чай?
        Согласно дворцовому этикету, тот, кому император жалует награду, обязан встать на колени и в знак благодарности
        положить земной поклон. Но в тот раз император выехал с дозором инкогнито и хотел остаться неузнанным. В
        смятении слуга поднял указательный и средний пальцы, согнул их в форме коленопреклоненного человека и постучал
        пару раз по столу, будто коснулся земли головой, выражая благодарность. Служащий чайной в недоумении спросил,
        что это значит, слуга же не нашел лучшего ответа как сказать: «Это чайный ритуал поклона рукой».
      </p>
      <p>
        С того момента, этот ритуал получил хождение в чайных, а в конце концов стал традицией чаепития в Гуандуне; он
        до сих пор очень популярен в районе Линнань, а также в среде этнических китайцев, проживающих на территории
        Юго-восточной Азии. Изначально ритуал был достаточно строгим: необходимо было согнуть пальцы и, не сжимая кулак,
        постучать костяшками; впоследствии ритуал претерпел изменения, и сегодня достаточно лишь постучать кончиками
        сложенных вместе указательного и среднего пальцев.
      </p>
    </React.Fragment>
  ),
};

export default guangdong;
