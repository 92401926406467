import React from 'react';

const chaju = {
  id: 'chaju',
  image: '/img/stuff/chaju.png',
  name: 'Чайные инструменты',
  chiName: '茶具',
  ruName: 'чацзюй',
  say: 'chájù',
  content: (
    <React.Fragment>
      <p>
        Набор чайных инструментов <em>чацзюй</em> состоит из воронки, щипчиков, иглы, большой и маленькой ложечек,
        чайной кисточки. Все они помещаются в специальный стаканчик круглой или квадратной формы, к которому может
        крепиться держатель для деревянных подставок для чашек под названием <em>чатоу</em>.{' '}
      </p>
      <p>
        Чайные инструменты облегчают работу чайного мастера. В наиболее распространенном типе набора есть две ложечки.
        Одна, большая, используется для того, чтобы переложить чай из чайницы в <em>чахэ</em>. Другая, маленькая, – для
        перекладывания чая из чахэ в чайник и некоторых действий во время заваривания (отодвинуть чай от носика,
        поправить его и т.д.).{' '}
      </p>
      <p>
        Воронку <em>часяньло</em> применяют при пересыпании чая из <em>чахэ</em> в узкое горлышко чайника.{' '}
      </p>
      <p>
        Щипчики <em>цзяцзы</em> нужны для манипуляций с горячими чашками при промывании посуды в <em>чачи</em> – «чайном
        пруде». Кроме того, поскольку китайцы избегают прямого контакта рук и личной посуды человека, которому она
        выдается, щипцы нужны и при работе с чашками, стоящими на чабани. Свободное использование щипцов считается
        особым достижением мастера.{' '}
      </p>
      <p>
        Игла <em>чацзань</em> пускается в ход, когда носик чайника забился. Однако нужно помнить, что ее можно
        использовать для прочистки только тех чайников, у которых внутри отсутствует сеточка или сеточка состоит из
        крупных отверстий. Чайная кисточка <em>янхуби</em> помогает содержать посуду в чистоте и «насыщать чайничек
        вниманием мастера».
      </p>
    </React.Fragment>
  ),
};

export default chaju;
