import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import s from './App.module.css';
// import { ReactComponent as DaoIcon } from './svg/dao.svg';
// import { ReactComponent as ChaDaoIcon } from './svg/chadao.svg';

import Main from './pages/Main';
import Dictionary from './pages/Dictionary';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './pages/About';
import Stuff from './pages/Stuff';
import StuffItem from './pages/StuffItemCenter';
import Locations from './pages/Locations';
import LocationItem from './pages/LocationItem';
import Family from './pages/Family';
import Tea from './pages/Tea';
import Making from './pages/Making';
import Page404 from './pages/Page404';

function App() {
  const location = useLocation();
  return (
    <div className={s.App}>
      <Header />
      <main className={s.SiteMain}>
        <AnimatePresence
          exitBeforeEnter
          onExitComplete={() => {
            window.scrollTo(0, 0);
          }}
        >
          <Switch location={location} key={location.pathname}>
            <Route exact path="/" component={Main} />
            <Route exact path="/family/:familyId" component={Family} />
            <Route exact path="/family/:familyId/tea/:teaId" component={Tea} />
            <Route exact path="/dictionary" component={Dictionary} />
            <Route exact path="/about" component={About} />
            <Route exact path="/stuff" component={Stuff} />
            <Route exact path="/locations" component={Locations} />
            <Route exact path="/making" component={Making} />
            <Route exact path="/location/:locationId" component={LocationItem} />
            <Route exact path="/stuff/:stuffId" component={StuffItem} />
            <Route path="*" component={Page404} />
          </Switch>
        </AnimatePresence>
      </main>
      <Footer />
    </div>
  );
}

export default App;
