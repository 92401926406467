import React from 'react';
import { motion } from 'framer-motion';

import s from './Navigation.module.css';

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const MenuItem = React.memo((props) => {
  const { children } = props;
  // whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}
  return (
    <motion.li className={s.li} variants={variants}>
      {children}
    </motion.li>
  );
});

export default MenuItem;
