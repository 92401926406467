const LiuBao = {
  id: 'LiuBao',
  name: 'Лю Бао',
  image: '/img/tea/LiuBao.png',
  chiName: '六堡茶',
  enName: 'Liu Bao',
  ruName: 'Шесть Замков',
  location: 'Гуанси-Чжуанский автономный округ',
  say: 'liù bǎo chá',
  legend: '',
  howto: {
    text:
      'Заваривать горячей водой (90-95°С) в гайвани или чайнике из пористой глины. Пропорция заварки к воде: 6-7 г на 100 мл. Для первого раза настоять 8-10 секунд, после чего пить проливом с постепенным увеличением экспозиции. Выдерживает 12 завариваний.',
    temp: '95',
    ratio: '6',
    time: '1',
  },
  taste: {
    berry: 2,
    earth: 3,
    spice: 3,
    balmy: 4,
    trees: 4,
  },
  headerMargin: 10,
};

export default LiuBao;
