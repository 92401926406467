import React, { useRef } from 'react';
import { motion, useCycle } from 'framer-motion';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import useDimensions from './useDimensions';

import MenuToggle from './MenuToggle2';
import MenuList from './MenuList';

import s from './Navigation.module.css';

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 250px 48px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(30px at 250px 48px)',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const Navigation = React.memo((props) => {
  const { links = [] } = props;
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const history = useHistory();

  React.useEffect(() => {
    // history.listen((location, action) => {
    history.listen(() => {
      toggleOpen(0);
    });
  }, []);

  return (
    <motion.nav
      className={classNames([s.nav, isOpen && s.open])}
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={height}
      ref={containerRef}
    >
      <motion.div className={s.background} variants={sidebar} />
      <MenuList links={links} toggle={() => toggleOpen()} />
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
});

export default Navigation;
